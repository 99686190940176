import { render, staticRenderFns } from "./forgotUserData.vue?vue&type=template&id=16ab2965&scoped=true"
import script from "./forgotUserData.vue?vue&type=script&lang=js"
export * from "./forgotUserData.vue?vue&type=script&lang=js"
import style1 from "./forgotUserData.vue?vue&type=style&index=1&id=16ab2965&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16ab2965",
  null
  
)

export default component.exports