<script>

    import _ from "lodash";
    import accounting from "accounting";

    export default {
        mounted() {
            console.log('dragDropItems mixin activated');
        },
        data() {
            return {
                drag: {
                    sourceIndex: -1,
                    targetIndex: -1,
                    sourceItem: {},
                    targetItem: {},
                    debug: false,
                    draggedElems: [],
                }
            };
        },
        computed: {
            isDragOverEmptyItem() {
                if (!this.drag.targetItem) return false;
                return this.drag.targetItem.id;
            }
        },
        methods: {
            dragReset() {
                this.drag.sourceIndex = -1;
                this.drag.targetIndex = -1;
                this.drag.sourceItem = {};
                this.drag.targetItem = {};
            },
            cloneItem() {
                //console.log('cloneItem');
                const index = 0;
                // this will later updated on dropped
                this.drag.sourceItem = {
                    hrs: 0,
                    id: null,
                    name: '',
                    rate: 0,
                    rate2: 0,
                    total: 0,
                    adj_price: null,
                    adj_hrs: null,
                    adj_rate: null,
                };
                // continue to onItemMoveChange method
                return this.drag.sourceItem;
            },
            onDragStart(event) {
                this.drag.sourceIndex = event.oldIndex;
                this.drag.sourceItem = this.cardSubParts[event.oldIndex];

                // console.log('onDragStart', this.drag.sourceIndex);
            },
            onDragAdd(event) {
                if (_.includes(event.item?.id, 'group-item-') || _.includes(event.item?.id, 'group-') ) {
                  setTimeout(() => {
                    this.estimateParts[this.selectedCard].splice(this.estimateParts[this.selectedCard].length - 1)
                  }, 0)
                  if (_.includes(event.item?.id, 'group-item-')) {
                    let id = event.item.id.split('group-item-')[1]
                    let groupItem = null;
                    _.forEach(this.currentGroupCategory, (g) => {
                      let findItem = _.find(g.items, (itm) => {
                        return Number(itm.id) == Number(id)
                      })
                      if (findItem) {
                        groupItem = findItem
                      }
                    })
                    setTimeout(() => {
                      if (groupItem) {
                        this.selectCardSubPart(groupItem)
                      }
                    }, 1)

                  }
                  if (_.includes(event.item?.id, 'group-')) {
                    let id = event.item.id.split('group-')[1]
                    let group = _.find(this.currentGroupCategory, (g) => {
                      return Number(g.group.id) == Number(id)
                    })
                    if (group) {
                      _.forEach(group.items, (i, ind) => {
                        setTimeout(() => {
                          this.selectCardSubPart(i)
                        }, i + 1)
                      })
                    }
                  }
                  return
                }

                const sourceIndex = event.oldIndex;
                const targetIndex = event.newIndex;

                this.drag.sourceIndex = sourceIndex;
                this.drag.targetIndex = targetIndex;

                let  sourcePart = this.cardSubParts[sourceIndex];

              // if (this.isEstimatesTemplates) {
              //   sourcePart = this.cardSubParts[Object.keys(this.cardSubParts)[sourceIndex]]
              //   this.selectedCardSubPart = sourcePart.prt;
              //   let default_rate = 0;
              //   if (this.selectedCard == 'Part') {
              //     var newItem = {
              //       id: null,
              //       name: sourcePart.prt,
              //       part_num: '',
              //       type: 'New',
              //       mup: 0,
              //       qty: 1,
              //       rate: 0,
              //       total: 0
              //     };
              //
              //   } else if (this.selectedCard == 'Paint') {
              //     var newItem = {
              //       id: null,
              //       name: sourcePart.prt,
              //       paint_type: 'not_set',
              //       hrs: 0,
              //       rate: default_rate,
              //       total: 0
              //     };
              //   } else {
              //     var newItem = {
              //       id: null,
              //       name: sourcePart.prt,
              //       rate: default_rate,
              //       hrs: sourcePart.hrs,
              //       total: 0
              //     };
              //   }
              //
              //   setTimeout(() => {
              //     let partsCopy = _.cloneDeep(this.itemParts[this.selectedCard])
              //     partsCopy.splice(this.drag.targetIndex, 1, newItem);
              //     this.itemParts[this.selectedCard] = [];
              //     setTimeout(() => {
              //       this.itemParts[this.selectedCard] = partsCopy;
              //       this.dragReset();
              //     }, 0)
              //   }, 0);
              //
              // } else {
              //   // remap rate by selectedCard
              //   // match this.rates to estimate-item.vue > data
              //   let rate = 0;
              //   let paint_type = '';
              //   switch (this.selectedCard) {
              //     case 'Paint': {
              //       rate = this.rates.paint;
              //       paint_type = this.rates.paint_material;
              //       break;
              //     }
              //     case 'Repair': {
              //       rate = this.rates.repair;
              //       break;
              //     }
              //     case 'RR': {
              //       rate = this.rates.rr;
              //       break;
              //     }
              //     default: {
              //       rate = 0;
              //     }
              //   }
              //
              //   // update object with reactivity
              //   // ref: https://v3.vuejs.org/guide/change-detection.html#for-objects
              //   this.drag.sourceItem = Object.assign(
              //       {},
              //       this.drag.sourceItem,
              //       {
              //         ...sourcePart,
              //         // apply remapped data some specific property
              //         name: sourcePart.prt,
              //         rate,
              //         paint_type,
              //         estimateMethodology: this.rates.estimate_methodology,
              //         materialUseLevy: this.rates.material_use_levy,
              //         mup: this.rates.partmarkup,
              //         type: 'manual',
              //         adj_price: null,
              //         adj_hrs: null,
              //         adj_rate: null,
              //       }
              //   );

                if (this.drag.dragOverEmpty) {
                    this.applyToEmptyItem(); // this is for apply to empty feature
                } else {
                  if (sourcePart) {
                    this.selectCardSubPart(sourcePart, this.drag.targetIndex)
                    this.dragReset();
                  }
                }
            },
            onDragEnd(event) {
                if (_.isEmpty(this.drag.draggedElems)) {
                  return;
                }
                if (this.drag.draggedElems.length > 1) {
                    _.forEach(this.drag.draggedElems, function(itm) {
                        itm.style.setProperty('background-color', 'transparent');
                    });

                    this.drag.draggedElems[this.drag.draggedElems.length - 1].style.setProperty('background-color', '#F9F9F9');

                    this.drag.draggedElems.splice(0, this.drag.draggedElems.length - 1);
                } else {
                    this.drag.draggedElems[0].style.setProperty('background-color', '#F9F9F9');
                }

                this.drag.draggedElems[0].style.setProperty('background-color', '#fff');

                console.log('onDragEnd');
                // this.onDragAdd(event);
            },
            applyToEmptyItem() {
                console.log('applyToEmptyItem', this.drag.targetItem);
                this.drag.targetItem.name = this.drag.sourceItem.name;
                this.drag.targetItem.id = this.drag.sourceItem.id;

                // update existing
                console.log(this.drag);

                // we're not creating new one
                // this.onRightClickAddPart(this.selectedCard, this.drag.targetItem) // this.selectedCard e.g. 'RR'
                //     .then(res => {
                //         // swap dropped item with newly saved item
                //         this.estimateParts[this.selectedCard].splice(this.drag.targetIndex, 0, res);
                //     })
                //     .finally(() => {
                //         this.dragReset();
                //     })
            },
            itemOnMouseenter(item, index) {
                // console.log('itemOnMouseenter', item.name, item.id);
                // if (this.drag.dragOverEmpty) return

                this.drag.targetItem = item;
                this.drag.targetIndex = index;

            },
            itemOnMouseover(item) {
                // TODO: Let's drop this as this is unecessary
                // console.log('itemOnMouseover', item.name, item.id);
            },
            itemOnMouseleave(item) {
                // console.log('itemOnMouseleave', item.name, item.id);
                this.drag.targetItem = {};
                this.drag.targetIndex = -1;
            },
        }
    };


</script>
