import { render, staticRenderFns } from "./board.vue?vue&type=template&id=76791b8e&scoped=true"
import script from "./board.vue?vue&type=script&lang=js"
export * from "./board.vue?vue&type=script&lang=js"
import style0 from "./board.vue?vue&type=style&index=0&id=76791b8e&prod&scoped=true&lang=css"
import style1 from "./board.vue?vue&type=style&index=1&id=76791b8e&prod&scoped=true&lang=scss"
import style2 from "./board.vue?vue&type=style&index=2&id=76791b8e&prod&lang=css"
import style3 from "vuetify/dist/vuetify.min.css?vue&type=style&index=3&id=76791b8e&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76791b8e",
  null
  
)

export default component.exports