<template>
    <div class="container-fluid bg-white p-0 login">
        <div class="header">
            <div class="gradient auth-bg col-xl-6 col-lg-6 col-md-6"></div>
            <span class="close" @click="$router.push('/login')">×</span>
            <div class="h-content">
                <div class="row clearfix width-container">
                    <div class="col-md-6 main-logo-container">
                        <div class="main-logo">
                            <router-link to="/login" class="logo-link"></router-link>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-7 col-lg-7 col-md-7"></div>
                        <div class="col-xl-5 col-lg-5 col-md-5">
                            <div class="box box-block mb-3">
                                <form class="form-material" method="POST" @submit.prevent="sendForgotRequest">
                                    <h2 class="header-text">Forgot your {{ getTitle() }}?</h2>
                                    <p>Get Help by providing us with more information about your account and one of our team members
                                        will contact you.</p>
                                    <br/>
                                    <div class="form-group">
                                        <label for="userEmail" class="form-group-input-title">Enter the Email Address That Was Registered With Your Account</label>
                                        <email v-model="userEmail"></email>
                                    </div>
                                    <div class="form-group">
                                        <label for="firstname" class="form-group-input-title">First Name</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                :class="{'error':!isFirstName}"
                                                placeholder="Your First Name"
                                                name="firstname"
                                                v-model="firstName"
                                                @change="checkFirstName()"
                                        />
                                        <div v-if="!isFirstName" class="w-100 text-right">
                                <span class="error-text">
                                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Field is required.
                                </span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="lastName" class="form-group-input-title">Last Name</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                :class="{'error':!isLastName}"
                                                placeholder="Your Last Name"
                                                name="lastName"
                                                v-model="lastName"
                                                @change="checkLastName()"
                                        />
                                        <div v-if="!isLastName" class="w-100 text-right">
                                <span class="error-text">
                                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Field is required.
                                </span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="contactNumber" class="form-group-input-title">Contact Number</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                :class="{'error':!isContactNumber}"
                                                placeholder="Your Contact Number"
                                                name="contactNumber"
                                                v-model="contactNumber"
                                                @change="checkContactNumber()"
                                        />
                                        <div v-if="!isContactNumber" class="w-100 text-right">
                                <span class="error-text">
                                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Field is required.
                                </span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="companyName" class="form-group-input-title">Company Name The Account is Registered With</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                :class="{'error':!isCompanyName}"
                                                placeholder="Company Name"
                                                name="companyName"
                                                v-model="companyName"
                                                @change="checkCompanyName()"
                                        />
                                        <div v-if="!isCompanyName" class="w-100 text-right">
                                <span class="error-text">
                                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Field is required.
                                </span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button class="btn btn-primary" :disabled="!userEmail" type="submit">Submit to Support</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Axios from "axios";
    import Email from "./Email";

    export default {
        name: "forgotUserData",
        components: {
            Email,
        },
        data() {
            return {
                userEmail: "",
                firstName: "",
                lastName: "",
                contactNumber: "",
                companyName: "",
                isFirstName: true,
                isLastName: true,
                isContactNumber: true,
                isCompanyName: true
            };
        },
        methods: {
            sendForgotRequest() {
                if (!this.isCheckFields()) return;
                NProgress.start();
                Axios({
                    url: this.getUrl(), data: {
                        email: this.userEmail,
                        firstName: this.firstName,
                        lastName: this.lastName,
                        contactNumber: this.contactNumber,
                        companyName: this.companyName
                    }, method: 'POST'
                }).then(resp => {
                    if (resp && resp.data && resp.data._status) {
                        toastr.success('The form has been sent successfully. Request Nbr:' + resp.data.request_id);
                        this.$router.push('/login');
                    }
                }).catch(err => {
                    console.log(err);
                }).finally(() => {
                    NProgress.done();
                });
            },
            checkFirstName() {
                this.isFirstName = !_.isEmpty(this.firstName);
            },
            checkLastName() {
                this.isLastName = !_.isEmpty(this.lastName);
            },
            checkContactNumber() {
                this.isContactNumber = !_.isEmpty(this.contactNumber);
            },
            checkCompanyName() {
                this.isCompanyName = !_.isEmpty(this.companyName);
            },
            isCheckFields() {
                this.checkFirstName();
                this.checkLastName();
                this.checkContactNumber();
                this.checkCompanyName();
                return !(!this.isFirstName || !this.isLastName || !this.isContactNumber || !this.isCompanyName);
            },
            getUrl() {
                return '/auth/forgot-username-support';
            },
            getTitle() {
                return 'Username';
            }
        }
    };
</script>

<style scoped>

</style>
<style scoped>

    .login .gradient.auth-bg {
        background: #1f233d !important;
    }

    .logo-text {
        color: white;
        margin: 20% 0 0 20%;
    }

    .login .gradient {
        position: absolute;
        height: 100% !important;
    }

    .close {
        color: #5e79ff;
        font-size: 50px;
        padding-right: 20px;
        padding-top: 20px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 999;
        opacity: 0.5;
    }

    .close:hover {
        opacity: 1;
    }

    .header {
        color: black;
    }

    .header-text {
        font-size: 32px;
    }

    .login .header .box {
        background: none;
    }

    .box-block {
        max-width: 450px;
    }

    .box-block h4, h6 {
        text-align: center;
    }

    h6 {
        margin-top: 30px;
    }

    input::placeholder {
        color: #ADADAD !important;
    }

    .V3 .login input {
        color: black;
    }

    .form-padd {
        padding-bottom: 19px;
    }

    .checkbox-block {
        margin: 50px 0 50px 25px;
    }

    .btn-block {
        background: #4472C4;
        color: white;
        border-radius: 3px;
    }

    .btn.disabled, .btn:disabled {
        opacity: 1;
    }

    .header .gradient {
        opacity: 1;
    }

    .form-group-label {
        padding-left: 20px;
    }

    .V3 label.radio {
        width: 100%;
    }

    .header {
        min-height: 100vh;
    }

    .header .btn {
        margin: 0 auto;
    }

    .form-material {
        text-align: center;
    }

    .form-material h2 {
        padding-bottom: 50px;
    }

    .form-material p {
        font-weight: 600;
        padding-bottom: 50px;
        font-size: 16px;
    }

    .form-control {
        color: black;
    }

    input::placeholder {
        color: #ADADAD !important;
    }

    .V3 .login input {
        padding-left: 15px;
        color: black !important;
    }

    .text-sm-right {
        padding-top: 10px;
    }

    .form-group-input-title {
        width: 100%;
        text-align: left;
        padding-left: 15px;
        font-size: 16px;
    }

    .btn-primary {
        margin-top: 30px !important;
        width: 100%;
        border-radius: 3px;
        font-size: 16px !important;
        background: linear-gradient(135deg, rgb(114, 103, 231) 0%, rgb(98, 89, 239) 49.12%, rgb(78, 69, 238) 100%);

    }

    .main-logo-container {
        padding: 32px 72px 0 72px;
    }

    .width-container {
        margin: 0 auto;
        width: 1440px;
    }

    .h-content {
        display: flex;
        justify-content: center;
    }

    .main-logo {
        display: flex;
        justify-content: center;
        height: 39px !important;
        width: 185px !important;
        background-repeat: round;
    }

    .logo-link {
        display: block;
        height: 39px;
        width: 185px;
    }

    @media screen and (max-width: 768px) {
        .main-logo {
            background-image: url('../../assets/logos/logo3x.png');
            background-size: 100% auto;
        }
    }

    .logo-link {
        display: block;
        height: 42px;
        width: 200px;
    }

    .form-group .form-control {
        font-family: "Nunito Sans";
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
        padding: 9px 13px;
        border: 1px solid rgba(27, 30, 56, 0.2) !important;
        border-radius: 4px !important;
        height: 43px !important;
        color: #1C1F39;
    }

    .form-group .form-control::placeholder {
        font-family: "Nunito Sans";
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
        color: #ADADAD !important;
        opacity: 1;
        padding-left: 2px;
    }

    .btn.disabled, .btn:disabled {
        cursor: not-allowed;
        opacity: .65;
        background: #adaeb2;
        border-color: #adaeb2;
    }

    .V3 .btn-primary.disabled:hover, .V3 .btn-primary:disabled:hover {
        background: #98999c;
        border-color: #98999c;
    }
    @media screen and (max-width: 767px) {
        .box-block {
            margin-top: 70px !important;
        }
        .main-logo-container .main-logo {
            margin: 0 auto;
        }
    }

    @media screen and (max-width: 350px) {
        .box-block {
            margin-top: 30px !important;
        }
        .width-container {
            margin-top: 50px !important;
        }
    }
    .V3 .btn-primary.disabled:hover, .V3 .btn-primary:disabled:hover {
        background: #98999c;
        border-color: #98999c;
    }
</style>
