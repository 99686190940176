<template>
  <form class="search-filter pl-3">
    <div class="search-filter__box" :class="{'search-filter__box__focused': isInputInFocus,'search-filter__box__with__dropdown': isShowDropdown, 'search-filter__box-color-turquoise': isSearch}" :style="boxStyle">
      <div class="search-filter__scroll-container">
        <ul class="search-filter__ul">
          <li class="search-filter__li" style="z-index: 999999; display: flex">
            <div class="">
              <i v-if="isShowSearIcon" ref="searchIcon" @click="clickOnSearchIcon" @focus="clickOnSearchIcon" class="bx bx-search-alt mr-0-5"
                 style="font-size: 24px; z-index: 99999999999; margin-top: 7px; color: #9395a1; margin-left: 7px;"></i>
            </div>
            <!-- Token -->
            <div v-if="!isMyltiple" class="token font-12 font-12--light">
              <!--                  eslint-disable -->
              <template v-for="(level, index) in token">
                <div class="token__level" :key="`${level}-${index}`" v-if="!level.resultObjectOnly">
                  {{ level.label }}
                </div>
              </template>
              <div class="token__remove" @click="remove" v-if="tokenComplete"><i class='bx bx-x'></i></div>
            </div>
            <div v-else class="token font-12 font-12--light">
              <template v-for="(token, indexToken) in computedTokens">
                <template v-for="(level, index) in token">
                  <div class="token__level" :key="`${level}-${indexToken}-${index}`" v-if="!level.resultObjectOnly">
                    {{ level.label }}
                  </div>
                </template>
                <div class="token__remove" @click="remove(indexToken)"
                     v-if="(computedTokens[indexToken] && computedTokens[indexToken][0] && computedTokens[indexToken][0].isCompleted) && mappedFilters[indexToken] && computedTokens[indexToken].length - 1 >= mappedFilters[indexToken].options.length">
                  <i class='bx bx-x'></i></div>
              </template>
            </div>
          </li>

          {{ /* sample to check styling */ }}
          <!--
          <li class="search-filter__li" v-if="isTokens">
              <div class="token font-12 font-12--light">
                  <div class="token__level">Date</div>
                  <div class="token__container">
                      <div class="token__value" data-id="@fanny">
                          Card Open
                      </div>
                      <div class="token__remove" @click="remove"><i class='bx bx-x'></i></div>
                  </div>
              </div>
          </li>
          -->

          <!-- input with drop down-->
          <li class="search-filter__li search-filter__li--input">
            <div class="dropdown d-flex" style="width: 100%; justify-content: end;">

              <!--                            <date-range-picker-->
              <!--                                v-show="showDateRange"-->
              <!--                                ref="picker"-->
              <!--                                opens="left"-->
              <!--                                class="font-12"-->
              <!--                                :ranges="predefinedTimeRanges"-->
              <!--                                :locale-data="localeData"-->
              <!--                                :close-on-esc="true"-->
              <!--                                @update="handleDateUpdate"-->
              <!--                                @toggle="handleDateToggle"-->
              <!--                                v-model="dateRange"-->
              <!--                            />-->
              <b-form-input v-show="!showDateRange"
                            class="search-filter__input font-12"
                            ref="search-filter-input"
                            id="search-filter-input"
                            v-model="search"
                            type="text"
                            @focus="focusInput"
                            @click="showDropdown"
                            @keydown.enter.prevent="$emit('onEnter')"
                            @keydown="handleKeydown"
                            @blur="isInputInFocus = false"
                            @keyup="handleKeyup"
                            debounce="500"
                            aria-haspopup="true"
                            aria-expanded="false"
                            autocomplete="off"
                            data-toggle="dropdown"
                            style="width: 100%; z-index: 999999;text-transform: uppercase;"
                            v-closable="{
                              exclude: ['dropdown', 'searchIcon', 'searchOptionInput', 'dateRange'],
                              handler: 'hideDropdown'
                            }"
                            :placeholder="computedPlaceholder"
              >

              </b-form-input>
              <div
                  v-if="showDateRange"
                  ref="dateRange"
                  :class="{'responsive-search-filter': true}" class="custom-dropdown-2 custom-dropdown">

              </div>

              <div
                  v-if="isShowDropdown && !isDateFilter && !(tokenComplete && !isMyltiple) && !(token[0] && token[0].id === 'dateRange')
                  && ((computedCurrentFilter && computedCurrentFilter.isNeedSearch) && ((filteredFilters.length) || (!filteredFilters.length && searchForOptions)) || filteredFilters.length)"
                  :class="{'responsive-search-filter': token.length}" class="custom-dropdown">
                <div class="">
                  <b-form-input
                      ref="searchOptionInput"
                      v-if="(computedCurrentFilter && computedCurrentFilter.isNeedSearch) && ((filteredFilters.length) || (!filteredFilters.length && searchForOptions))"
                      v-model="searchForOptions"
                      @input="setSearchForCurrentFilter"
                      @keydown.enter.prevent="$emit('onEnter')"
                      class="search-options"
                      placeholder="Search"
                  />
                  <div v-if="isShowLoading" class="" style="position: absolute; right: 20px; top: 14px;">
                    <b-spinner style="width: 19px; height: 19px;" :variant="'info'"></b-spinner>
                  </div>
                </div>

                <template v-for="(filter, index) in filteredFilters">
                  <a
                      class="dropdown-item search-filter__dropdown-item font-12"
                      ref="filters"
                      @focus="showDropdown"
                      href="#"
                      @keydown="handleKeydownDropdown"
                      :key="filter.label.toLowerCase()"
                      @touchstart="selectFilter(index, $event)"
                      @click.prevent.stop="selectFilter(index, $event)"
                  >
                    <span>{{ filter.label }}</span>
                  </a>
                </template>
              </div>

              <div
                  v-else-if="(isShowDropdown || (isAssignModal)) && !_isEmpty(additionalMenu)"
                  :class="{'responsive-search-filter': true}" class="custom-dropdown additional-menu-search-filter">
                <a class="tabs-nav-right-search-dropdown-item"
                     v-for="itm in computedAdditonalMenu"
                     ref="filters2"
                     @keydown="handleKeydownDropdown2"
                     href="#"
                     @touchstart="$emit('selectAdditional', itm)"
                     @click.prevent.stop="$emit('selectAdditional', itm)"
                >
                  <template v-if="isAssignModal">
                    <div class="tabs-nav-right-search-dropdown-item-header">
                      <span class="tabs-nav-right-search-dropdown-item-header-weight">{{itm.label}}</span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="tabs-nav-right-search-dropdown-item-header"
                         :class="{'active': isDropdownItemActive(itm)}">
                      <span class="tabs-nav-right-search-dropdown-item-header-weight">{{itm.estimateNumber}}<span v-if="itm.regoNumber"> - {{itm.regoNumber}}</span> -</span><span style="font-weight: 700"> {{ itm.customerName }}</span>
                    </div>
                    <div class="tabs-nav-right-search-dropdown-item-footer">
                      <span><span v-if="itm.insurerName">{{ itm.insurerName }}<span v-if="itm.insurerName && itm.make || itm.model"> - </span></span>{{ itm.make }} {{ itm.model }}</span>
                    </div>
                  </template>
                </a>
              </div>

              <div ref="dropdown"
                   class="dropdown-menu search-filter__dropdown-menu"
                   style="width: 215px"
                   aria-labelledby="search-filter-input"
                   v-if="!isDateFilter && filteredFilters.length && !tokenComplete && token[0] && token[0].id === 'dateRange'">
                <template>
                  <a class="dropdown-item search-filter__dropdown-item font-12"
                     ref="filters"
                     @focus="showDropdown"
                     @keydown="handleKeydownDropdown"
                     href="#"
                     style="display: block"
                     @click.prevent.stop="selectFilter(0)">
                    <span>This month </span><span style="float: right">{{ computedCurrentMonth }}</span>
                  </a>
                  <a class="dropdown-item search-filter__dropdown-item font-12"
                     ref="filters"
                     @focus="showDropdown"
                     @keydown="handleKeydownDropdown"
                     href="#"
                     style="display: block"
                     @click.prevent.stop="selectFilter(1)">
                    <span>Last month </span><span style="float: right">{{ computedLastMonth }}</span>
                  </a>
                  <a class="dropdown-item search-filter__dropdown-item font-12"
                     ref="filters"
                     @focus="showDropdown"
                     @keydown="handleKeydownDropdown"
                     href="#"
                     @click.prevent.stop="selectFilter(2)">
                    <span>Custom Range</span>
                  </a>
                </template>
              </div>


            </div>
          </li>
        </ul>
      </div>
      <div ref="overlay" class="overlay" @click="hideDropdown"></div>
    </div>
    <div class="rs-button-container"></div>

    <div v-if="debug" class="debug">
      <div class="debug__title">Search-Filter Debug:</div>
      <div class="debug__item"><span class="debug__key">Last Key Code:</span><span class="debug__value">
        {{ lastKeyCode }}
      </span></div>
      <div class="debug__item"><span class="debug__key">tokenComplete:</span><span class="debug__value">{{
          tokenComplete
        }}</span></div>
      <div class="debug__item"><span class="debug__key">dropdownPosition:</span><span
          class="debug__value">{{ dropdownPosition }}</span></div>
      <div class="debug__item"><span class="debug__key">filterResult:</span><span
          class="debug__value">{{ filterResult || 'Empty' }}</span></div>
      <div class="debug__item"><span class="debug__key">selectedTokenIndex:</span><span
          class="debug__value">{{ selectedTokenIndex || 'Empty' }}</span></div>
      <div class="debug__item"><span class="debug__key">selectedIndexes:</span><span
          class="debug__value">{{ selectedIndexes || 'Empty' }}</span></div>
      <div class="debug__item"><span class="debug__key">selectedCategories:</span><span
          class="debug__value">{{ selectedCategories || 'Empty' }}</span></div>
      <div class="debug__item"><span class="debug__key">selectedCategoryLabel:</span><span
          class="debug__value">{{ selectedCategoryLabel || 'Empty' }}</span></div>
      <div class="debug__item"><span class="debug__key">selectedCategory:</span><span
          class="debug__value">{{ selectedCategory || 'Empty' }}</span></div>
      <div class="debug__item"><span class="debug__key">filterResultObj:</span><span
          class="debug__value debug__text">{{ filterResultObj || 'Empty' }}</span></div>
    </div>
  </form>
</template>

<script>
/*eslint-disable */
// import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import * as dayjs from 'dayjs'

var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
import _ from 'lodash'
import Vue from 'vue'
import Vue2TouchEvents from 'vue2-touch-events'
// This variable will hold the reference to
// document's click handler
let handleOutsideClick
Vue.directive('closable', {
  bind (el, binding, vnode) {
    // Here's the click/touchstart handler
    // (it is registered below)
    handleOutsideClick = (e) => {
      e.stopPropagation()
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { handler, exclude } = binding.value
      // This variable indicates if the clicked element is excluded
      let clickedOnExcludedEl = false
      exclude.forEach(refName => {
        // We only run this code if we haven't detected
        // any excluded element yet
        if (!clickedOnExcludedEl) {
          // Get the element using the reference name
          let excludedEl = vnode.context.$refs[refName]
          // See if this excluded element
          // is the same element the user just clicked on
          if (excludedEl) {
            if (excludedEl._isVue) {
              excludedEl = excludedEl.$el
            }
            clickedOnExcludedEl = excludedEl.contains(e.target)
          }
        }
      })
      // console.log(e.target)
      // We check to see if the clicked element is not
      // the dialog element and not excluded
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        vnode.context[handler]()
      }
    }
    // Register click/touchstart event listeners on the whole page
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  },
  unbind () {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('touchstart', handleOutsideClick)
  }
})

export default {
  props: {
    tokenCopy: {
      type: Array,
      default: () => [],
    },
    tokensCopy: {
      type: Object,
      default: () => {}
    },
    additionalMenu: {
      type: Array,
      default: () => [],
    },
    limitLength: {
      type: Number,
      default: null,
    },
    optionsLength: {
      type: Number,
      default: 0,
    },
    isMyltipleOptions: {
      type: Boolean,
      default: false,
    },
    isAssignModal: {
      type: Boolean,
      default: false,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    isShowSearIcon: {
      type: Boolean,
      default: true,
    },
    isMyltiple: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'empty' // | 'cards' | 'activities' | --take a look in Filter.vue file for other options
    },
    value: {
      type: [String, Object],
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Search or Filter results'
    },
    height: {
      type: String,
      default: '39px'
    },
    filters: {
      type: Array,
      default: () => []
    },
    debug: {
      type: Boolean,
      default: false,
    },
    isIgnoreSiteAndPageHeader: {
      type: Boolean,
      default: false,
    },
    filterValue: {
      type: String,
      default: '',
    },
  },
  components: {
    Vue2TouchEvents,
  },
  data () {
    let startDate = new Date()
    let endDate = new Date()
    endDate.setDate(endDate.getDate() + 6)

    let today = new Date()
    today.setHours(0, 0, 0, 0)
    let last7Days = new Date()
    last7Days.setDate(last7Days.getDate() - 6)
    let last14Days = new Date()
    last14Days.setDate(last14Days.getDate() - 13)

    let yesterday = new Date()
    yesterday.setDate(today.getDate() - 1)
    yesterday.setHours(0, 0, 0, 0)

    let date = new Date()
    date.setHours(0, 0, 0, 0)
    const thisMonthStart = new Date(date.getFullYear(), date.getMonth(), 1)
    const thisMonthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0)

    return {
      dateRange: { startDate, endDate },
      isTokens: true,
      selectedCategory: -1,
      selectedCategoryLabel: '',
      token: [],
      search: '',
      computedUpdate: 0,
      searchForOptions: '',
      isCanOpenDropdown: true,
      isTokenComplete: false,
      selectedTokenIndex: 0,
      tokens: {
        0: [],
        1: [],
        2: [],
      },
      searches: {},
      selectedCategories: [],
      dropdownPosition: 0,
      dropdownPosition2: 0,
      dropdown: {},
      isInputInFocus: false,
      localeData: {
        format: 'DD/MM/YYYY'
      },
      selectedIndexes: [],
      isShowDropdown: false,
      isShowLoading: false,
      predefinedTimeRanges: {
        'Today': [today, today],
        'Yesterday': [yesterday, yesterday],
        'Last 7 Days': [last7Days, startDate],
        'Last 14 Days': [last14Days, startDate],
        'This month': [thisMonthStart, thisMonthEnd],
        'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
        'Custom Range': [today, today],
      },
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      showDateRange: false,
      lastKeyCode: null,
      lastKeyCode2: null,
    }
  },
  computed: {
    computedAdditonalMenu() {
      return _.orderBy(this.additionalMenu, [(itm) => this.isDropdownItemActive(itm) ? 2 : 1], 'desc')
    },
    computedTokens () {
      let completedTokens = _.filter(this.tokens, (tkn) => {
        return tkn[0] && tkn[0].isCompleted
      })
      let unCompletedTokens = _.filter(this.tokens, (tkn) => {
        return !(tkn[0] && tkn[0].isCompleted)
      })
      let result = []
      _.forEach(completedTokens, (tkn) => {
        result.push(tkn)
      })
      _.forEach(unCompletedTokens, (tkn) => {
        result.push(tkn)
      })
      return result
    },
    computedCurrentToken () {
      if (this.tokens[this.selectedTokenIndex]) {
        return this.tokens[this.selectedTokenIndex]
      }
      return []
    },
    computedCurrentFilter () {
      if (this.computedCurrentToken && this.computedCurrentToken[0]) {
        return _.find(_.cloneDeep(this.filters), (flt) => {
          return flt.id === this.computedCurrentToken[0].id
        })
      }
      return {}
    },
    currentLevelForMyltiple () {
      return this.computedCurrentToken.length - 1
    },
    maxLengthForMyltiply () {
      if (this.selectedCategory >= 0) {
        if (this.isMyltipleOptions) {
          return this.mappedFilters[this.selectedCategory].options[0].length
        }
        // console.log('this.mappedFilters[this.selectedCategory]', this.mappedFilters[this.selectedCategory]);
        return this.mappedFilters[this.selectedCategory].options.length
      } else {
        return null
      }
    },
    computedCurrentMonth () {
      return this.months[this.currentMonth] + ' ' + new Date().getFullYear()
    },
    countOfSelectedOptions () {
      let count = 0
      _.forEach(this.token, (t) => {
        if (t.options) {
          count++
        }
      })
    },
    computedLastMonth () {
      if (this.currentMonth === 0) {
        return 'December' + ' ' + (new Date().getFullYear() - 1)
      } else {
        let month = this.months[this.currentMonth - 1]
        return month + ' ' + new Date().getFullYear()
      }
    },
    currentMonth () {
      return new Date().getMonth()
    },
    computedPlaceholder () {
      return this.token.length ? '' : this.placeholder
    },
    currentLevel () {
      return this.token.length - 1 // minus category select
    },
    maxLength () {
      if (this.selectedCategory >= 0) {
        if (this.isMyltipleOptions) {
          return this.mappedFilters[this.selectedCategory].options[0].length
        }
        // console.log('this.mappedFilters[this.selectedCategory]', this.mappedFilters[this.selectedCategory]);
        return this.mappedFilters[this.selectedCategory].options.length
      } else {
        return null
      }
    },
    // add original index for search filter feature
    mappedFilters () {
      this.computedUpdate++
      return _.cloneDeep(this.filters).map((filter, index) => {
        filter.originalIndex = index
        return filter
      })
    },
    filteredMultipleFilters () {
      let options = []
      if (this.computedCurrentToken && this.computedCurrentToken.length >= 1 && this.isMyltipleOptions && !(this.computedCurrentToken[0] && this.computedCurrentToken[0].isCompleted)) {
        options = this.mappedFilters[this.selectedCategory].options[0].filter(filter => {
          if (filter.label !== null) {
            let key = this.mappedFilters[this.selectedCategory].id
            let selectedOptions = ''
            let index = -1
            for (let ind in this.filterResultObj) {
              if (this.filterResultObj[ind].hasOwnProperty(key)) {
                index = ind
              }
            }
            if (this.filterResultObj[index] && this.filterResultObj[index][key]) {
              selectedOptions = this.filterResultObj[index][key].toLowerCase().split(';').filter(Boolean)
            }
            const label = filter.label.toLowerCase()
            const searchWord = this.search.trim().toLowerCase()
            if (!_.isEmpty(selectedOptions)) {
              return !selectedOptions.includes(label) && label.includes(searchWord)
            }
            return label.includes(searchWord)
          }
        })
      } else if (this.tokens.length === 0 || (this.tokens && this.tokens[this.selectedTokenIndex] && this.tokens[this.selectedTokenIndex].length === 0)) {
        // console.log('filtered filter -- CATEGORY')
        options = this.mappedFilters.filter(filter => {
          const label = filter.label.toLowerCase()
          const searchWord = this.search.trim().toLowerCase()
          return !this.selectedIndexes.includes(filter.originalIndex) && label.includes(searchWord) && !this.selectedCategories.includes(filter.label)
        })
      } else if (this.tokens && this.tokens[this.selectedTokenIndex] && this.tokens[this.selectedTokenIndex].length > 0 && !(this.tokens[this.selectedTokenIndex][0] && this.tokens[this.selectedTokenIndex][0].isCompleted)
          && this.mappedFilters[this.selectedCategory] && this.mappedFilters[this.selectedCategory].options[this.computedCurrentToken.length - 1] && !this.mappedFilters[this.selectedCategory].isCompleted) {
        // console.log('filtered filter -- OPTIONS')
        options = this.mappedFilters[this.selectedCategory].options[this.computedCurrentToken.length - 1].filter(filter => {
          if (filter.label !== null && filter.label) {
            let key = this.mappedFilters[this.selectedCategory].id
            let selectedOptions = ''
            let index = -1
            for (let ind in this.filterResultObj) {
              if (this.filterResultObj[ind].hasOwnProperty(key)) {
                index = ind
              }
            }
            if (this.filterResultObj[index] && this.filterResultObj[index][key]) {
              selectedOptions = this.filterResultObj[index][key].toLowerCase().split(';').filter(Boolean)
            }
            const label = filter.label.toLowerCase()
            const searchWord = this.search.trim().toLowerCase()
            if (!_.isEmpty(selectedOptions)) {
              return !selectedOptions.includes(label) && label.includes(searchWord)
            }
            return label.includes(searchWord)
          }
        })
      } else {
        options = this.mappedFilters.filter(filter => {
          return !this.selectedIndexes.includes(filter.originalIndex) && !this.selectedCategories.includes(filter.label)
        })
      }
      if (this.limitLength) {
        options = options.slice(0, this.limitLength)
      }
      return options
    },
    filterdSingleFilters () {
      // first select filter category
      if (this.token && this.token.length > 1 && this.isMyltipleOptions) {
        let options = this.mappedFilters[this.selectedCategory].options[0].filter(filter => {
          if (filter.label !== null) {
            const label = filter.label.toLowerCase()
            const searchWord = this.search.trim().toLowerCase()
            return label.includes(searchWord)
          }
        })
        if (this.limitLength) {
          options = options.slice(0, this.limitLength)
        }
        return options
      }
      if (this.token.length === 0) {
        // console.log('filtered filter -- CATEGORY')
        return this.mappedFilters.filter(filter => {
          const label = filter.label.toLowerCase()
          const searchWord = this.search.trim().toLowerCase()
          return label.includes(searchWord)
        })
      } else if (this.token.length > 0 && this.mappedFilters[this.selectedCategory].options[this.currentLevel]) {
        // console.log('filtered filter -- OPTIONS')
        let options = this.mappedFilters[this.selectedCategory].options[this.currentLevel].filter(filter => {
          if (filter.label !== null) {
            const label = filter.label.toLowerCase()
            const searchWord = this.search.trim().toLowerCase()
            return label.includes(searchWord)
          }
        })
        if (this.limitLength) {
          options = options.slice(0, this.limitLength)
        }
        return options
      } else {
        return this.mappedFilters
      }
    },
    filteredFilters () {
      if (this.isMyltiple) {
        let filter = this.searches[this.computedCurrentFilter.id];
        if (!filter) {
          this.debouncedUpdateLoading();
        }
        if (this.computedCurrentFilter && this.computedCurrentFilter.isNeedSearch && filter) {
          return _.filter(this.filteredMultipleFilters,(itm, ind) => {
            if (ind + 1 === this.filteredMultipleFilters.length) {
              this.debouncedUpdateLoading();
            }
            return _.includes(_.trim(_.toLower(itm.label)), _.toLower(filter))
          })
        } else {
          return this.filteredMultipleFilters
        }
      } else {
        let filter = this.searches[this.computedCurrentFilter.id];
        if (!filter) {
          this.debouncedUpdateLoading();
        }
        if (this.computedCurrentFilter && this.computedCurrentFilter.isNeedSearch && filter) {
          return _.filter(this.filterdSingleFilters,(itm, ind) => {
            if (ind + 1 === this.filterdSingleFilters.length) {
              this.debouncedUpdateLoading();
            }
            return _.includes(_.trim(_.toLower(itm.label)), _.toLower(filter))
          })
        } else {
          return this.filterdSingleFilters
        }
      }
    },
    isDateFilter () {
      return false
      // return this.filteredFilters[0] && this.filteredFilters[0].type && this.filteredFilters[0].type === 'Date';
    },
    tokenComplete () {
      if (this.isMyltiple) {
        return false
      }
      if (this.isMyltipleOptions) {
        return this.isTokenComplete
      }
      return this.currentLevel >= this.maxLength
    },
    filterResultObj () {
      if (this.isMyltiple) {
        let result = {}

        _.forEach(this.mappedFilters.slice(), (filter) => {
          result[filter.originalIndex] = {
            search: this.search,
          }
          let id = filter.id
          let token = null
          for (let key in this.tokens) {
            if (this.tokens[key][0] && this.tokens[key][0].id === id) {
              token = this.tokens[key].slice()
              break
            }
          }
          _.forEach(token, (itm, index) => {
            if (index === 0) {
              result[filter.originalIndex][id] = ''
            } else {
              if (token[0].id === 'dateRange') {
                result[filter.originalIndex][id] = this.dateRange
              } else if (itm.label) {
                result[filter.originalIndex][id] += itm.label + ';'
              }
            }
          })
        })

        // _.forEach(this.mappedFilters, (filter) => {
        //   result[filter.originalIndex] = {
        //     search: this.search,
        //   }
        //   let id = filter.id
        //   let token = null
        //   for (let key in this.tokens) {
        //     if (this.tokens[key][0] && this.tokens[key][0].id === id) {
        //       token = this.tokens[key]
        //       break
        //     }
        //   }
        //   console.log('token', token)
        //   _.forEach(token, (itm, index) => {
        //     if (index === 0) {
        //       result[filter.originalIndex][id] = ''
        //     } else {
        //       if (token[0].id === 'dateRange') {
        //       result[filter.originalIndex][id] = this.dateRange
        //    } else if (itm.label) {
        //         result[filter.originalIndex][id] += itm.label + ';'
        //       }
        //     }
        //   })
        // })
        return result
      } else {
        let resObj = {
          // include manual input from text input
          search: this.search
        }
        if (this.token.length > 0) {
          this.token.forEach((token, index) => {
            const id = token.id
            // category always even or 0 -- due to nature of array
            if (index === 0 || index % 2 === 0) {
              resObj[id] = ''

              // value always odd
            } else if (token.type === 'Date') {
              resObj[this.token[index - 1].id] = token
            } else {
              resObj[this.token[index - 1].id] = token.label
            }
          })
        }
        return resObj
      }
    },
    filterResult () {
      let token = this.isMyltiple ? this.computedCurrentToken : this.token
      let res = ''
      if (token && token.length === 0) {
        res = ''
      } else if (token.length > 0) {
        token.forEach((tkn, index) => {
          // only output string
          if (tkn.resultObjectOnly || (index > 0 && token[index - 1] && token[index - 1].resultObjectOnly)) return

          // ignore first token, it is category for example, 'Rego', 'Vehicle Owner'
          if (index === 0) {
            res = ''
          } else if (index === 1) {
            res = tkn.label ? tkn.label : res
          } else if (index > 1) {
            res = tkn.label ? res.trim() + ' ' + tkn.label : res
          }
        })
      }
      res += ' ' + this.search
      res = res.trim()
      // console.log('token.length', this.token.length, res);
      return res
    },
    boxStyle () {
      return {
        'height': this.height
      }
    }
  },
  watch: {
    tokensCopy: {
      handler (val) {
        if (this.isMyltiple && !_.isEmpty(val)) {
          let result = {}
          let tokensCopyObj = _.cloneDeep(val);
          for (let key in tokensCopyObj) {
            result[key] = _.cloneDeep(tokensCopyObj[key])
          }
          this.$set(this, 'tokens', _.cloneDeep(result))
          // this.tokens = _.cloneDeep(this.tokensCopy)
          for (let key in this.tokens) {
            if (this.tokens[key][0] && this.tokens[key][0].hasOwnProperty('originalIndex')) {
              this.selectedIndexes.push(this.tokens[key][0].originalIndex)
              this.selectedCategory = this.tokens[key][0].originalIndex
            }
          }
        }
      },
      immediate: true,
    },
    filterValue (val) {
      console.log('filterValue', val)
      this.search = val
    },
    filterResult (val) {
      this.$emit('input', val)
    },
    filterResultObj: {
      handler: function (valObj) {
        this.$emit('change', valObj)
      },
      deep: true
    },
  },
  created () {
    this.debouncedUpdateLoading = _.debounce(function (v) {
      this.updateLoading(v);
    }, 700);
  },
  mounted () {

    // additional external listener
    // these element are not covered by overlay
    if (!this.isIgnoreSiteAndPageHeader && document.querySelector('.site-header')) {
      document.querySelector('.site-header').addEventListener('click', this.hideDropdown)
    }

    if (!this.isIgnoreSiteAndPageHeader && !this.isSearch) {
      if (document.querySelector('.page-header')) {
        document.querySelector('.page-header').addEventListener('click', this.hideDropdown)
      }
    }

    if (this.filterValue) {
      this.search = this.filterValue
    }

    if (this.isMyltiple && !_.isEmpty(this.tokensCopy)) {
      let result = {}
      let tokensCopyObj = _.cloneDeep(this.tokensCopy);
      for (let key in tokensCopyObj) {
        result[key] = _.cloneDeep(tokensCopyObj[key])
      }
      this.$set(this, 'tokens', _.cloneDeep(result))
      // this.tokens = _.cloneDeep(this.tokensCopy)
      for (let key in this.tokens) {
        if (this.tokens[key][0] && this.tokens[key][0].hasOwnProperty('originalIndex')) {
          this.selectedIndexes.push(this.tokens[key][0].originalIndex)
          this.selectedCategory = this.tokens[key][0].originalIndex
        }
      }
    } else if (!_.isEmpty(this.tokenCopy)) {
      let data = this.tokenCopy.slice()
      this.token = data
      this.selectedCategory = data[0].originalIndex
    }

    // this.mappedFilters = Filter.generate(this.type);
  },
  unmounted () {
    document.querySelector('.site-header').removeEventListener('click', this.hideDropdown)
    document.querySelector('.page-header').removeEventListener('click', this.hideDropdown)
  },
  filters: {
    formatDate (val) {
      return dayjs(val).format('DD/MM/YYYY')
    }
  },
  methods: {
    clickUp() {
      console.log(this.filteredFilters)
    },
    isDropdownItemActive(itm) {
      let search = _.toLower(this.search)
      if(_.toLower(itm.estimateNumber) == search || _.toLower(itm.regoNumber) == search ||
          _.toLower(itm.customerName) == search || _.toLower(itm.insurerName) == search ||
          _.toLower(itm.make) == search || _.toLower(itm.model) == search){
        return true
      }
    },
    _isEmpty(v) {
      return _.isEmpty(v)
    },
    updateLoading () {
      setTimeout(() => {
        this.isShowLoading = false;
      }, 200)
    },
    setSearchForCurrentFilter () {
      if (this.computedCurrentFilter) {
        this.isShowLoading = true;
        this.$set(this.searches, [this.computedCurrentFilter.id], this.searchForOptions)
      }
    },
    clickOnSearchIcon () {
      this.showDropdown()
      this.$nextTick(() => {
        if (this.$refs['search-filter-input']) {
          this.$refs['search-filter-input'].focus()
        }
      })
    },
    focusInput () {
      this.isInputInFocus = true
      this.showDropdown()
    },
    showDropdown () {
      if (!this.isCanOpenDropdown) {
        return
      }
      // if (this.isMyltiple && this.countOfSelectedOptions === this.optionsLength) {
      //   console.log('show return')
      //   return;
      // }
      // if (this.tokenComplete && !this.isMyltiple) {
      //   console.log('show return')
      //   return
      // }
      this.isShowDropdown = true
      // $(this.$refs.dropdown).dropdown().toggle(true);
      $(this.$refs.overlay).show()
    },
    hideDropdown () {
      // $(this.$refs.dropdown).dropdown().toggle(false);)
      if (this.isMyltipleOptions && this.isMyltiple && this.computedCurrentToken && (this.computedCurrentToken.length >= 2 || (this.computedCurrentToken[0] && this.computedCurrentToken[0].id === 'dateRange'))) {
        this.showDateRange = false
        if (this.computedCurrentToken[0] && this.computedCurrentToken[0].id === 'dateRange') {
          let result = {}
          if (this.dateRange && this.dateRange.start && this.dateRange.end) {
            let start = this.dateRange.start
            let end = this.dateRange.end
            result = { label: start + ' - ' + end }
          }
          if (this.computedCurrentToken.length === 1) {
            this.tokens[this.selectedTokenIndex].push(result)
          } else {
            this.tokens[this.selectedTokenIndex][1] = result
          }
        }
        if (this.tokens[this.selectedTokenIndex][0]) {
          this.tokens[this.selectedTokenIndex][0].isCompleted = true
          this.$emit('selectedToken', this.tokens[this.selectedTokenIndex][0])
        }

        if (this.selectedTokenIndex + 1 !== this.filters.length) {
          this.selectedTokenIndex += 1
        }
      } else if (this.isMyltipleOptions && this.token.length > 2) {
        this.isTokenComplete = true
      }
      this.isShowDropdown = false
      $(this.$refs.overlay).hide()
    },
    remove (indexToken) {
      if (this.isMyltiple) {
        if (this.computedTokens[indexToken] && this.computedTokens[indexToken][0]) {
          let token = this.computedTokens[indexToken][0]
          let originalTokenIndex = -1
          for (let key in this.tokens) {
            if (this.tokens[key][0] && this.tokens[key][0].id === token.id) {
              originalTokenIndex = key
            }
          }
          // let originalTokenIndex = _.findIndex(this.tokens, (tkn) => {
          //   console.log(tkn, tkn[0], 'for each')
          //   return tkn[0] && tkn[0].id === token.id;
          // })
          if (originalTokenIndex !== -1) {
            let tokensCopy = _.cloneDeep(this.tokens)
            tokensCopy[originalTokenIndex][0].isCompleted = false
            this.$set(this, 'tokens', tokensCopy)
          } else {
            token.isCompleted = false
          }
          if (this.selectedCategories.includes(token.label)) {
            this.selectedCategories = _.filter(this.selectedCategories, (cat) => {
              return cat !== token.label
            })
          }
          if (this.selectedIndexes.includes(Number(token.originalIndex))) {
            this.selectedIndexes = _.filter(this.selectedIndexes, (ind) => {
              return Number(ind) !== Number(token.originalIndex)
            })
          }
        }
        if (this.computedTokens[indexToken] && this.computedTokens[indexToken][0] && this.computedTokens[indexToken][0].hasOwnProperty('originalIndex')) {
          let index = this.computedTokens[indexToken][0].originalIndex
          let tokenIndex = -1
          for (let key in this.tokens) {
            if (this.tokens[key][0] && Number(this.tokens[key][0].originalIndex) === Number(index)) {
              // tokenIndex = Number(this.tokens[key][0].originalIndex);
              tokenIndex = Number(key)
            }
          }
          if (tokenIndex !== -1) {
            this.tokens[tokenIndex].splice(0, this.tokens[tokenIndex].length)
          }
        }
        if (this.selectedTokenIndex !== 0) {
          this.selectedTokenIndex -= 1
        }
        // console.log("remove", this.selectedTokenIndex, this.selectedIndexes)
        if (this.computedTokens[this.selectedTokenIndex] && this.computedTokens[this.selectedTokenIndex][0] && this.computedTokens[this.selectedTokenIndex][0].label) {
          this.selectedCategoryLabel = this.computedTokens[this.selectedTokenIndex][0].label
        }
        if (this.selectedIndexes.length === this.optionsLength - 1) {
          for (let ind in this.tokens) {
            if (_.isEmpty(this.tokens[ind])) {
              this.selectedTokenIndex = Number(ind)
            }
          }
        }

        this.selectedCategory = this.selectedTokenIndex
        if (_.isEmpty(this.selectedIndexes)) {
          this.selectedTokenIndex = 0
          this.selectedCategoryLabel = ''
          this.selectedCategory = -1
        }
        this.isCanOpenDropdown = true
        let value = {}
        for (let key in _.cloneDeep(this.tokens)) {
          value[key] = this.tokens[key].slice()
        }

        this.$emit('updateTokens', value)
        // this.$emit('updateTokens', this.tokens)
      } else {
        this.token = []
        this.isTokenComplete = false
        this.search = ''
        this.selectedCategory = -1
        this.dropdownPosition = 0
        this.showDateRange = false
        this.$emit('updateToken', this.token)
      }
      this.computedUpdate++
    },
    selectFilter (index, e) {
      e.preventDefault()
      e.stopPropagation()
      if (_.isEmpty(this.computedCurrentFilter)) {
        this.searches = {};
        this.searchForOptions = '';
      }
      if (this.isMyltiple) {
        if (this.tokens[this.selectedTokenIndex] && this.tokens[this.selectedTokenIndex].length === 0) {
          this.selectCategory(index)
        }
        this.$nextTick(() => {
          let isHasEmptyTokens = false
          _.forEach(this.tokens, (tkn) => {
            if (_.isEmpty(tkn)) {
              isHasEmptyTokens = true
            }
          })
          if (this.tokens[this.selectedTokenIndex] && this.tokens[this.selectedTokenIndex].length === 2) {
            if (this.selectedTokenIndex + 1 === this.optionsLength && !isHasEmptyTokens) {
              this.isCanOpenDropdown = false
            } else if (this.computedCurrentToken.isCompleted) {
              console.log('selected Token Index', this.selectedTokenIndex)
              this.selectedTokenIndex += 1
            }
            if (!this.selectedCategories.includes(this.selectedCategoryLabel)) {
              this.selectedCategories.push(this.selectedCategoryLabel)
            }
            // this.hideDropdown();
          }
        })
        if (this.tokens[this.selectedTokenIndex] && this.tokens[this.selectedTokenIndex][0] && this.tokens[this.selectedTokenIndex][0].isCompleted && this.tokens[this.selectedTokenIndex][0].id && this.filteredFilters[index].id) {
          if (_.isEmpty(this.tokens[index])) {
            this.selectedTokenIndex = index
          } else {
            this.selectedTokenIndex = this.filteredFilters[index].originalIndex
          }
        }
        // if (this.tokens[this.selectedTokenIndex] && this.tokens[this.selectedTokenIndex][0] && this.tokens[this.selectedTokenIndex][0].isCompleted && this.tokens[this.selectedTokenIndex][0].id && this.filteredFilters[index].id && this.selectedTokenIndex + 1 !== (this.tokens.length)) {
        //   this.selectedTokenIndex += 1
        //   console.log(this.selectedTokenIndex, 'selectedTokenResult2')
        // }
        this.tokens[this.selectedTokenIndex].push(this.filteredFilters[index])
        if (this.tokens[this.selectedTokenIndex] && this.tokens[this.selectedTokenIndex][0] && this.tokens[this.selectedTokenIndex][0].hasOwnProperty('originalIndex') && !this.selectedIndexes.includes(Number(this.tokens[this.selectedTokenIndex][0].originalIndex))) {
          this.selectedIndexes.push(this.tokens[this.selectedTokenIndex][0].originalIndex)
        }
        if (this.tokens[this.selectedTokenIndex] && this.tokens[this.selectedTokenIndex][0] && this.tokens[this.selectedTokenIndex][0].hasOwnProperty('label') && !this.selectedCategories.includes(this.tokens[this.selectedTokenIndex][0].label)) {
          this.selectedCategories.push(this.tokens[this.selectedTokenIndex][0].label)
          this.selectedCategoryLabel = this.tokens[this.selectedTokenIndex][0].label
          this.selectedCategory = this.tokens[this.selectedTokenIndex][0].originalIndex
        }
        this.resetSearchInput()
        if (this.computedCurrentToken[0] && this.computedCurrentToken[0].id === 'dateRange') {
          this.showDateRange = true
        }
        let value = {}
        for (let key in _.cloneDeep(this.tokens)) {
          value[key] = this.tokens[key].slice()
        }

        this.$emit('updateTokens', value)
      } else {
        if (this.token.length === 0) {
          // select category
          this.selectCategory(index)
        } else {
          // do nothing, and continue
          // select a non-category filter will be calculated in filtered filters
        }

        // selecting a date
        if (this.filteredFilters[index].label === 'Date' || this.filteredFilters[index].label === 'Custom Date Range') {
          this.showDateRange = true
          this.$nextTick(() => {
            this.$refs.picker.togglePicker(true)
          })
        }

        // hide dropdown when a token complete
        this.$nextTick(() => {
          // if complete hide dropdown
          if (this.tokenComplete) {
            this.hideDropdown()
          }
        })

        if (this.filteredFilters[index].label !== 'Custom Date Range') {
          this.token.push(this.filteredFilters[index])
          this.$emit('updateToken', this.token)
        }

        this.resetSearchInput()
      }
    },
    selectCategory (index) {
      if (this.isMyltiple) {
        this.selectedCategoryLabel = this.filteredFilters[index].label
        this.selectedCategory = this.filteredFilters[index].originalIndex
      } else {
        if (this.filteredFilters[index]) {
          this.selectedCategory = this.filteredFilters[index].originalIndex
        }
      }
    },
    resetSearchInput () {
      this.search = ''
      this.dropdownPosition = 0
      const searchInput = this.$refs['search-filter-input']
      this.$nextTick(() => {
        if (searchInput) {
          searchInput.focus()
        }
      })
    },
    // Search Input Keydown
    handleKeydown (e) {
      switch (e.keyCode) {
        case 27: {
          this.hideDropdown()
          break
        }
        case 32: // space
        {
          if (!this.search.trim().length) return
          // search if match any token label
          const index = this.filteredFilters.findIndex(filter => filter.label.toLowerCase() === this.search.trim().toLowerCase())
          if (index > -1) {
            event.preventDefault()
            this.selectFilter(index)
          }
          break
        }
        case 8: // delete
        {
          if (this.isMyltiple) {
            if (this.search) {
              return
            }
            let index = -1
            for (let key in this.computedTokens) {
              if (this.computedTokens[key].length) {
                index = key
              }
            }
            this.remove(index)
          } else {
            if (this.token.length && !this.search.length) {
              let lastFilter = this.token.pop()

              // pop again if the category is resultObjectOnly
              if (this.token[this.token.length - 1] && this.token[this.token.length - 1].resultObjectOnly) {
                lastFilter = this.token.pop()
              }

              console.log('lastFilter', lastFilter)
              if (!this.isMyltiple) {
                this.$emit('updateToken', this.token)
              }
              // this.search = lastFilter.label;
              this.search = ''
            }
          }

          break
        }
        case 13: // enter
        {
          e.preventDefault()
          this.hideDropdown()
        }
          break
        default: {
          this.showDropdown()
        }
      }
    },
    // Search Input Keyup
    handleKeyup (e) {
      switch (e.keyCode) {
        case 38: // up
        {
          e.preventDefault()
          break
        }
        case 40: // down
        {
          e.preventDefault()
          this.dropdownPosition = 0
          this.dropdownPosition2 = 0;
          if (
              !(this.isShowDropdown && !this.isDateFilter && !(this.tokenComplete && !this.isMyltiple) && !(this.token[0] && this.token[0].id === 'dateRange')
                  && ((this.computedCurrentFilter && this.computedCurrentFilter.isNeedSearch) && ((this.filteredFilters.length) || (!this.filteredFilters.length && this.searchForOptions)) || this.filteredFilters.length))
              && this.isShowDropdown && !_.isEmpty(this.additionalMenu)) {
            this.$refs['filters2'][this.dropdownPosition].focus()
          } else {
            this.$refs['filters'][this.dropdownPosition].focus()
          }
          break
        }
        case 8: // delete
        {
          // when hit delete and everything empty, reset state
          if (!this.token.length && !this.search.length) {
            this.remove()
          }
          break
        }
        default: {
          break
        }
      }
    },
    // handle keys on dropdown
    handleKeydownDropdown (e) {
      e.preventDefault()
      console.log('handleKeydownDropdown:', e.keyCode, e)
      this.lastKeyCode = e.keyCode
      switch (e.keyCode) {
        case 38: // up
        {
          this.dropdownPosition--
          if (this.dropdownPosition > -1) {
            this.$refs['filters'][this.dropdownPosition].focus()
          } else {
            // focus to input
            this.dropdownPosition = 0
            this.$refs['search-filter-input'].focus()
            this.showDropdown()
          }
          break
        }
        case 40: // down
        {
          this.dropdownPosition++
          if (this.dropdownPosition < this.$refs['filters'].length) {
            console.log('')
          } else {
            this.dropdownPosition = 0
          }
          this.$refs['filters'][this.dropdownPosition].focus()
          break
        }
        case 32: // space
        case 13: // enter
        {
          this.selectFilter(this.dropdownPosition, e)
          this.dropdownPosition = 0
          break
        }
        case 8: // delete
        case 27: // esc
        default: {
          if (e.key.length === 1) {
            this.search += e.key
          }
          this.showDropdown()
          this.dropdownPosition = 0
          this.$refs['search-filter-input'].focus()
        }
      }
    },

    handleKeydownDropdown2 (e) {
      e.preventDefault()
      console.log('handleKeydownDropdown:', e.keyCode, e)
      this.lastKeyCode2 = e.keyCode
      switch (e.keyCode) {
        case 38: // up
        {
          this.dropdownPosition2--
          if (this.dropdownPosition2 > -1) {
            this.$refs['filters2'][this.dropdownPosition2].focus()
          } else {
            // focus to input
            this.dropdownPosition2 = 0
            this.$refs['search-filter-input'].focus()
            this.showDropdown()
          }
          break
        }
        case 40: // down
        {
          this.dropdownPosition2++
          if (this.dropdownPosition2 < this.$refs['filters2'].length) {
            console.log('')
          } else {
            this.dropdownPosition2 = 0
          }
          this.$refs['filters2'][this.dropdownPosition2].focus()
          break
        }
        case 32: // space
        case 13: // enter
        {
          this.$emit('selectAdditional', this.additionalMenu[this.dropdownPosition2])
          this.dropdownPosition2 = 0
          break
        }
        case 8: // delete
        case 27: // esc
        default: {
          if (e.key.length === 1) {
            this.search += e.key
          }
          this.showDropdown()
          this.dropdownPosition2 = 0
          this.$refs['search-filter-input'].focus()
        }
      }
    },
    handleDateUpdate () {
      const obj = this.dateRange
      obj.type = 'Date'
      obj.label = `${this.dateRange.startDate} - ${this.dateRange.endDate}`
      // this.token.pop();

      this.token.push(this.dateRange)
      if (!this.isMyltiple) {
        this.$emit('updateToken', this.token)
      }

      // focus on next input
      this.$nextTick(() => {
        this.resetSearchInput()
      })
    },
    handleDateToggle (show) {
      // console.log('handleDateToggle', arguments);
      // console.log('this.isDateFilter', this.isDateFilter);
      // console.log('this.currentLevel', this.currentLevel);
      if (!show) {
        this.showDateRange = false
        this.$nextTick(() => {
          // console.log('this.currentLevel', this.currentLevel);

          if (this.token[this.currentLevel].startDate) {
            return
          } else {
            const lastFilter = this.token.pop()
            if (!this.isMyltiple) {
              this.$emit('updateToken', this.token)
            }
            console.log('lastFilter', lastFilter)
            // this.search = lastFilter.label.slice(0, -1);
            this.search = ''

            this.$nextTick(() => {
              const searchInput = this.$refs['search-filter-input']
              if (searchInput) {
                searchInput.focus()
              }
            })
          }
        })
      }
    }
  }
}
</script>
<style>
.custom-dropdown-2 .dateRangeInput {
  padding-right: 10px;
  padding-left: 10px;
}

.custom-dropdown-2 .dateRangeArrowIcon {
  margin-left: 80px;
  transform: rotate(90deg);
}

@media screen and (max-width: 580px) {
  .theme-repairer .dashboard-page .search .search-filter {
    padding-left: 0 !important;
  }
}
</style>
<style scoped>
.additional-menu-search-filter{
  cursor: pointer;
}
#search-filter-input:focus {
  outline: none !important;
}
.tabs-nav-right-search-dropdown-item:hover{
  background-color: rgb(243, 243, 243);
}
.additional-menu-search-filter .tabs-nav-right-search-dropdown-item:focus {
  background-color: rgb(243, 243, 243);
}
.custom-dropdown {
  border: 1px solid #fff;
  position: absolute;
  top: 44px;
  left: -10px;
  z-index: 999999;
  padding-top: 7px;
  padding-bottom: 7px;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
  border-radius: 3px;
  max-height: 275px;
  text-align: left;
  list-style: none;
  min-width: 12rem;
  background: #fff;
  overflow: auto;
}
.search-filter__box-color-turquoise .custom-dropdown{
  right: -15px;
  left: auto !important;
  min-width: 15rem !important;
}
.custom-dropdown-2 {
  width: 195px;
  left: -195px
}

.responsive-search-filter {
  overflow-y: scroll;
}

ul {
  margin: 0;
  padding: 0;
}

.font-12 {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: normal;
  letter-spacing: 0;
  color: #1C1F39;
}

.search-filter__box {
  display: flex;
  align-items: center;
  position: relative;
  /* background-color: turquoise; */

  box-sizing: border-box;
  padding-right: 5.5px;

  height: 39px;
  border: 1px solid rgba(28, 31, 57, 0.25);
  border-radius: 3px;

  z-index: 10;
}
.search-filter__box-color-turquoise{
  border: 1px solid #03a3ae !important;
  margin-right: 15px;
}
.search-filter__box.search-filter__box-color-turquoise .search-filter__li i{
  color: #03a3ae !important;
}

.search-filter__box-color-turquoise .search-filter__input::placeholder{
  color: #03a3ae !important;
}

.search-filter__box__focused {
  background-color: #fff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  /*box-shadow: 0 0 0 0.2rem rgb(191 222 255 / 25%) !important;*/
}

.theme-repairer .search-filter__box__focused {
  box-shadow: 0 0 0 0.2rem rgb(121 177 187 / 50%) !important
}

.search-filter__scroll-container {
  width: 100%;
}

.search-filter__ul {
  margin: 0;
  padding: 0;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  /* justify-content: center; */
}

.search-filter__li {
  display: inline-block;
  position: relative;
  margin-right: 5.5px;
}

.search-filter__li:hover {
  /* reset */
  border-bottom-color: transparent !important;
}

.search-filter__li > * {
  display: inline-block;
}

.search-filter__li--input {
  flex: 1 1 100%;
  height: 35px;
}

.search-filter__input {
  position: relative;
  /* border-color: 0; */
  box-sizing: border-box;
  width: 100%;
  box-shadow: none !important;
  border-color: transparent !important;
  margin: 0;
  height: 35px;
  padding: 0;
}

.search-filter__input::placeholder {
  color: #1C1F39;
  opacity: 1;
}

.search-filter__input--no-placeholder::placeholder {
  display: none;
}

/* Dropdown */
/* Dropdown Container */
.search-filter__dropdown {

}

.search-options {
  border: 1px solid buttonface;
  padding: 2px 7px;
  margin: 0 7px;
  width: 90% !important;
  border-radius: 3px;
}

/* Dropdown Container */
.dropdown-menu.search-filter__dropdown-menu {
  margin-top: 8px;
  padding-top: 7px;
  padding-bottom: 7px;
  border-color: white;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  left: -10px !important;
  max-height: 275px;
}

/* Dropdown Item */
.dropdown-item.search-filter__dropdown-item {
  display: flex;
  align-items: center;
  padding: 7px 15px;
  border-radius: 2px;
  margin-bottom: 1px;
}

.dropdown-item.search-filter__dropdown-item .bx {
  margin-right: 7px;
  font-size: 18px;
}

.dropdown-item.search-filter__dropdown-item span {
  position: relative;
  top: 2px;
}

/* theme */
.V3 .dropdown-item.search-filter__dropdown-item {
  font-size: 12px;
}

.V3 .dropdown-item.search-filter__dropdown-item:hover,
.V3 .dropdown-item.search-filter__dropdown-item:active,
.V3 .dropdown-item.search-filter__dropdown-item:focus {
  cursor: pointer;
  font-weight: bold;

  background-color: rgba(94, 121, 255, 0.1);
  color: #5E79FF;
}


.token {
  display: flex;
  justify-content: center;
  margin: 3px 0px;
}

.token > * {
  margin-right: 2px;
  padding: 6px 5px;
  background-color: rgba(22, 25, 50, 0.05);
  border-radius: 2px;
}

.token div:last-child {
  margin-right: 0;
}

.token__value,
.token__category,
.token__level {
  white-space: nowrap;
}

.token__level {
  display: flex;
  align-items: center;
}

.token__container {
  display: flex;
}

.token__remove .bx {
  vertical-align: middle;
  font-size: 18px;
}

.token__remove:hover {
  cursor: pointer;
}

.overlay {
  position: fixed;
  display: none;
  width: 100vw;
  height: 100vh;
  /* background: aquamarine; */
  opacity: .3;
  top: 0;
  left: 0;
  z-index: -1;
}

.search-filter {
  position: relative;
}

.debug {
  display: flex;
  flex-flow: column nowrap;
  position: fixed;
  background: rgba(4, 29, 5, 0.52);
  padding: 1em;
  bottom: 35px;
  right: 15px;

  font-size: 11px;
  border-radius: 12px;
}

.debug__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: .5em 0;
}

.debug__text {
  height: 50px;
}

.debug__key {
  padding: .25em .6em;
  background: #19bb2c;
  border-radius: 8px 0 0 8px;
  border: 1px solid #36c539;
  color: rgb(232, 255, 231);
}

.debug__value {
  padding: .25em .6em;
  background: #efefef;
  border-radius: 0 8px 8px 0;
  border: 1px solid #dddddd;
  margin-left: 2px;
}

.debug__title {
  color: #dddddd;
  font-weight: 600;
}
.custom-dropdown {
  width: 290px;
  left: -45px;
  scrollbar-width: thin;
}
.custom-dropdown .tabs-nav-right-search-dropdown-item {
  border: 1px solid #5e77fc;
  padding: 5px 10px;
  border-bottom: 0;
  display: inline-block;
  width: 100%;
}
.custom-dropdown .tabs-nav-right-search-dropdown-item:last-child {
  border-bottom: 1px solid #5e77fc;
}
.custom-dropdown .tabs-nav-right-search-dropdown-item-header {
  color: rgb(28, 31, 57);
  font-weight: 600;
  margin-bottom: 3px;
  font-size: 13px;
}
.custom-dropdown .tabs-nav-right-search-dropdown-item-header.active {
  color: #5e77fc;
}
.custom-dropdown .tabs-nav-right-search-dropdown-item-header .tabs-nav-right-search-dropdown-item-header-weight{
  font-weight: 900;
}
.custom-dropdown .tabs-nav-right-search-dropdown-item-footer {
  font-weight: 600;
  color: rgb(28, 31, 57);
  font-size: 12px;
}
</style>
