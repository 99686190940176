// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'es6-promise/auto';
import Vue from 'vue';

import Axios from "axios";

import {appConfig} from './config.js'

Vue.use(appConfig);

Axios.defaults.baseURL = appConfig.baseAPIURL;
Axios.defaults.withCredentials = true;
Vue.prototype.$http = Axios;
const APIToken = localStorage.getItem('token');

if (APIToken && APIToken !== undefined && APIToken !== "undefined") {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = APIToken;
}


import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const toastOptions = {
    // You can set your default options here
    icon: false
};


Vue.use(Toast, toastOptions);

import ToggleButton from 'vue-js-toggle-button';

Vue.use(ToggleButton);

// import { StripePlugin } from '@vue-stripe/vue-stripe';

// const options = {
//     pk: appConfig.stripePublicKey,
//     // stripeAccount: 'Naevette',
//     // apiVersion: 'some string',
//     locale: 'en',
// };
//
// Vue.use(StripePlugin, options);

import Vuetify from 'vuetify';
Vue.use(Vuetify);

//import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BootstrapVue from 'bootstrap-vue';

Vue.use(BootstrapVue);


import '@/../static/old/bootstrap4/css/bootstrap.min.css';
import '@/../static/old/themify-icons/themify-icons.css';
import '@/../static/old/font-awesome/css/font-awesome.min.css';
import '@/../static/old/animate.css/animate.min.css';
import '@/../static/old/jscrollpane/jquery.jscrollpane.css';
import '@/../static/old/waves/waves.min.css';
import '@/../static/old/chartist/chartist.min.css';
// import '@/../static/old/switchery/dist/switchery.min.css';
import '@/../static/old/morris/morris.css';
import '@/../static/old/jvectormap/jquery-jvectormap-2.0.3.css';
import '@/../static/old/bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import '@/../static/old/bootstrap-daterangepicker/daterangepicker.css';
import '@/../static/old/nprogress/nprogress.css';
import '@/../static/old/toastr/toastr.min.css';
import '@/../static/old/dropify/dist/css/dropify.min.css';
import '@/../static/old/Magnific-Popup/dist/magnific-popup.css';
import '@/../static/old/summernote/summernote.css';
import '@/../static/old/css/core.css';

import '@/assets/css/main.css';

import '@/../static/old/tether/js/tether.min.js';
import '@/../static/old/waves/waves.min.js';
// import '@/../static/old/switchery/dist/switchery.min.js';
import '@/../static/old/Magnific-Popup/dist/jquery.magnific-popup.min.js';
//import '@/../static/old/toastr/toastr.min.js';
//import '@/../static/old/jquery-fullscreen-plugin/jquery.fullscreen-min.js';
//import '@/../static/old/ckeditor/ckeditor.js';
import '@/../static/old/bootstrap4/js/bootstrap.min.js';
import '@/../static/old/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js';
import '@/../static/old/bootstrap-daterangepicker/daterangepicker.js';
//import '@/../static/old/nprogress/nprogress.js';
import '@/../static/old/detectmobilebrowser/detectmobilebrowser.js';

import App from './App';
import store from './store/';
import router from './router';

router.beforeEach((to, from, next) => {
    console.log('from=', from, ' to=', to);
    //if (to.matched.some((record) => {console.log('record.meta.requiresAuth=',record.meta.requiresAuth); return record.meta.requiresAuth})) {
    if (to.path == '/login') {
        console.log("first.next('/login')");
        next();
    } else if (to.path == '/' && store.getters.isLoggedIn) {
        console.log("next('/')");
        next();
    } else if (to.meta.requiresAuth && !store.getters.isLoggedIn) {
        console.log("next('/login')");
        next('/login');
    } else {
        next();
    }
});

import {Vue2Storage} from 'vue2-storage';

Vue.use(Vue2Storage, {
    prefix: 'repairshop_',
    driver: 'local',
    ttl: 1000 * 60 * 60 * 24 * 30,
});

import moment from 'moment';

import ModalWizard from './components/utility/modal-wizard';

Vue.use(ModalWizard);

import Tabs from './components/rs-tabs';

Vue.use(Tabs);

import vuescroll from 'vuescroll';

Vue.use(vuescroll, {
    // reference: https://vuescrolljs.yvescoding.org/guide/configuration.html#bar
    name: 'VueScroll',
    mode: 'native',
    ops: {
        rail: {
            opacity: 1,
            background: '#FFFFFF',
            size: '20px',
            keepShow: true,
            gutterOfSide: '0',
            gutterOfEnds: '0',
        },
        bar: {
            background: '#B7B8C0',
            size: '20px',
        }
    },
});

import ZoomOnHover from "vue-zoom-on-hover";

Vue.use(ZoomOnHover);

Vue.filter('filterDayMonthYear', function (date) {
    if (date) {
        return moment(date, "MM/DD/YYYY").format('DD/MM/YYYY');
    }
    return null;
});

Vue.filter('filterDayMonthYearTime', function (date) {
    if (date) {
        return moment(date, "MM/DD/YYYY, h:mm:ss a").format('DD/MM/YYYY hh:mm:ss A');
    }
    return null;
});

Vue.directive('scroll', {
    inserted: function (el, binding) {
        let f = function (evt) {
            if (binding.value(evt, el)) {
                window.removeEventListener('scroll', f);
            }
        };
        window.addEventListener('scroll', f);
    }
});

Vue.directive('focus', {
    inserted: function (el) {
        el.focus();
    },
    bind: function (el) {
        el.focus();
    },
});

import '../static/app.js';

window._ = require('lodash');
window.NProgress = require('nprogress');
window.toastr = require('@/../static/old/toastr/toastr.min.js');

/* eslint-disable no-new */
new Vue({
    router,
    store,
    vuetify : new Vuetify(),
    render: h => h(App)
}).$mount('#app');


