<template>
    <div class="container-fluid bg-white p-0 login">
        <div class="header">
            <div class="gradient auth-bg col-xl-6 col-lg-6 col-md-6"></div>
            <span class="close" @click="$router.push('/login')">×</span>
            <div class="h-content">
                <div class="row clearfix width-container">
                    <div class="col-md-6 main-logo-container">
                        <div class="main-logo">
                            <router-link to="/login" class="logo-link"></router-link>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-7 col-lg-7 col-md-7"></div>
                        <div class="col-xl-5 col-lg-5 col-md-5">
                            <div class="box box-block mb-3">
                                <form class="form-material" method="POST">
                                    <h2 class="header-text">Forgot your Password?</h2>
                                    <p>Dont’ Worry, just enter the email address your account is registered with and we will send
                                        you link to reset your password.</p>
                                    <div class="row">
                                        <div class="mt-3">
                                            <label></label>
                                            <email @checkedEmail="checkedEmail" style="color: black;" v-model="userEmail"></email>
                                            <p class="text-sm-right">Get Help with your username. <a href="#" @click.prevent="onClickGetHelp">Click Here</a></p>
                                        </div>
                                    </div>

                                    <div class="form-group mt-2 mb-1">
                                        <button type="button"
                                                @click="sendForgotRequest"
                                                :disabled="isDisabledButton || isSending"
                                                class="btn btn-primary">Send Password Reset Link
                                        </button>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Email from "./Email";
    import Axios from "axios";

    export default {
        name: "forgotPassword",
        components: {
            Email
        },
        data() {
            return {
                userEmail: "",
                isDisabledButton: true,
                isSending: false,
            };
        },
        methods: {
            onClickGetHelp() {
                this.$router.push('/auth/forgot/password/support');
            },
            checkedEmail(isCheckEmail) {
                this.isDisabledButton = !isCheckEmail;
            },
            sendForgotRequest() {
                this.isSending = true;
                NProgress.start();
                Axios({url: '/auth/forgot-password', data: {email: this.userEmail}, method: 'POST'})
                    .then(resp => {
                        if (resp && resp.data && resp.data._status === true) {
                            toastr.success('Email with password reset link has been sent successfully');
                            return this.$router.push('/login');
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    }).finally(() => {
                    NProgress.done();
                    this.isSending = false;
                });
            }
        },
    };
</script>

<style scoped>
    .login .gradient {
        position: absolute;
        height: 100% !important;
    }

    .login .gradient.auth-bg {
        background: #1f233d !important;
    }

    .logo-text {
        color: white;
        margin: 20% 0 0 20%;
    }

    .close {
        color: #5e79ff;
        font-size: 50px;
        padding-right: 20px;
        padding-top: 20px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 999;
        opacity: 0.5;
    }

    .close:hover {
        opacity: 1;
    }

    .header {
        color: black;
    }

    .header-text {
        font-size: 32px;
    }

    .login .header .box {
        background: none;
    }

    .box-block {
        max-width: 450px;
    }

    .box-block h4, h6 {
        text-align: center;
    }

    h6 {
        margin-top: 30px;
    }

    input::placeholder {
        color: #ADADAD !important;
    }

    .V3 .login input {
        color: black;
    }

    .form-padd {
        padding-bottom: 19px;
    }

    .checkbox-block {
        margin: 50px 0 50px 25px;
    }

    .btn-block {
        background: #4472C4;
        color: white;
        border-radius: 3px;
    }

    .header .gradient {
        opacity: 1;
    }

    .form-group-label {
        padding-left: 20px;
    }

    .V3 label.radio {
        width: 100%;
    }

    .header {
        min-height: 100vh;
    }

    .header .btn {
        margin: 0 auto;
    }

    .form-material {
        text-align: center;
    }

    .form-material h2 {
        padding-bottom: 50px;
    }

    .form-material p {
        font-weight: 600;
        padding-bottom: 50px;
        font-size: 16px;
    }

    .form-control {
        color: black;
    }

    input::placeholder {
        color: #ADADAD !important;
    }

    .V3 .login input {
        padding-left: 15px;
        color: black !important;
    }

    .text-sm-right {
        padding-top: 10px;
    }

    .form-group-input-title {
        width: 100%;
        text-align: left;
        padding-left: 15px;
        font-size: 16px;
    }

    .text-sm-right {
        padding-top: 35px;
    }

    .form-group {
        margin-top: 80px;
    }

    .btn-primary {
        width: 100%;
        border-radius: 3px;
        font-size: 16px !important;
        background: linear-gradient(135deg, rgb(114, 103, 231) 0%, rgb(98, 89, 239) 49.12%, rgb(78, 69, 238) 100%);
    }

    .main-logo-container {
        padding: 32px 72px 0 72px;
    }

    .width-container {
        margin: 0 auto;
        width: 1440px;
    }

    .h-content {
        display: flex;
        justify-content: center;
    }

    .main-logo {
        display: flex;
        justify-content: center;
        height: 39px !important;
        width: 185px !important;
        background-repeat: round;
    }

    .logo-link {
        display: block;
        height: 39px;
        width: 185px;
    }

    @media screen and (max-width: 768px) {
        .main-logo {
            background-image: url('../../assets/logos/logo3x.png');
            background-size: 100% auto;
        }
    }

    .form-group .form-control {
        font-family: "Nunito Sans";
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
        padding: 9px 13px;
        border: 1px solid rgba(27, 30, 56, 0.2) !important;
        border-radius: 4px !important;
        height: 43px !important;
        color: #1C1F39;
    }

    .form-group .form-control::placeholder {
        font-family: "Nunito Sans";
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
        color: #ADADAD !important;
        opacity: 1;
        padding-left: 2px;
    }

    .btn.disabled, .btn:disabled {
        cursor: not-allowed;
        opacity: .65;
        background: #adaeb2;
        border-color: #adaeb2;
    }

    .V3 .btn-primary.disabled:hover, .V3 .btn-primary:disabled:hover {
        background: #98999c;
        border-color: #98999c;
    }
    @media screen and (max-width: 767px) {
        .box-block {
            margin-top: 70px !important;
        }
        .main-logo-container .main-logo {
            margin: 0 auto;
        }
    }

    @media screen and (max-width: 350px) {
        .box-block {
            margin-top: 30px !important;
        }
        .width-container {
            margin-top: 50px !important;
        }
    }
    .V3 .btn-primary.disabled:hover, .V3 .btn-primary:disabled:hover {
        background: #98999c;
        border-color: #98999c;
    }
</style>
