<script>
    import Axios from 'axios';
    import AmazonAds from './utility/amazon-ads';
    import GoogleLocation from './utility/google-location';
    import {mapActions, mapGetters} from "vuex";
    import Multiselect from "vue-multiselect";
    import _ from "lodash";
    import Email from './utility/email-validator';

    export default {
    name: 'customers-new',
    data: function () {
      return {
		  isCheckedEmail: true,
		  optionsForState: [
			  {value: 'Select your state', key: '',},
			  {value: 'Queensland', key: 'QLD',},
			  {value: 'Northern Territory', key: 'NT',},
			  {value: 'New South Wales', key: 'NSW',},
			  {value: 'Victoria', key: 'VIC',},
			  {value: 'Tasmania', key: 'TAS',},
			  {value: 'South Australia', key: 'SA',},
			  {value: 'Western Australia', key: 'WA',},
		  ],
		  optionsForCountry: [
			  {value: 'Select your country', key: '',},
			  {value: 'Australia', key: 'AU',},
			  {value: 'New Zealand', key: 'NZ',},
		  ],
		  customer: {
			  name: '',
			  address: '',
			  level_unit_lot: '',
			  street_name: '',
			  suburb: '',
			  state: '',
			  postcode: '',
			  country: '',
			  mobile: '',
			  phone: '',
			  fax: '',
			  email: '',
			  abn: '',
			  type: 'I',
			  customer_name: '',
		  }
	  }
	},
    computed: {
      ...mapGetters({
              'isCustomerUser': 'isCustomerUser',
              getterBusinessName : 'getBusinessName',
            }),
        isOwnerTypeBusinessFleet() {
            return !!(this.customer.type == 'F');
        },
        isOwnerTypeBusiness() {
            return !!(this.customer.type == 'B');
        },
        isOwnerTypeIndividual() {
            return !!(this.customer.type == 'I');
        },
      computedState: {
        get: function () {
          let vm = this;
          try {
          let item = _.find(this.optionsForState, function (itm) {
            return itm.key == vm.customer.state;
          });
          return item;
          } catch (e) {
          return {};
          }
        },
        set: function (item) {
          if (item) {
          this.customer.state = item.key;
          }
        },
      },
      computedCountry: {
        get: function () {
          let vm = this;
          try {
          let item = _.find(this.optionsForCountry, function (itm) {
            return itm.key == vm.customer.country;
          });
          return item;
          } catch (e) {
          return {};
          }
        },
        set: function (item) {
          if (item) {
          this.customer.country = item.key;
          }
        },
      },
    },
    methods: {
      ...mapActions({
        actionLoadCustomers: 'customer/loadCustomers',
      }),
      validCustomerEmail: function(value){
        this.customer.email = value
        this.$v.customer.email.$touch()
      },
      saveData: function () {
        if (!this.isCheckedEmail) {
          toastr.error('Your changes could not be saved, please check the form fields again');
          return;
        }
        NProgress.start();
        Axios({
          method: 'post',
          responseType: 'json',
          url: '/fe/customer',
          validateStatus: function (status) {
            return status < 500
          },
          data: JSON.stringify(this.customer)
        })
        // Axios.post('/fe/job', JSON.stringify({customer: this.job.customer, vehicle: this.job.vehicle, booking_date: this.job.booking_date, completion_date: this.job.completion_date}))
        .then(response => {
          if (response.status == 200) {
            toastr.success('Customer details saved');
            this.$router.push('/customers');
            this.actionLoadCustomers();
          } else {
            toastr.error(response.data.msg)
          }
          NProgress.done()
        })
        .catch(error => {
          NProgress.done()
          toastr.error(error)
        })
      },
      addressChanged: function (addr) {
          this.customer.address = addr["address"];
          this.customer.level_unit_lot = addr['levelUnitLot'];
          this.customer.street_name = addr["street_name"];
          this.customer.suburb = addr["suburb"];
          this.customer.state = addr["state"];
          this.customer.postcode = addr["postcode"];
          this.customer.country = addr["country"];
      },
    },
    components: {
      AmazonAds,
      GoogleLocation,
      Multiselect,
      Email,
    }
  }
</script>

<template>
<div>
	<div class="page-header">
		<h4>Add Customer</h4>
		<ol class="breadcrumbs">
			<li class="breadcrumb-item home">
				<router-link to="/">Home</router-link>
			</li>
			<li class="breadcrumb-item active">Add Customer</li>
		</ol>
    <h4 class="header-display-name">{{ getterBusinessName }}</h4>
	</div>
	<div class="box box-block bg-white">
		<!--<p class="font-90 text-muted mb-1">Add Customer details</p>-->
		<div class="card main-card">
			<div class="card-header bg-white save-button-header justify-content-end" style="background-color: #fff !important; padding: 0 10px;">
				<div class="rs-btn-group float-sm-right">
					<button @click="saveData" type="button" class="btn btn-primary w-min-sm waves-effect waves-light">Save</button>
				</div>
			</div>
			<div class="card-block main-card-block">
				<div class="row">
				<div class="col-md-6">
				<div class="card">
					<div class="card-header bg-navyblue">
						<i class="ti-layout-list-thumb align-middle"></i><strong>&nbsp;&nbsp;Customer Details</strong>
					</div>
					<div class="card-block bg-white">
            <div class="form-group row">
              <label for="owner_type" class="col-lg-3 col-form-label">Owner Type</label>
              <div class="col-lg-9" id="owner_type">
                <label class="form-check-inline radio" style="margin-top: 5px">

                  <input v-model="customer.type"
                         class="form-check-input" type="radio" name="customer_type"
                         id="individual_customer_type" value="I">
                  <span class="icon"><i class='bx bx-check'></i></span><span
                  class="text">Individual</span>

                </label>
                <label class="form-check-inline radio" style="margin-top: 5px">
                  <input v-model="customer.type"
                         class="form-check-input" type="radio" name="customer_type"
                         id="business_customer_type" value="B">
                  <span class="icon"><i class='bx bx-check'></i></span><span
                  class="text">Business</span>
                </label>

                <label class="form-check-inline radio" style="margin-top: 5px">
                  <input v-model="customer.type"
                         class="form-check-input" type="radio" name="customer_type"
                         id="business_fleet_customer_type" value="F">
                  <span class="icon"><i class='bx bx-check'></i></span><span style="min-width: 121px"
                                                                             class="text">Business Fleet</span>
                </label>
              </div>
            </div>
						<div class="form-group row">
							<label for="customer_name" class="col-lg-3 col-form-label">Name</label>
							<div class="col-lg-9">
								<input type="text" v-model="customer.customer_name" class="form-control" id="customer_name" placeholder="Customer Name">
							</div>
						</div>

            <div class="form-group row" v-if="isOwnerTypeBusiness || isOwnerTypeBusinessFleet">
              <label for="customer_abn" class="col-lg-3 col-form-label">Business ABN</label>
              <div class="col-lg-9">
                <input type="text" v-model="customer.abn" class="form-control" id="customer_abn" placeholder="Business ABN">
              </div>
            </div>
            <div class="form-group row" v-if="isOwnerTypeBusiness || isOwnerTypeBusinessFleet">
              <label for="customer_business_contact_nbr" class="col-lg-3 col-form-label">Business Contact Nbr</label>
              <div class="col-lg-9">
                <input type="text" v-model="customer.mobile" class="form-control" id="customer_business_contact_nbr" placeholder="Business Contact Nbr">
              </div>
            </div>

            <div class="form-group row" v-if="isOwnerTypeBusiness || isOwnerTypeBusinessFleet">
              <label for="customer_fax" class="col-lg-3 col-form-label">Business Fax</label>
              <div class="col-lg-9">
                <input type="text" v-model="customer.fax" class="form-control" id="customer_fax" placeholder="Business Fax">
              </div>
            </div>

            <div class="form-group row" v-if="isOwnerTypeBusiness || isOwnerTypeBusinessFleet">
              <label for="customer_email" class="col-lg-3 col-form-label">Business Email</label>
              <div class="col-lg-9">
                <input id="customer_email" type="text" v-model="customer.email" class="form-control" placeholder="Business Email">
              </div>
            </div>

            <div class="form-group row" v-if="isOwnerTypeIndividual">
              <label for="mobile" class="col-lg-3 col-form-label">Contact Nbr 1</label>
              <div class="col-lg-9">
                <input type="text" v-model="customer.mobile" class="form-control" id="mobile" placeholder="Mobile">
              </div>
            </div>
            <div class="form-group row" v-if="isOwnerTypeIndividual">
              <label for="fax" class="col-lg-3 col-form-label">Fax</label>
              <div class="col-lg-9">
                <input type="text" v-model="customer.fax" class="form-control" id="fax" placeholder="Fax">
              </div>
            </div>

            <div class="form-group row" v-if="isOwnerTypeIndividual">
              <p style="color: rgba(0, 0, 0, 0.5); padding-left: 15px; font-weight: bold">Home address</p>
            </div>

            <div class="form-group row" v-else>
              <p style="color: rgba(0, 0, 0, 0.5); padding-left: 15px; font-weight: bold">Business address</p>
            </div>

            <div class="form-group row" v-if="isOwnerTypeIndividual">
                <label for="email" class="col-lg-3 col-form-label">Email</label>
                <email
                        :email="customer.email"
                        @onChange="(value) => customer.email = value"
                        @checked="(value) => isCheckedEmail = value"
                        class="col-lg-9"
                ></email>
            </div>
            <div class="form-group row">
              <label class="col-lg-3 col-form-label">Search Address</label>
              <div class="col-lg-9">
								<google-location type="text" v-model="customer.address" id="address" placeholder="Search for an Address"
                                   @onPlaceChange="addressChanged"></google-location>
                </div>
              </div>
            </div>
						<div class="form-group row">
							<label for="level_unit_lot" class="col-lg-3 col-form-label">Level/Unit/Lot Nbr</label>
							<div class="col-lg-9">
								<input type="text" v-model="customer.level_unit_lot" class="form-control" id="level_unit_lot" placeholder="Lot, Unit, or Level number">
							</div>
						</div>
						<div class="form-group row">
							<label for="street_name" class="col-lg-3 col-form-label">Street Name</label>
							<div class="col-lg-9">
								<input type="text" v-model="customer.street_name" class="form-control" id="street_name" placeholder="Street Name">
							</div>
						</div>
						<div class="form-group row">
							<label for="suburb" class="col-lg-3 col-form-label">Suburb</label>
							<div class="col-lg-9">
								<input type="text" v-model="customer.suburb" class="form-control" id="suburb" placeholder="Suburb">
							</div>
						</div>
						<div class="form-group row">
							<label for="state" class="col-lg-3 col-form-label">State</label>
							<div class="col-lg-9">
								<multiselect
									v-model="computedState"
									:options="optionsForState"
									:showLabels="false"
									:option-height="29"
									:max-height="203"
									:close-on-select="true"
									track-by="key"
									label="value"
								></multiselect>
							</div>
						</div>
						<div class="form-group row">
							<label for="postcode" class="col-lg-3 col-form-label">Postcode</label>
							<div class="col-lg-9">
								<input type="text" v-model="customer.postcode" class="form-control" id="postcode" placeholder="Postcode">
							</div>
						</div>
						<div class="form-group row">
							<label for="country" class="col-lg-3 col-form-label">Country</label>
							<div class="col-lg-9">
								<multiselect
									v-model="computedCountry"
									:options="optionsForCountry"
									:showLabels="false"
									:option-height="29"
									:max-height="203"
									:close-on-select="true"
									track-by="key"
									label="value"
								></multiselect>
							</div>
						</div>

					</div>
				</div>
				</div>
				<div class="col-md-6">
					<amazon-ads></amazon-ads>
				</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>
<style>
.search-location-block .form-control {
  padding: 0;
}
</style>
