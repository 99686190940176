<script>

    import ActivityReplyMessage from './activity-reply-message';
    import {mapGetters} from "vuex";

    export default {
        name: "activity-list-email",
        props: ['email', 'viewed', 'activity', 'index', 'cardId', 'estimateId'],
        data: function () {
            return {
                dataEmail: Object.assign({}, this.email),
                a: this.activity,
                isMinimized: !!this.viewed,
                dpOptions: {
                    autoclose: true,
                    todayHighlight: true,
                    format: 'dd/mm/yyyy'
                },
            };
        },
        methods: {
            deleteActivity: function () {
              this.$emit('deleteActivity', this.a.activity_id, true);
              this.$refs.deleteActivityModal.hide();
            },
            openMenuEmailAttachments: function (event, item) {
                this.$emit('openMenuForEmailAttachments', event, item);
            },
            markAsReadActivity: function (item) {
                this.$store.dispatch('updateActivityViewedStatus', {activity_id: item.activity_id, card_id: this.cardId, viewed: 1});
                item.viewed = 1;
                toastr.success('Email Marked as Read successfully');
            },
            markAsUnReadActivity: function (item) {
                this.$store.dispatch('updateActivityViewedStatus', {activity_id: item.activity_id, card_id: this.cardId, viewed: 0});
                item.viewed = 0;
                toastr.success('Email Marked as Unread successfully');
            },
            replyActivity(index) {
                const refIndex = 'activityReplyMessageRef' + index;
                if (this.$refs[refIndex]) {
                    this.$refs[refIndex].showReplyMessage(false);
                }
            },
            replyToAllActivity(index) {
                const refIndex = 'activityReplyMessageRef' + index;
                if (this.$refs[refIndex]) {
                    this.$refs[refIndex].showReplyMessage(true);
                }
            },
            loadActivity: function () {
                this.$emit('loadActivity');
            },
            markAsUnRead: function (a) {
                this.$emit('unread', a);
            },
            markAsRead: function (a) {
                this.$emit('read', a);
            },
            destroyMe: function () {
                Vue.$destroy(this);
            }
        },
        computed: {
          ...mapGetters({
            isShopManagerUser: 'isShopManagerUser',
            isProductionManagerUser: 'isProductionManagerUser',
            isAdministrator: 'isAdministrator',
            isStatusActive: 'isStatusActive',
          }),
        },
        watch: {},
        components: {
            ActivityReplyMessage,
        },
        mounted: function () {
          let params = this.$route.params;
          let vm = this;
          this.$nextTick(() => {
            if (params && params.action && params.action == 'goToEmail' && this.a.email.id == params.emailId) {
              vm.isMinimized = false;
              vm.$nextTick(() => {
                setTimeout(() => {
                  vm.$el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: "start",
                  });
                }, 100)
              });
            }
          });
        }
    };
</script>
<template>
    <div class="tl-content box box-block display-block" style="background-color: #FAFAFA;">
        <span class="arrow left" style="border-color: #FAFAFA;"></span>
        <div>
            <div v-if="a.activity_type === 'ER' && isStatusActive" class="navbar-nav nav dropdown dropdown-options show float-sm-right text-right additional-action-menu-style mr-0 additional-email-action-menu-mobile">
                <span class="btn ml-0-25 waves-effect waves-light additional-action-menu-style"
                        style="margin-top: -10px; padding-left: 10px !important; right: 7px;"
                        :id="'dropdownReplyActivity' + a.activity_id"
                        data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="true">Actions
                        <span class="dropdown-arrow  pull-right" style="box-sizing: border-box; width: 30px; border-left: 1px solid rgba(27, 30, 56, 0.25);"><i class="fa fa-chevron-down pull-right-action"></i></span>
                 </span>
                <div class="dropdown-menu dropdown-menu-right animated fadeInUp drop-down-menu-actions"  style="right: -6px">
                    <div class="dropdownLinks-actions-links" :aria-labelledby="'dropdownReplyActivity' + a.activity_id">
                        <a @click="replyActivity(index)" class="dropdown-item text-primary clickable"><i class="fa fa-reply activtyPopupIcon"
                                                                                                             aria-hidden="true"></i> Reply</a>
                        <a @click="replyToAllActivity(index)" class="dropdown-item text-primary clickable"><i class="fa fa-reply-all activtyPopupIcon"
                                                                                                                  aria-hidden="true"></i> Reply to All</a>
                        <a v-if="a.viewed==1" @click="markAsUnReadActivity(a)" class="dropdown-item text-primary clickable"><i
                                class="fa fa-eye-slash activtyPopupIcon" aria-hidden="true"></i> Mark As Unread</a>
                        <a v-else @click="markAsReadActivity(a)" class="dropdown-item text-primary clickable"><i
                                class="fa fa-eye activtyPopupIcon" aria-hidden="true"></i> Mark As Read</a>
                        <a v-if="isProductionManagerUser || isShopManagerUser || isAdministrator" @click="$refs.deleteActivityModal.show()" class="dropdown-item text-primary clickable dropdown-action-item">Delete Entry</a>
                    </div>
                </div>
            </div>
            <activity-reply-message :activity="a" :ref="'activityReplyMessageRef'+index" @loadActivity="loadActivity"></activity-reply-message>

            <p v-if="a.activity_type === 'ER' && a.email.from"><strong>From: </strong>{{a.email.from}}</p>
            <p v-if="a.activity_type === 'UR' && a.email.to"><strong>To: </strong>{{a.email.to}}</p>
            <p v-if="a.entry_date && a.entry_time"><strong>Sent: </strong>{{a.entry_date.replaceAll('-', '/')}} - {{a.entry_time}}</p>
            <p v-if="a.email.to && a.activity_type === 'ER'"><strong>To: </strong>{{a.email.to}}</p>
            <p v-if="a.email.from && a.activity_type === 'UR'"><strong>From: </strong>{{a.email.from}}</p>
            <p v-if="a.email.subject"><strong>Subject: </strong>{{a.email.subject}}</p>
            <p v-if="a.email.files.length">
                <strong>
                    Attachments:
                    <a v-for="att in a.email.files"
                       :href="att.url"
                       target="_blank"
                       class="btn btn-primarybtn btn-outline-primary btn-sm tag-pill mr-0-75 noselect"
                       style="position:relative;"
                       @contextmenu.prevent="openMenuEmailAttachments($event, att)"
                       v-if="!!!att.isDeleted"
                    >
                        <div style="margin: 5px 23px 5px 5px;" v-if="att.isCopyToCard || att.isCopyToEstimate">{{att.name}}</div>
                        <i class="icon ion-ios-checkmark-circle" style="font-size:20px; color:#2DCA73; position:absolute; top:3px; right:7px;"
                           v-if="att.isCopyToCard || att.isCopyToEstimate"></i>
                        <div style="margin: 5px" v-else>{{att.name}}</div>
                    </a>
                </strong>
            </p>
            <div class="card px-1 py-1" v-html="a.email.html_text"></div>
            <div class="tl-date text-muted mt-1">{{a.entry_date.replaceAll('-', '/')}} - {{a.entry_time}}</div>
        </div>
        <modal-wizard ref="deleteActivityModal" cancelButtonText="Cancel" finishButtonText="Ok" @on-complete="deleteActivity" class="delete-activity-modal">
          <modal-wizard-tab title="Delete Activity" description="" title-icon-class="ti-align-right">
            <template>
              <div class="pt-2 pb-2">Do you want to Delete this Activity list entry?</div>
            </template>
          </modal-wizard-tab>
        </modal-wizard>
    </div>
</template>

<style scoped>
    .dropdownLinks-actions-links {
        width: 115px;
    }

    .drop-down-menu-actions {
        width: 115px !important;
        border-radius: 3px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 4px 0 rgb(27 30 56 / 15%);
        min-width: 115px !important;
        margin: -13px 0 0 !important;
        text-align: left;
        list-style: none;
        background-clip: padding-box;
        z-index: 110;
    }

    .dropdown-action-item {
        text-align: center !important;
        min-height: 20px !important;
    }

    .pull-right-action {
        padding-right: 3px !important;
        margin-left: 10px;
    }

    .additional-email-action-menu-mobile {
        position: absolute;
        right: 16px;
    }
</style>
