<script>
    import ActivityListDefault from "./activity-list-default";
    import ActivityListEIO from "./activity-list-eio";
    import ActivityListEmail from "./activity-list-email";
    import ActivityListTask from './activity-list-task';
    import ActivityListInvoice from './activity-list-invoice';
    import {VueContext} from 'vue-context';
    import Axios from 'axios';
    import SearchFilter from '../../search-filter/search-filter';
    import ActivityListSms from "@/components/utility/activity-list/activity-list-sms.vue";

    export default {
        name: "activity-list",
        props: ['activity', 'cardId', 'estimateId', 'isOpenNewTaskForm', 'newTask', 'filteredListAssignedTo', 'estimateId', 'isCanReplyTo'],
        data: function () {
            return {
              replyA: null,
                activities: this.activity,
              templates: [],
                activitySortTypes: {
                    email: 'Email',
                    external: 'External',
                    assignment: 'Assignment',
                    status_change: 'Status/Change',
                },
                filter: '',
            };
        },
        methods: {
          replyToMessage(a) {
            if (!this.isCanReplyTo) {
              return
            }
            this.replyA = a
          },
          loadTemplates() {
            Axios.get('/fe/sms-templates').then((r) => {
              if (r.data._status && r.data.templates) {
                this.templates = _.filter(r.data.templates, (t) => { return t.type == 'reply'})
              }
            })
          },
            deleteActivity: function (id, isEmail = false) {
                this.$emit('deleteActivity', id, isEmail);
            },
            saveTask: function ({task, activityId}) {
                this.$emit('saveTask', task);

                this.$nextTick(() => {
                    this.openActivityTaskDetails(activityId);
                });
            },
            openActivityTaskDetails(activityId) {
                if (!activityId) return;
                const ref = this.$refs[`activity-${activityId}`][0];
                ref.open();
                this.$nextTick(() => {
                    ref.$el.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest'
                    });
                });
            },
            closeTask: function (data) {
                console.log(data);
                this.$emit('closeTask');
            },
            deleteTask: function (task) {
                task.delete = true;
                this.$emit('deleteTask', task);
            },
            saveNewTask: function (data) {
                this.$emit('saveNewTask', data);
            },
            closeNewTask: function () {
                this.$emit('closeNewTask');
            },
            openMenuForEmailAttachments: function (event, item) {
                this.$refs.rightClickMenuForEmailAttachments.open(event, item);
            },
            menuForEmailAttachments: function (event, data) {
                var type = data.type;
                var item = data.item;
                if (type == 30) {
                    Axios.post(`/fe/messages/file/remove`, {file_id: item.id})
                        .then(response => {
                            if (response.status === 200) {
                                toastr.success(response.data.msg);
                                item.isDeleted = true;
                            }
                            console.log(response);
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
                if (type == 11 || type == 10) {
                    var data = {
                        type: 'card',
                        card_id: this.cardId,
                        file_id: item.id,
                    };
                } else if (type == 21 || type == 20) {
                    var data = {
                        type: 'estimate',
                        card_id: this.cardId,
                        file_id: item.id,
                    };
                } else {
                    return;
                }

                this.$store.dispatch('copyFileFromEmail', data).then((res) => {
                    NProgress.done();
                    if (res.status === 200) {
                        toastr.success(res.data.msg);
                        if (res.data.update && res.data.update.files) {
                            this.$emit('loadFiles');
                        }
                        if (res.data.update && res.data.update.images) {
                            this.$emit('loadImages');
                        }
                        if (type == 11 || type == 10) {
                            item.isCopyToCard = true;
                        } else if (type == 21 || type == 20) {
                            item.isCopyToEstimate = true;
                        }
                    } else if (res.status === 400) {
                        toastr.error(res.data.msg);
                    } else {
                        toastr.warning(res.data.msg);
                    }
                }).catch((err) => {
                    NProgress.done();
                    toastr.error(err);
                });

            },
            markAsReadActivity: function (item) {
                let r = this.$store.dispatch('updateActivityViewedStatus', {activity_id: item.activity_id, card_id: this.cardId, viewed: 1});
                r.then((response) => {
                    if (response && response.data && response.data.msg){
                        toastr.success(response.data.msg)
                    }
                });
                item.viewed = 1;
            },
            markAsUnReadActivity: function (item) {
                let r = this.$store.dispatch('updateActivityViewedStatus', {activity_id: item.activity_id, card_id: this.cardId, viewed: 0});
                r.then((response) => {
                   if (response && response.data && response.data.msg){
                       toastr.success(response.data.msg)
                   }
                });
                item.viewed = 0;
            },
            loadActivity: function () {
                this.$emit('loadActivity');
            },
            destroyMe: function () {
                Vue.$destroy(this);
            },
            filterActivity(activityText, query) {
                let text = '';
                if (!query) {
                    text = noteText;
                } else {
                    console.log('activityText', activityText);
                    if (activityText) {
                        text = activityText.replaceAll(new RegExp(query, 'iug'), `<span style="background-color: yellow">${query}</span>`);
                    }
                }

                return text;
            },
        },
        computed: {
            computedActivityWithFilter() {
                //this.filter = "a";
                if (!this.filter) {
                    return this.activity;
                }
                let data = [];
                this.activity.forEach(n => {
                    if (n.action_code == 'TC') {
                        let task = n.task;
                        let f = this.filterActivity(task.notes, this.filter);
                        if (task.notes != null && f != task.notes) {
                            data.push(n);
                        } else {
                            let f = this.filterActivity(task.name, this.filter);
                            if (task.name != null && f != task.name) {
                                data.push(n);
                            } else {
                                let comments = task.comments;
                                let status_comment = false;
                                comments.forEach(c => {
                                    let cc = this.filterActivity(c.text, this.filter);
                                    if (c.text != null && cc != c.text) {
                                        status_comment = true;
                                    }
                                });
                                if (status_comment) {
                                    data.push(n);
                                }
                            }
                        }
                    } else {
                        let f = this.filterActivity(n.action_description, this.filter);
                        if (n.action_description != null && f != n.action_description) {
                            data.push(n);
                        } else {
                            f = this.filterActivity(n.entry_date, this.filter);
                            if (n.entry_date != null && f != n.entry_date) {
                                data.push(n);
                            } else {
                                f = this.filterActivity(n.entry_time, this.filter);
                                if (n.entry_time != null && f != n.entry_time) {
                                    data.push(n);
                                }
                            }
                        }
                    }
                });
                return data;
            },
            isTechnicalUser: function () {
                let userInfo = this.$store.state.userInfo;
                if (userInfo && userInfo.group_id == 4) {
                    return true;
                }
                return false;
            },
        },
        watch: {
            activity: function (newVal, oldVal) {
                this.activities = [];
                var self = this;
                setTimeout(() => {
                    self.activities = newVal;
                }, 50);
            }
        },
        components: {
          ActivityListSms,
            ActivityListDefault,
          ActivityListEIO,
            ActivityListEmail,
            ActivityListTask,
            ActivityListInvoice,
            VueContext,
            SearchFilter,
        },
        mounted: function () {
          this.loadTemplates()
        }
    };
</script>
<template>
    <div>
        <div class="row">
            <div class="offset-sm-4 col-sm-8 col-md-6 col-12 additional-left-position">
                <search-filter ref="search-filter" placeholder="Search or Filter results" v-model="filter"/>
            </div>
        </div>

        <div class="timeline overflow-x-auto">
            <div v-if="isOpenNewTaskForm"
                 :data-activity-id="'newtask-1'"
                 class="tl-item tl-activity-item"
            >
                <div class="tl-wrap b-a-primary">
                    <i class="fa fa-user left" aria-hidden="true"></i>
                    <activity-list-task
                            :task="newTask"
                            :assigned-to-list="filteredListAssignedTo"
                            :openNewTaskForm="isOpenNewTaskForm"
                            @save="saveNewTask"
                            @close="closeNewTask"
                    >
                    </activity-list-task>
                </div>
            </div>
            <div v-for="(a, index) in computedActivityWithFilter"
                 :class="[a.viewed === '1' ? 'tl-item tl-activity-item viewed' : 'tl-item tl-activity-item']"
                 :data-activity-id="a.activity_id"
                 :key="'activity-'+a.activity_id"
            >

              <div class="tl-item tl-activity-item" v-if="replyA && replyA.activity_id == a.activity_id">
                <div class="tl-wrap b-a-primary">
                  <i class="fa fa-envelope-o left" aria-hidden="true"></i>
                  <activity-list-sms :activity="replyA" @close="replyA = null; $emit('loadActivity')" @delete="replyA = null" :templates="templates">
                  </activity-list-sms>
                </div>
              </div>

                <div class="tl-wrap b-a-primary">
                    <i v-if="
                    (a.action_code == 'IPP'
                    || a.action_code == 'ICC'
                    || a.action_code == 'IEC'
                    || a.action_code == 'IFP'
                    || a.action_code == 'IEP'
                    || a.action_code == 'ICP'
                    || a.action_code == 'ExcessInvoiceFullPayment'
                    || a.action_code == 'ExcessInvoicePayment'
                    || a.action_code == 'CompleteInvoiceFullPayment'
                    || a.action_code == 'CompleteInvoicePayment')"
                       class="fa fa-usd left" aria-hidden="true"></i>
                    <i v-else-if="a.activity_type === 'SMR' || a.activity_type === 'SMS'" class="fa fa-envelope-o left" aria-hidden="true"></i>
                    <i v-else-if="a.sort_type === activitySortTypes.email" class="fa fa-envelope-o left" aria-hidden="true"></i>
                    <i v-else-if="a.sort_type === activitySortTypes.external" class="fa fa-external-link left" aria-hidden="true"></i>
                    <i v-else-if="a.sort_type === activitySortTypes.assignment" class="fa fa-user left" aria-hidden="true"></i>
                    <i v-else-if="a.sort_type === activitySortTypes.status_change" class="fa fa-pencil-square-o left" aria-hidden="true"></i>
                    <i class="left" v-else></i>
                    <activity-list-email
                            :ref="`activity-${a.activity_id}`"
                            :index="index"
                            :activity="a"
                            :email="a.email"
                            @loadActivity="loadActivity"
                            @deleteActivity="deleteActivity"
                            :cardId="cardId"
                            :estimateId="estimateId"
                            v-if="a.activity_type === 'ER' || a.activity_type === 'UR'"
                            @openMenuForEmailAttachments="openMenuForEmailAttachments">
                    </activity-list-email>
                    <activity-list-task
                            :ref="`activity-${a.activity_id}`"
                            :task="a.task"
                            :assigned-to-list="filteredListAssignedTo"
                            :activity="a"
                            :viewed="a.viewed"
                            @save="saveTask"
                            @close="closeTask"
                            @unread="markAsUnReadActivity"
                            @read="markAsReadActivity"
                            @delete="deleteTask"
                            v-else-if="a.subject_type=='T'"
                    >
                    </activity-list-task>
                    <activity-list-invoice
                            :ref="`activity-${a.activity_id}`"
                            :task="a.task"
                            :assigned-to-list="filteredListAssignedTo"
                            :activity="a"
                            :viewed="a.viewed"
                            @save="saveTask"
                            @close="closeTask"
                            @unread="markAsUnReadActivity"
                            @read="markAsReadActivity"
                            @deleteActivity="deleteActivity"
                            v-else-if="
                            a.subject_type=='C' &&
                            (a.action_code == 'IPP'
                            || a.action_code == 'ICC'
                            || a.action_code == 'IEC'
                            || a.action_code == 'IFP'
                            || a.action_code == 'IEP'
                            || a.action_code == 'ICP'
                            || a.action_code == 'ExcessInvoiceFullPayment'
                            || a.action_code == 'ExcessInvoicePayment'
                            || a.action_code == 'CompleteInvoiceFullPayment'
                            || a.action_code == 'CompleteInvoicePayment')"
                    >
                    </activity-list-invoice>
                  <activity-list-e-i-o v-else-if="
                  (a.action_code == 'EstimageOnlineNewRfq'
                            || a.action_code == 'EstimageOnlineRepairsAuthorized'
                            || a.action_code == 'EstimageOnlineRepairsCancelled'
                            || a.action_code == 'EstimageOnlineRegoUpdated'
                            || a.action_code == 'EstimageOnlineExcessUpdate'
                            || a.action_code == 'EstimageOnlineInvoiceExported'
                            || a.action_code == 'EstimageOnlineQuoteSent')"
                                       :index="index"
                                       :viewed="a.viewed"
                                       @unread="markAsUnReadActivity"
                                       @read="markAsReadActivity"
                                       @deleteActivity="deleteActivity"
                                       :activity="a"
                  >

                  </activity-list-e-i-o>
                  <activity-list-default
                      :ref="`activity-${a.activity_id}`"
                      :index="index"
                      :viewed="a.viewed"
                      @replyToMsg="replyToMessage(a)"
                      @unread="markAsUnReadActivity"
                      @read="markAsReadActivity"
                      @deleteActivity="deleteActivity"
                      :activity="a"
                      v-else
                  >
                  </activity-list-default>
                </div>
            </div>
            <vue-context ref="rightClickMenuForEmailAttachments">
                <ul slot-scope="item">
                    <li @click="menuForEmailAttachments($event.target, {type:11, item:item.data})" v-if="item && item.data && item.data.type=='image'">Add to Cards Images</li>
                    <li @click="menuForEmailAttachments($event.target, {type:10, item:item.data})" v-if="item && item.data && item.data.type!='image'">Add to Cards Files</li>
                    <li @click="menuForEmailAttachments($event.target, {type:20, item:item.data})" v-if="item && item.data && item.data.type!='image'">Add to Estimates Files</li>
                    <li @click="menuForEmailAttachments($event.target, {type:21, item:item.data})" v-if="item && item.data && item.data.type=='image'">Add to Estimates Images</li>
                    <li @click="menuForEmailAttachments($event.target, {type:30, item:item.data})">Delete</li>
                </ul>
            </vue-context>
        </div>
    </div>
</template>

<style>

    .delete-activity-modal .modal-dialog {
        position: absolute !important;
        transform: translate(100%, 100%) !important;
    }

    @media (max-width: 1140px) {
      .delete-activity-modal .modal-dialog {
        transform: translate(90%, 100%) !important;
      }
    }

    @media (max-width: 930px) {
      .delete-activity-modal .modal-dialog {
        transform: translate(65%, 100%) !important;
      }
    }

    @media (max-width: 725px) {
      .delete-activity-modal .modal-dialog {
        transform: translate(46%, 100%) !important;
      }
    }

    @media (max-width: 615px) {
      .delete-activity-modal .modal-dialog {
        transform: translate(35%, 100%) !important;
      }
    }


    @media (max-width: 574px) {
      .delete-activity-modal .modal-dialog {
        transform: translate(25%, 100%) !important;
      }
    }

    @media (max-width: 515px) {
      .delete-activity-modal .modal-dialog {
        transform: translate(17%, 100%) !important;
      }
    }

    @media (max-width: 450px) {
      .delete-activity-modal .modal-dialog {
        transform: translate(9%, 100%) !important;
      }
    }

    @media (max-width: 414px) {
      .delete-activity-modal .modal-dialog {
        transform: translate(5%, 100%) !important;
      }
    }

    @media (max-width: 388px) {
      .delete-activity-modal .modal-dialog {
        transform: translate(2%, 100%) !important;
      }
    }

    @media (max-width: 378px) {
      .delete-activity-modal .modal-dialog {
        transform: translate(0%, 100%) !important;
      }
    }

    .delete-activity-modal .modal-dialog .modal-content {
        width: 340px;
    }

    .delete-activity-modal .modal-dialog .modal-content .tab-content {
        margin-bottom: 10px;
    }

    .dropdown-action-item-with-icon {
        padding: 10px 7px 8px 0px !important;
    }

    @media (max-width: 1400px) {
        #activity .timeline .tl-content table td:first-child {
            /*added*/
            min-width: 140px;
            width: auto;
        }

        .activity-table {
            min-width: auto !important;

        }
    }

    @media (max-width: 520px) {
        #activity .timeline .tl-content table td:first-child {
            width: auto;
        }

        .activity-table {
            min-width: auto !important;

        }
    }

    .activity-table tr td {
        padding: 0px 15px 15px 15px;
        color: #1C1F39 !important;
    }

    #activity .timeline .tl-content {
        padding: 15px 15px 1px 15px !important;
    }

    #activity .timeline .tl-content table td:first-child {
        width: 35% !important;
    }

    .table-and-actions {
        display: flex;
        justify-content: space-between;
    }

    .activity-table {
        min-width: 60%;
        width: 60%;
    }

    #activity .tl-wrap.b-a-primary i.fa-envelope-o.left {
        position: absolute;
        left: -10px;
    }

    #activity .tl-wrap.b-a-primary i.fa-pencil-square-o.left {
        position: absolute;
        left: -9px;
        top: -7px;
    }

    #activity .tl-wrap.b-a-primary i.fa-external-link.left {
        position: absolute;
        left: -9px;
        top: -7px;
    }

    #activity .tl-wrap.b-a-primary i.fa-user.left {
        position: absolute;
        left: -7px;
    }

    #activity .tl-wrap.b-a-primary {
        padding-left: 30px;
    }

    #activity .tl-wrap.b-a-primary:before {
        width: 36px;
        height: 36px;
        top: 9px;
        left: -18px;
    }

    #activity .tl-wrap.b-a-primary i.left {
        position: absolute;
        top: 1.25rem;
        left: -10px;
        margin-top: 18px;
        width: 20px;
        height: 20px;
        font-size: 20px;
        color: #3E70C9;
    }

    #activity .timeline {
        padding-left: 25px;
        padding-bottom: 25px;
    }

    .activity-list-header {
        padding: 15px;
    }

    /* Activity | Transaction History Styles */
    /* also for invoice-excess.vue */
    .tab-pane__title,
    .tab-pane h4 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        letter-spacing: normal;
        margin-bottom: 0.618em;
    }

    #activity .vue-form-wizard .wizard-tab-content {
        min-height: 1px;
        padding-bottom: 0;
        border-top: 1px solid transparent;
        margin-top: 0px;
        padding: 5px;
    }

    #activity .wizard-navigation {
        float: left;
        margin-top: 18px;
        margin-left: -29px;
    }

    #activity .tl-wrap.b-a-primary {
        padding-left: 35px;
        padding-bottom: 15px;
    }

    #activity .tl-wrap.b-a-primary:before {
        width: 40px;
        height: 40px;
        /* top: 4.5px; */
        top: 0px;
        left: -21.5px;
        background: #142742;
        border-color: #142742;
    }

    #activity .tl-wrap.b-a-primary i.left {
        position: absolute;
        top: -8px;
        left: -10px;
        margin-top: 18px;
        width: auto;
        height: auto;
        font-size: 18px;
        color: #FFFFFF;
    }

    #activity .tl-wrap.b-a-primary .fa-pencil-square-o {
        left: -7.5px;
        top: 1.3rem;
    }

    #activity .tl-wrap.b-a-primary .fa-usd {
        left: -6px !important;
        top: 1.3rem;
    }

    #activity .tl-wrap.b-a-primary .fa-external-link {
        top: 1.25rem;
        left: -8px;
    }

    #activity .timeline .tl-content {
        min-width: 240px;
        padding: 15.5px;
        background-color: #FFFFFF;
        border: 1px solid rgba(27, 30, 56, 0.25);
        border-radius: 5px;
    }

    #activity .timeline .tl-content table td {
        vertical-align: top;
        padding-bottom: 10px;
    }

    #activity .timeline .tl-content table td:first-child {
        width: 40%;
        min-width: 140px;
        font-weight: 700;
    }

    #activity .timeline .tl-content table tr {
        margin-bottom: 10px;
    }

    #activity .tl-wrap.b-a-primary {
        border-left-width: 1px;
        border-color: rgba(27,30,56,.25) !important;
    }

    #activity .btn.btn-outline-primary.b-dd {
        font-size: 13px;
        font-weight: 600;
        border: none;
    }

    #activity .btn.btn-outline-primary.b-dd i.left {
        position: relative;
        top: 0rem;
        color: #7986A5;
        font-size: 12px;
        margin-left: 7px;
    }

    #activity .btn.btn-outline-primary.b-act {
        text-align: right;
        padding-right: 5px;
        padding-left: 5px;
        margin-top: -21px;
        height: auto;
        margin-right: -7px;
    }

    #activity .btn.btn-outline-primary.b-act i.left {
        position: relative;
        top: 0rem;
        color: #7986A5;
        font-size: 12px;
        margin-left: 14px;
    }

    #activity .btn.btn-outline-primary.b-dd:hover {
        color: #4F5D71;
        background: transparent !important;
    }

    #activity .open .btn.btn-outline-primary.b-dd {
        color: #4F5D71;
    }

    #activity .vue-form-wizard .wizard-header {
        padding: 15px 0;
        padding-bottom: 0px;
    }

    #activity .act-dd i.left {
        width: 14px !important;
        height: 18px !important;
        position: relative !important;
        top: 1px !important;
        margin-top: 0px !important;
        color: #142742 !important;
        display: inline-block;
        left: 0 !important;
        height: auto !important;
        font-size: 11px !important;
    }

    #activity .timeline .tl-item:first-child .tl-wrap {
        padding-top: 23px;
    }

    #activity .timeline .tl-item:first-child .tl-wrap:before {
        margin-top: 23px;
    }

    #activity .timeline .tl-item:first-child .tl-wrap i {
        top: 16px;
    }

    @media screen and (max-width: 490px) {
        #activity .timeline .tl-content .activity-table {
            margin-top: 20px;
            width: 100%;
        }

        #activity .timeline .tl-content table td:first-child {
            min-width: 70px !important;
            word-break: normal !important;
        }
    }

    @media screen and (max-width: 768px) {
        #activity .timeline .tl-content .activity-table {
            width: 100%;
        }

        #activity .timeline .tl-content table td {
            word-break: break-all;
        }
    }

    .timeline .box-block>.arrow.left:after {
        background-color: rgb(250, 250, 250);
    }

    .ex-invoice-excess  .timeline .box-block>.arrow.left:after {
        background-color: #FFFFFF;
    }
</style>

<style scoped>
    @media (min-width: 576px) {
        .additional-left-position {
            float: left;
            margin-left: 45px;
        }
    }
</style>
