<script>
    import Spinner from 'vue-simple-spinner';
    import _ from "lodash";
    export default {
        name: 'advanced-search',
        props: {
            value: null,
            searchData: {
                type: Array,
                default: () => []
            },
            searchKey: {
                type: String,
                default: ''
            },
            searchLabel: {
                type: String,
                default: ''
            },
            openOnFocus: {
                type: Boolean,
                default: false
            },
            openOnInput: {
                type: Boolean,
                default: true
            },
            showOnFocus: {
                type: Boolean,
                default: false
            },
            hideOnBlur: {
                type: Boolean,
                default: true
            },
            isShowNoResult: {
              type: Boolean,
              default: false,
            },
            noResultText: {
              type: String,
              default: 'No Results',
            },
            isLoading: {
              type: Boolean,
              default: false,
            },
            hideOnEscape: {
                type: Boolean,
                default: true
            },
            maxRecords: {
                type: Number,
                default: 7
            },
            isDisabled: {
                type: Boolean,
                default: false
            },
            placeholder: {
                type: String,
                default: ''
            },
            id: {
                type: String
            },
            isShowNew: {
                type: Boolean,
                default: true
            },
            isCanBeEmpty: {
                type: Boolean,
                default: false,
            },
            sortByName: {
                type: String,
                default: '',
            }
        },
        components: {
          'vue-simple-spinner': Spinner,
        },
        data: function () {
            return {
                currentPosition: 0,
                show: false,
                isNew: false,
                mouseInSearchResults: false
            };
        },
        computed: {
            asbValue: {
                get: function () {
                    this.isNew = false;
                    return this.value ? this.value : '';
                },
                set: function (newValue) {
                    this.$emit('input', newValue);
                }
            },
            asbValueLowerCase: function () {
                return this.asbValue.toLowerCase();
            },
            searchd: function () {
                try {
                    if (this.searchData) {
                      if (this.searchKey) {
                        return _.sortBy(this.searchData, [(o) => {return _.trim(_.toLower(o[this.searchKey]))}]);
                      }
                        return this.searchData;
                    }
                    return [];
                } catch (e) {
                    return [];
                }
            },
            key: function () {
                //   console.log(this.searchKey)
                return this.searchKey;
            },
            regTerm: function () {
                //   console.log(new RegExp(this.asbValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), "gi"))
                return new RegExp(this.asbValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), "gi");
            },
            asbValueExists: function () {
                if (this.key) {
                    //    console.log(this.searchResult.findIndex(item => item[this.key].toLowerCase() === this.asbValueLowerCase))
                    return this.searchResult.findIndex(item => item[this.key].toLowerCase() === this.asbValueLowerCase);
                }
//console.log(this.searchResult.findIndex(item => item.toLowerCase() === this.asbValueLowerCase))
                return this.searchResult.findIndex(item => item.toLowerCase() === this.asbValueLowerCase);
            },
            searchResult: function () {
                let rTerm = _.trim(_.toLower(this.asbValue));
                let finalResult = new Array();
                let take = this.maxRecords;
                if (this.searchKey) {
                    for (let i = 0; i < this.searchd.length; i++) {
                        let d = this.searchd[i];
                        if (!_.isEmpty(d) && d[this.key] && _.startsWith(_.trim(_.toLower(d[this.key])), rTerm)) {
                            finalResult.push(d);
                            take = take - 1;
                            if (take === 0) {
                                break;
                            }
                        }
                    }
                } else {
                    for (let i = 0; i < this.searchd.length; i++) {
                        let d = this.searchd[i];
                        if (!_.isEmpty(d) && _.startsWith(_.trim(_.toLower(d)), rTerm)) {
                            finalResult.push(d);
                            take = take - 1;
                            if (take === 0) {
                                break;
                            }
                        }
                    }
                }
                // if (this.sortByName.trim()){
                //   let totalArray = [];
                //   let resultInFirstWord = [];
                //   let resultInAnotherWord = [];
                //
                //   let self = this;
                //   _.forEach(finalResult, (item) => {
                //     if (self.searchKey && item[self.key] && _.startsWith(_.trim(_.toLower(item[self.key].split(' ')[0])), rTerm)) {
                //       resultInFirstWord.push(item)
                //     } else {
                //       resultInAnotherWord.push(item)
                //     }
                //   });
                //
                //   if (this.searchd[0] && this.searchd[0][this.sortByName]){
                //     resultInFirstWord = _.sortBy(resultInFirstWord, [this.sortByName]);
                //     resultInAnotherWord = _.sortBy(resultInAnotherWord, [this.sortByName])
                //   }
                //
                //   _.forEach(resultInFirstWord, (item) => {totalArray.push(item)});
                //   _.forEach(resultInAnotherWord, (item) => {totalArray.push(item)});
                //
                //   return totalArray;
                //
                // }


                return finalResult;
            },
            searchResultCount: function () {
                return this.searchResult.length;
            }
        },
        methods: {
            focusOnInput() {
              this.$refs.asb.focus();
            },
            closeOrOpenResults() {
              if (this.isDisabled) {
                return;
              }
              this.show = !this.show;
              if (this.show === true && this.$refs['asb']) {
                this.$refs['asb'].focus();
              }
            },
            onChange: function (e) {
                //   console.log('change');
                // console.log(d);
                if (this.isCanBeEmpty) {
                    //console.log('onChange22', this.value);
                    if (this.value == '') {
                        this.$emit('onSelect', {id: null, obj: 'empty'});
                    }
                }
                //this.$emit('onChange', d);
            },
            onSelect: function (d) {
                if (!this.searchLabel && !d) {
                    return;
                }
                this.show = false;
                this.isNew = !d;
                this.$emit('onSelect', d);
                this.$emit('onChange', d);
            },
            onEscape: function () {
                // console.log('esc pressed', this.hideOnEscape)
                if (this.hideOnEscape) {
                    this.show = false;
                }
            },
            onFocus: function () {
                this.mouseInSearchResults = false
                if (this.showOnFocus) {
                    this.show = true;
                }
            },
            onBlur: function (d) {
                //   console.log('blur');
                //  console.log(d);
                if (this.hideOnBlur && !this.mouseInSearchResults) {
                    this.show = false;
                    // this.keyEnter()
                }
            },
            keyDownPrevent: function (d) {
                //  console.log('down.prevent');
                // console.log(d);
                if (this.currentPosition < this.searchResultCount) {
                    this.currentPosition = this.currentPosition + 1;
                } else {
                    this.currentPosition = 0;
                }
                this.show = true;
            },
            keyDown: function (d) {
                this.show = true;
                //if (d.keyCode == 9 || d.key == 'Tab') {
                //    this.keyEnter();
                //}
            },
            keyUp: function (d) {
                //   console.log('up');
                //   console.log(d);
                if (this.currentPosition <= 0) {
                    this.currentPosition = this.searchResultCount;
                } else {
                    this.currentPosition = this.currentPosition - 1;
                }
                this.show = true;
            },
            keyEnter: function (d) {
                //   console.log('enter');
                //   console.log(d);
                // console.log(this.currentPosition, this.searchResultCount, this.searchResult[this.currentPosition])
                if (this.currentPosition >= this.searchResultCount) {
                    this.onSelect(null);
                } else {
                    this.onSelect(this.searchResult[this.currentPosition]);
                }
            },
            mouseOver: function (index) {
                this.currentPosition = index;
            },
            mouseEnter: function () {
                // console.log('mousEnter')
                this.mouseInSearchResults = true;
            },
            mouseLeave: function (d) {
                // console.log('mousLeave')
                //   console.log('leave');
                //   console.log(d);
                this.mouseInSearchResults = false;
            },
            keyPress: function (e) {
                //    console.log(e);
            }
        }
    };
</script>

<template>
    <div>
        <span @click="closeOrOpenResults" class="text-grey" :class="{'display-none': isNew}" style="position: absolute; top: 15px; transform:translateY(-50%); right: 2rem;">
            <i class="fa" :class="{'fa-angle-down': !show, 'fa-angle-up': show}"></i>
        </span>
        <span :class="{'display-none': !isNew, 'text-grey': true}" style="position:absolute;top: 50%; transform:translateY(-50%);right:2rem;">NEW</span>

        <div class="register-adv-search-block">
          <input ref="asb" type="text" v-model="asbValue" class="form-control" @keypress="keyPress" @keydown="keyDown" @keydown.down.prevent="keyDownPrevent" @keydown.up.prevent="keyUp"
                 @keyup.enter.prevent="keyEnter" style="opacity: 1"
                 @keyup.esc.prevent="onEscape" @blur="onBlur" @focus="onFocus" :disabled="isDisabled" :placeholder="placeholder" :id="id" @change="onChange" autocomplete="off">
          <vue-simple-spinner v-if="isLoading" :size="23" class="advanced-search-spinner" ></vue-simple-spinner>
        </div>
        <div v-if="show" :class="{'search-box-container': true}">
            <div class="search-box rs-scroll rs-scroll--primary">
                <div class="search-results">
                    <ul v-if="searchKey" class="sr-options" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
                        <li v-if="!searchResult.length && isShowNoResult" class="sr-option" >
                          {{ noResultText }}
                        </li>
                        <li :class="{'selected': index == currentPosition, 'sr-option': true, 'total-word': sr[key].toLowerCase() === asbValue.toLowerCase()}" v-for="(sr, index) in searchResult" @mouseover="mouseOver(index)" @click="keyEnter" :key="index"
                            v-html="sr[key].replace(regTerm, '<b>$&</b>')"></li>
                        <li v-if="searchLabel && asbValue && asbValueExists === -1 && isShowNew" :class="{'selected': searchResultCount == currentPosition, 'sr-option': true}"
                            @mouseover="mouseOver(searchResultCount)" @click="keyEnter">+ New {{searchLabel}}<br/><strong>{{asbValue}}</strong></li>
                    </ul>
                    <ul v-if="!searchKey" class="sr-options" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
                        <li :class="{'selected': index == currentPosition, 'sr-option': true, 'total-word': sr.toLowerCase() === asbValue.toLowerCase()}" v-for="(sr, index) in searchResult" @mouseover="mouseOver(index)" @click="keyEnter"
                            v-html="sr.replace(regTerm, '<b>$&</b>')"></li>
                        <li v-if="searchLabel && asbValue && asbValueExists === -1 && isShowNew" :class="{'selected': searchResultCount == currentPosition, 'sr-option': true}"
                            @mouseover="mouseOver(searchResultCount)" @click="keyEnter">+ New {{searchLabel}}<br/><strong>{{asbValue}}</strong></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    .register-adv-search-block {
      height: 35px;
    }
    .advanced-search-spinner {
      margin-left: 94%;
      margin-top: 4px;
      transform: translateY(-31px);
    }
    .total-word {
      color: #5E79FF !important;
    }
    .search-box-container {
        position: relative;
    }

    .search-box-container .search-box {
        position: absolute;
        width: 100%;
        z-index: 99;
        border: 1px solid rgba(0, 0, 0, .25);
        margin-top: -1px;
        background-color: #FFFFFF;
        max-height: 17rem;
        overflow-y: auto;
    }

    .search-box-container .search-box ul.sr-options {
        list-style: none;
        margin: 0;
        padding: 0;
        overflow-y: auto;
    }

    .search-box-container .search-box ul.sr-options li.sr-option {
        cursor: pointer;
        padding: 6px;
        user-select: none;
        -webkit-user-select: none;
        border-bottom: 1px solid rgba(0, 0, 0, .05);
    }

    /*.search-box-container .search-box ul.sr-options li.sr-option:hover, */
    .search-box-container .search-box ul.sr-options li.sr-option.selected {
      background-color: #edeffd !important;
      color: black;
    }

</style>

<style scoped>
    .V3 span.text-grey i {
        font-size: 24px;
        color: #1C1F39;
    }
</style>
