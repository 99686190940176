import { render, staticRenderFns } from "./the-orders.vue?vue&type=template&id=2278fc45&scoped=true"
import script from "./the-orders.vue?vue&type=script&lang=js"
export * from "./the-orders.vue?vue&type=script&lang=js"
import style0 from "./the-orders.vue?vue&type=style&index=0&id=2278fc45&prod&lang=css"
import style1 from "./the-orders.vue?vue&type=style&index=1&id=2278fc45&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2278fc45",
  null
  
)

export default component.exports