<script>
    import Axios from 'axios';
    import {VueContext} from 'vue-context';
    import Multiselect from 'vue-multiselect';
    import NumberFormatter from './utility/number-formatter';
    import BlockPagination from './utility/block-pagination';
    import {mapGetters} from "vuex";

    export default {
        name: 'suppliers',
        data: function () {
            return {
                filter: '',
                optionTypeValues: [
                  {name: 'Parts', value: 'parts'},
                  {name: 'Consumables', value: 'consumables'},
                  {name: 'Mechanical', value: 'mechanical'},
                  {name: 'PDR', value: 'pdr'},
                  {name: 'Ancilary Services', value: 'ancillary'},
                ],
                contextOpenEvent: null,
                suppliers: [],
                suppliersPerPage: 50,
                suppliersCurrentPage: 1,
                suppliersFiltered: [],

                supplier_type: {
                    OEM: 'OEM',
                    AM: 'After Market',
                    WRECKER: 'Wrecker',
                },
                states: {
                    QLD: 'Queensland',
                    NT: 'Northern Territory',
                    NSW: 'New South Wales',
                    VIC: 'Victoria',
                    TAS: 'Tasmania',
                    SA: 'South Australia',
                    WA: 'Western Australia'
                },
                current_page: 1,
                total_pages: 0,
                total_records: 0,
                start_num: 0,
                end_num: 0,
                fields: [
                    {
                        label: "Supplier Name",
                        key: "business_name",
                        sortable: true,
                        tdClass: 'clickable font-weight-bold td-text',
                        thClass: 'bg-navyblue th-text'
                    },
                    {
                        label: "Type",
                        key: "type",
                        sortable: true,
                        tdClass: 'text-left clickable font-weight-bold td-text',
                        thClass: 'text-left bg-navyblue th-text'
                    },
                    {
                        label: "Email",
                        key: "email",
                        sortable: true,
                        tdClass: 'text-left clickable font-weight-600 td-text',
                        thClass: 'text-left bg-navyblue th-text'
                    },
                    {
                        label: "Address",
                        key: "address",
                        sortable: true,
                        tdClass: 'text-left clickable font-weight-600 td-text',
                        thClass: 'text-left bg-navyblue th-text'
                    },
                    {
                        label: "Suburb",
                        key: "suburb",
                        sortable: true,
                        tdClass: 'text-left clickable font-weight-600 td-text',
                        thClass: 'text-left bg-navyblue th-text'
                    },
                    {
                        label: "State",
                        key: "state",
                        sortable: true,
                        tdClass: 'text-left clickable font-weight-600 td-text',
                        thClass: 'text-left bg-navyblue th-text'
                    },
                    {
                        label: "Phone",
                        key: "phone",
                        sortable: true,
                        tdClass: 'text-left clickable font-weight-600 td-text',
                        thClass: 'text-left bg-navyblue th-text'
                    },
                    {
                        label: " ",
                        key: "supplier_id",
                        sortable: false,
                        tdClass: 'text-left clickable font-weight-600 td-text ',
                        thClass: 'text-left th-text bg-navyblue'
                    }
                ],
                columns: [
                    {label: "Supplier Name", data: "business_name", className: 'text-center clickable'},
                    {label: "Type", data: "type", className: 'text-center clickable'},
                    {label: "Email", data: "email", className: 'text-center clickable'},
                    {label: "Address", data: "address", className: 'text-center clickable'},
                    {label: "Suburb", data: "suburb", className: 'text-center clickable'},
                    {label: "State", data: "state", className: 'text-center clickable'},
                    {label: "Phone", data: "phone", className: 'text-center clickable'}
                ],
                makesSuplied: [],
                value: []
            };
        },
        computed: {
            ...mapGetters({
                isCustomerUser: 'isCustomerUser',
                isEstimatorUser: 'isEstimatorUser',
                isStatusActive: 'isStatusActive',
                getterBusinessName : 'getBusinessName',
              }
            ),
            suppliersFiltered2() {
                return this.suppliersFiltered;
            },
        },
        methods: {
          getTypeName(v) {
            return _.find(this.optionTypeValues, (i) => {
              return i.value == v
            })?.name || ''
          },
          filterForTable(row, filter) {
            let formattedFilter = filter.toLowerCase();
            if (row.abn && row.abn.toLowerCase().includes(formattedFilter)) {
              return true;
            } else if (row.address && row.address.toLowerCase().includes(formattedFilter)) {
              return true;
            } else if (row.business_name && row.business_name.toLowerCase().includes(formattedFilter)) {
              return true;
            } else if (row.country && row.country.toLowerCase().includes(formattedFilter)) {
              return true;
            } else if (row.dms && row.dms.toLowerCase().includes(formattedFilter)) {
              return true;
            } else if (row.email && row.email.toLowerCase().includes(formattedFilter)) {
              return true;
            } else if (row.fax && row.fax.toLowerCase().includes(formattedFilter)) {
              return true;
            } else if (row.level_init_lot && row.level_init_lot.toLowerCase().includes(formattedFilter)) {
              return true;
            } else if (row.makes && row.makes.length > 0 && row.makes.join(' ').toLowerCase().includes(formattedFilter)) {
              return true;
            } else if (row.phone && row.phone.includes(formattedFilter)) {
              return true;
            } else if (row.postcode && row.postcode.toLowerCase().includes(formattedFilter)) {
              return true;
            } else if (row.state && row.state.toLowerCase().includes(formattedFilter)) {
              return true;
            } else if (row.street_name && row.street_name.toLowerCase().includes(formattedFilter)) {
              return true;
            } else if (row.suburb && row.suburb.toLowerCase().includes(formattedFilter)) {
              return true;
            } else if (row.type && row.type.toLowerCase().includes(formattedFilter)) {
              return true;
            }
            return false;
          },
          contextOpen: function (event, w, e) {
            this.contextOpenEvent = event;
          },
          contextClose: function () {
            let event = this.contextOpenEvent;
            if (event) {
              let row = event.target.parentElement;
              let rows = row.parentElement.rows;
              for (let i = 0; i < rows.length; i++) {
                let elem = rows[i];
                elem.style.backgroundColor = "";
              }
            }
          },
          openMenuSupplier(item, index, event){
            if (this.isCustomerUser) {
              return;
            }
            event.preventDefault();
            let row = event.target.parentElement;
            let rows = row.parentElement.rows;
            for (let i = 0; i < rows.length; i++) {
              let elem = rows[i];
              elem.style.backgroundColor = "";
            }
            row.style.backgroundColor = "#F3F3F3";
            this.openSuppliersContext(event, index, item)
          },
            onChangeVehicleMakes: function (a, b, c) {
                //console.log(a);
                //console.log(b);
                //console.log(c);
            },
            loadSuppliers: function (pageNumber) {
                NProgress.start();
                Axios.get('/fe/supplier', {
                    params: {
                        pageNum: pageNumber,
                        sort_by: 'supplier_id',
                        sort_type: 'DESC'
                    }
                })
                    .then(response => {
                        this.suppliers = response.data.data;
                        this.suppliersFiltered = response.data.data;
                        this.current_page = response.data.current_page;
                        this.total_pages = response.data.total_pages;
                        this.total_records = response.data.total_records;
                        this.start_num = response.data.start_num;
                        this.end_num = response.data.end_num;

                        NProgress.done();
                    })
                    .catch(error => {
                        console.log(error);
                        NProgress.done();
                    });
            },
            onSuppliersFiltered: function (fi) {
                this.suppliersFiltered = fi;
                this.suppliersCurrentPage = 1;
            },
            editSupplier: function (item) {
                this.$router.push('/suppliers/view/' + item.supplier_id);
            },
            deleteSupplier: function (item) {
                if (confirm('Are you sure?')) {
                    let id = item.supplier_id;
                    Axios({
                        method: 'post',
                        responseType: 'json',
                        headers: {'Delete': true},
                        url: `/fe/supplier/${id}`,
                        data: {delete: id},
                        validateStatus: function (status) {
                            return status < 500;
                        },
                    })
                        .then(response => {
                            if (response.status === 200) {
                                toastr.success("The Supplier has been deleted");
                                this.loadSuppliers(1);
                            }
                        });
                }
            },
            onSuppliersRowClick: function (item, index, event) {
                this.$router.push('/suppliers/view/' + item.supplier_id);
            },
            paginationClick(pageNumber) {
                this.loadSuppliers(pageNumber);
            },
            openSuppliersContext(e, index, item) {
                if (!this.isStatusActive) {
                  return;
                }
                console.log('test', e, index, item);
                this.$refs.menu.open(e, {item: item, index: index});

                //let el = e.target;
                //let tr = el.closest('TR');
                //if (tr.attributes['aria-rowindex'] === undefined) {
                //    return;
                //}
                //let index = tr.attributes['aria-rowindex'].nodeValue;

                //setTimeout(() => {
                //  [].slice.call(this.$refs.menu.$el.children).forEach((elem) => {
                //	  if (elem.children.length && elem.querySelector('.subContext')) {
                //		  if (this.makesSuplied.length < 4 && elem.querySelector('.subContext .multiselect__tags')) {
                //			  elem.querySelector('.subContext .multiselect__tags').remove();
                //		  }
                //		  elem.classList.add('hasSubContext');
                //		  elem.addEventListener('click', (e) => {
                //			  e.stopPropagation();
                //		  });
                //		  elem.addEventListener('mouseenter', () => {
                //			  if (window.innerWidth < elem.getBoundingClientRect().right + elem.querySelector('.subContext').getBoundingClientRect().width) {
                //				  elem.querySelector('.subContext').classList.add('position_sub_context_left');
                //			  } else elem.querySelector('.subContext').classList.remove('position_sub_context_left');
                //		  });
                //	  } else elem.classList.remove('hasSubContext');
                //  });
                //}, 0);
            }
        },
        mounted: function () {
            this.loadSuppliers(1);
        },
        components: {
            VueContext,
            Multiselect,
            NumberFormatter,
            BlockPagination,
        }
    };
</script>
<style>
    .pt-1-25 {
        padding: 1.25rem 0;
    }

    .pb-nav {
        padding-bottom: 2px !important;
    }

    .font-weight-600 {
        font-weight: 600;
    }

    .suppliers-page .add-supplier i {
        font-weight: bolder !important;
    }

    .suppliers-page .search-input {
        box-sizing: border-box;
        height: 38px;
        border: 1px solid #1C1F39;
        border-radius: 3px;
        width: 100%;
    }

    .suppliers-page .add-supplier {
        height: 38px;
        position: relative;
        bottom: 1px;
        width: 125px;
        border-radius: 3px;
        background-color: #5E79FF;
        color: #FFFFFF;
        font-family: "Nunito Sans";
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
        padding-left: 7px;

    }

    .suppliers-page .td-text {
        color: #1C1F39;
        font-family: "Nunito Sans";
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;
    }

    .suppliers-page .th-text {
        color: #1C1F39;
        font-family: "Nunito Sans";
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
    }
</style>

<template>
    <div class="suppliers-page table-view">
        <div class="page-header">
            <h4>View Suppliers</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item home">
                    <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item active">View Suppliers</li>
            </ol>
            <h4 class="header-display-name">{{ getterBusinessName }}</h4>
            <div class="page-header-nav-btn" v-if="!isCustomerUser && isStatusActive">
                <router-link to="/suppliers/new" class="btn add-supplier">
                    <i class="ti-plus"></i> New Supplier
                </router-link>
            </div>
        </div>
        <tabs :options="{useUrlFragment: false, defaultTabHash: 'suppliers-all' }">
            <tab name="All" id="suppliers-all" ref="activeTab" :class-name="'tab-content-table'">
                <div class="pagination-block-bottom">
                    <b-table
                            class="rs-scroll rs-scroll--y noselect"
                            responsive
                            hover
                            :items="suppliers"
                            :fields="fields"
                            :filter-function="filterForTable"
                            :filter="filter"
                            :current-page="suppliersCurrentPage"
                            :per-page="suppliersPerPage"
                            @filtered="onSuppliersFiltered"
                            @row-clicked="onSuppliersRowClick"
                            @row-contextmenu="openMenuSupplier">
                        <template v-slot:cell(business_name)="data">
                                {{ data.item.business_name }}
                        </template>
                        <template v-slot:cell(type)="data" @contextmenu.prevent="openSuppliersContext($event, data.index, data.item)">
                                {{ getTypeName(data.item.business_type) }}
                        </template>
                        <template v-slot:cell(email)="data">
                                {{ data.item.email }}
                        </template>
                        <template v-slot:cell(address)="data">
                                {{ data.item.address }}
                        </template>
                        <template v-slot:cell(suburb)="data">
                                {{ data.item.suburb }}
                        </template>
                        <template v-slot:cell(state)="data">
                                {{ data.item.state }}
                        </template>
                        <template v-slot:cell(phone)="data">
                                {{ data.item.phone }}
                        </template>
                        <template v-slot:cell(supplier_id)="data">
                            <button v-if="!isCustomerUser && !isEstimatorUser && isStatusActive" v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltip--bottomleft', html: true, title:'Delete Supplier', placement:'bottomleft'}" @click="deleteSupplier(data.item)"
                                    class="btn_trush clickable delete"></button>
                        </template>
                    </b-table>
                </div>
                <vue-context ref="menu" @open  = "contextOpen" @close = 'contextClose'>
                    <ul slot-scope="item" v-if="item.data">
                        <li @click="editSupplier(item.data.item)">Edit</li>
                        <li class="hasSubContext">View Makes Supplied<i class="bx bx-chevron-right" style="font-size: 21px; transform: translate(2px, -3px)"></i>
                            <ul class="context-level subContext"
                                v-if="item && item.data && item.data.item && item.data.item.makes && item.data.item.makes.length > 0">
                                <li class="no-cursor" v-for="make in item.data.item.makes">
                                    {{ make }}
                                </li>
                            </ul>
                            <ul v-else class="context-level subContext">
                                <li class="no-cursor">No Makes</li>
                            </ul>
                        </li>
                        <li  v-if="!isCustomerUser && !isEstimatorUser" @click="deleteSupplier(item.data.item)">Delete</li>
                    </ul>
                </vue-context>
                <block-pagination
                    :role="'suppliers'"
                    :arrayOfSomeone="suppliers"
                    :arrayOfSomeoneFiltered="suppliersFiltered2"
                    :currentPage="suppliersCurrentPage"
                    @changedCurrentPage="value => suppliersCurrentPage = value"
                    :perPage="suppliersPerPage"
                    @changedPerPage="value => suppliersPerPage = value"
                    >
                </block-pagination>
            </tab>
            <template slot="nav-item-right">
                <div class="tabs-nav-right-search">
                    <b-form-input v-model="filter" type="text" class="form-control search-input search-position"
                                  placeholder="Search or Filter results"
                                  debounce="100"></b-form-input>
                </div>
                <div v-if="!isCustomerUser && isStatusActive" class="tabs-nav-right-btn">
                    <router-link to="/suppliers/new" class="btn add-supplier add-button-table">
                        <i class="ti-plus"></i> New Supplier
                    </router-link>
                </div>
            </template>
        </tabs>
    </div>
</template>
<style>
    .no-cursor {
        cursor: default !important;
    }

    .site-content .suppliers-page .hasSubContext {
        padding: 8px 5px 5px 10px !important;
    }

    .site-content .suppliers-page .subContext {
        left: 101% !important;
        width: 145px;
    }

    .pt-1-25 {
        padding: 1.25rem 0;
    }

    .pb-nav {
        padding-bottom: 2px !important;
    }

    .v-context li {
        position: relative;

    }

    .v-context li i {
        float: right;
        font-size: 10px;
        transform: translateY(5px);
    }

    .v-context .context-level {
        position: absolute;
        left: 248px;
        width: 248px;
        top: 0;
        border: 1px solid #BDBDBD;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
        transition: .4s;
        visibility: hidden;
        opacity: 0;
        transform: translateY(15px);
        background: #FFFFFF;
    }

    .v-context li:hover .context-level {
        visibility: visible;
        opacity: 1;
        transform: translateY(0px);
    }

    @media screen and (max-width: 767px) {
        .hasSubContext:hover:after {
            transform: rotate(90deg);
        }

        .subContext {
            left: 0 !important;
            right: auto !important;
            /*top: 100% !important;*/
            width: 100% !important;
        }
    }

    .subContext {
        padding: 0 !important;
    }

    .position_sub_context_left {
        right: calc(100% + 1px) !important;
        left: auto !important;
    }



</style>
<style scoped>
    .ti-plus {
        margin-right: 10px;
    }

    .btn-primary.ml-1.pull-right {
        height: 37px;
        display: flex;
        align-items: center;
        padding: auto 10px;
        padding: 0px 13px;
        border-radius: 3px;
    }

    .control-box {
        height: 69px;
        padding-top: 15px;
        padding-right: 15px;
    }

    .table-action {
        position: absolute;
        right: 30px;
        top: 145px;
    }

    .suppliers-page .table.b-table td div {
        padding: 13px 15px;
    }

    @media (min-width: 626px) {
        .tabs-nav-right-btn {
            margin-left: 15px !important;
        }
    }

    @media (max-width: 576px) {
        .search-position {
            margin: 10px 0 !important;
        }
    }
</style>
