<script>
    import Axios from 'axios';
    import AmazonAds from './utility/amazon-ads';
    import GoogleLocation from './utility/google-location';
    import AddressSuggestions from './utility/address-suggestions';
    import SupplierMakes from './utility/supplier-makes';
    import Multiselect from 'vue-multiselect';
    import {VueContext} from 'vue-context';
    import {mapGetters} from "vuex";
    import Email from './utility/email-validator';

    export default {
        name: 'suppliers-edit',
        data: function () {
            return {
                vehicleOwnersStatus: true,
                selectedMake: '',
                isCheckedSuppEmail: true,
                isCheckedContactEmail: true,
                supplier: {
                    id: 0,
                    business_name: '',
                    abn: '',
                    type: '',
                  businessType: '',
                    dms: '',
                    phone: '',
                    fax: '',
                    email: '',
                    address: '',
                    street_name: '',
                    level_unit_lot: '',
                    suburb: '',
                    state: '',
                    postcode: '',
                    country: '',
                    makes: [],
                    contacts: [],
                    warehouses: [],
                },
                value: [],
                optionSupplyKind: {},
                optiontype: {},
                optionstate: {},
                optioncountry: {},
                optionSupplyKindValues: [],
                optiontypevalues: [
                    {name: 'Parts', value: 'parts'},
                    {name: 'Consumables', value: 'consumables'},
                    {name: 'Mechanical', value: 'mechanical'},
                    {name: 'PDR', value: 'pdr'},
                    {name: 'Ancilary Services', value: 'ancillary'},],
                optionstatevalues: [
                    {name: 'Queensland', value: 'QLD'},
                    {name: 'Northern Territory', value: 'NT'},
                    {name: 'New South Wales', value: 'NSW'},
                    {name: 'Victoria', value: 'VIC'},
                    {name: 'Tasmania', value: 'TAS'},
                    {name: 'South Australia', value: 'SA'},
                    {name: 'Western Australia', value: 'WA'},],
                optioncountryvalues: [{name: 'Australia', value: 'AU'}, {name: 'New Zealand', value: 'NZ'}],
                contacts: {
                    filter: '',
                    contactsAll: [],
                    contactsAllPerPage: 20,
                    contactsAllCurrentPage: 1,
                    contactsAllFiltered: [],
                },
                locations: {
                    filter: '',
                    locationsAll: [],
                    locationsAllPerPage: 20,
                    locationsAllCurrentPage: 1,
                    locationsAllFiltered: [],
                },
                contactModalInfo: {
                    id: '',
                    name: '',
                    position: '',
                    department: '',
                    office_phone: '',
                    mobile_phone: '',
                    email: '',
                    location: ''
                },
                locationModalInfo: {
                    id: '',
                    name: '',
                    address: '',
                    phone: '',
                    hours_open: '',
                    hours_closing: '',
                    delivery: ''
                },
                openModalRow: 'edit',
                toOpenModal: '',
                deletedName: '',
                deleteObj: {},
                contactModalInfoIndex: '',
                locationModalInfoIndex: '',
                addressOptions: {
                    type: "ADDRESS",
                    scrollOnFocus: false,
                    triggerSelectOnBlur: false,
                    triggerSelectOnEnter: false,
                    addon: 'none',
                    url: '/fe/suggestions/suppliers/contact/' + this.$route.params.supplier_id,
                    hint: ' ',
                    noSuggestionsHint: ' ',
                },
            };
        },
        computed: {
            ...mapGetters({
                isCustomerUser: 'isCustomerUser',
                isEstimatorUser: 'isEstimatorUser',
                isStatusActive: 'isStatusActive',
              }
            ),
            selectionSupplyKind(){
              if(this.optiontype.value == 'parts'){
                this.optionSupplyKind = {}
                return [{name: 'OEM', value: 'OEM'}, {name: 'PARALLEL', value: 'PARALLEL'}, {name: 'AFTERMARKET', value: 'AFTERMARKET'}, {name: 'USED', value: 'USED'}, {name: 'RECO', value: 'RECO'}]
              }
              if(this.optiontype.value == 'consumables'){
                this.optionSupplyKind = {}
                return [{name: 'PAINT', value: 'PAINT'}, {name: 'WORKSHOP Materials', value: 'WM'}, {name: 'BOTH', value: 'BOTH'}]
              }
              return []
            },
            contactsFields() {
              let fields =  [
                {label: "Name", key: "name", sortable: true, tdClass: 'clickable number', thClass: 'bg-navyblue number'},
                {label: "Position", key: "position", sortable: true, tdClass: 'clickable customer', thClass: 'bg-navyblue customer'},
                {label: "Department", key: "department", sortable: true, tdClass: 'clickable rego', thClass: 'bg-navyblue rego'},
                {label: "Office Phone", key: "office_phone", sortable: true, tdClass: 'clickable make', thClass: 'bg-navyblue make'},
                {label: "Mobile Phone", key: "mobile_phone", sortable: true, tdClass: 'clickable model', thClass: 'bg-navyblue model'},
                {label: "Email", key: "email", sortable: true, tdClass: 'clickable colour', thClass: 'bg-navyblue colour'},
                {label: "Location", key: "location", sortable: true, tdClass: 'clickable colour', thClass: 'bg-navyblue colour'},
              ]
              if (this.isCustomerUser) {
                return fields;
              }
              fields.push({label: "Action", key: "action", sortable: false, tdClass: 'text-center clickable action colour', thClass: 'bg-navyblue action colour'})
              return fields
            },
            locationsFields() {
              let fields = [
                {label: "Location Name", key: "name", sortable: true, tdClass: 'clickable ', thClass: 'bg-navyblue '},
                {label: "Address", key: "address", sortable: true, tdClass: 'clickable ', thClass: 'bg-navyblue'},
                {label: "Phone", key: "phone", sortable: true, tdClass: 'clickable ', thClass: 'bg-navyblue'},
                {label: "Open Hours", key: "hours_open", sortable: true, tdClass: 'clickable ', thClass: 'bg-navyblue'},
                {label: "Closing Hours", key: "hours_closing", sortable: true, tdClass: 'clickable ', thClass: 'bg-navyblue'},
                {label: "Delivery", key: "delivery", sortable: true, tdClass: 'clickable ', thClass: 'bg-navyblue'},
              ]
              if (this.isCustomerUser) {
                return fields;
              }
              fields.push({label: "Action", key: "action", sortable: false, tdClass: 'text-center clickable action', thClass: 'bg-navyblue action'})
              return fields

            },
            vehicleMakeModels: {
                get: function () {
                    return this.$store.state.vehicleMakeModels;
                },
                set: function (obj) {
                },
            },
            vehicleMakes: function () {
                try {
                    var _result = [];
                    _.forEach(this.vehicleMakeModels, function (value, key) {
                        _result.push(key);
                    });
                    return _.sortBy(_result);
                } catch (erro) {
                    console.log(erro);
                    return [];
                }
            },
        },
        watch: {
            selectedMake: function (newVal, oldValue) {
                if (oldValue && newVal === null) {
                  let index = this.supplier.makes.indexOf(oldValue);
                  this.supplier.makes.splice(index, 1);
                  return;
                }
                if (!newVal) {
                  return;
                }
                if (this.supplier.makes.includes(newVal)) {
                  return;
                }
                this.supplier.makes.push(newVal);
            },
        },
        methods: {
            removeChip(chip) {
              if (this.selectedMake === chip) {
                this.selectedMake = '';
              }
            },
            saveData: function () {
                if (!this.isCheckedSuppEmail) {
                    toastr.error('Your changes could not be saved, please check the form fields again');
                    return;
                }
                NProgress.start();
                let supplier_id = this.$route.params.supplier_id;
                if (this.optiontype) {
                    this.supplier.businessType = this.optiontype.value;
                }
                if (this.optionSupplyKind) {
                    this.supplier.type = this.optionSupplyKind.value;
                }
                if (this.optionstate) {
                    this.supplier.state = this.optionstate.value;
                }
                if (this.optioncountry) {
                    this.supplier.country = this.optioncountry.value;
                }
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: `/fe/supplier/${supplier_id}`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: JSON.stringify(this.supplier)
                })
                    .then(response => {
                        if (response.status == 200) {
                            toastr.success('Supplier details have been saved');
                            this.loadSupplier();
                            // this.$router.push('/suppliers')
                        } else {
                            toastr.error(response.data.msg);
                        }
                    })
                    .catch(error => {

                        toastr.error(error);
                    })
                    .finally(() => {
                        NProgress.done();
                    });
            },
            loadSupplier: function () {
                NProgress.start();
                let supplier_id = this.$route.params.supplier_id;
                Axios.get(`/fe/supplier/${supplier_id}`)
                    .then(response => {
                        this.supplier = response.data.supplier;
                        this.optiontype = this.optiontypevalues.find(item => item.value == this.supplier.businessType);
                        this.optionSupplyKind = this.selectionSupplyKind.find(item => item.value == this.supplier.type);
                        this.optionstate = this.optionstatevalues.find(item => item.value == this.supplier.state);
                        this.optioncountry = this.optioncountryvalues.find(item => item.value == this.supplier.country);
                        NProgress.done();
                    })
                    .catch(error => {
                        toastr.error("Supplier not found");
                        console.log(error);
                        NProgress.done();
                        this.$router.push('/suppliers');
                    });
            },
            addressChanged: function (addr) {
                this.supplier.address = addr["address"];
                this.supplier.level_unit_lot = addr['levelUnitLot'];
                this.supplier.street_name = addr["street_name"];
                this.supplier.suburb = addr["suburb"];
                this.supplier.state = addr["state"];
                this.optionstate = this.optionstatevalues.find(item => item.value == this.supplier.state);
                this.supplier.postcode = addr["postcode"];
                this.supplier.country = addr["country"];
              this.optioncountry = this.optioncountryvalues.find(item => item.value == this.supplier.country);
            },
            testing(modal, type) {
                this.openModalRow = type;
                modal.show();
            },
            addContact() {
                if (this.isCustomerUser || !this.isStatusActive) {
                  return;
                }
                this.contactModalInfo = {
                    id: '',
                    name: '',
                    position: '',
                    department: '',
                    office_phone: '',
                    mobile_phone: '',
                    email: '',
                    location: '',
                };
                this.openModalRow = 'add';
                this.$refs[this.toOpenModal].show();
            },
            editContact(index, data) {
                if (this.isCustomerUser || !this.isStatusActive) {
                  return
                }
                this.contactModalInfo = Object.assign({}, data);
                this.contactModalInfoIndex = index;
                this.openModalRow = 'edit';
                this.$refs[this.toOpenModal].show();
            },
            completeEditContact() {
                if (!this.isCheckedContactEmail) {
                    toastr.error('Your changes could not be saved, please check the form fields again');
                    return;
                }
                if (this.openModalRow == 'add' || this.openModalRow == 'edit') {
                    this.supplier.contacts.push(this.contactModalInfo);
                    this.saveData();
                } else {
                    this.supplier.contacts.splice(this.contactModalInfoIndex, 1, this.contactModalInfo);
                }
                this.contactModalInfo = {};
                this.$refs[this.toOpenModal].hide();
            },
            openContextContacts(e, index, item) {
                if (!this.isCustomerUser) {
                  this.$refs.menuContact.open(e, {item: item, index: index});
                }
            },
            removeItemOnContacts(data) {
                if (this.isCustomerUser || !this.isStatusActive) {
                  return
                }
                this.openModalRow = 'delete';
                this.deletedName = data.item.name;
                this.deleteObj = data;
                this.$refs[this.toOpenModal].show();
            },
            completeDeleteContact() {
                this.supplier.contacts.splice(this.deleteObj.index, 1);
                this.$refs[this.toOpenModal].hide();
                this.deleteObj = {};
                this.saveData();
            },
            addLocation() {
                if (this.isCustomerUser || !this.isStatusActive) {
                  return
                }
                this.locationModalInfo = {
                    id: '',
                    name: '',
                    address: '',
                    phone: '',
                    hours_open: '',
                    hours_closing: '',
                    delivery: ''
                };
                this.openModalRow = 'add';
                this.$refs[this.toOpenModal].show();
            },
            editLocation(index, data) {
                if (this.isCustomerUser || !this.isStatusActive) {
                  return;
                }
                this.locationModalInfo = Object.assign({}, data);
                this.locationModalInfoIndex = index;
                this.openModalRow = 'edit';
                this.$refs[this.toOpenModal].show();
            },
            completeEditLocation() {
                if (this.openModalRow == 'add' || this.openModalRow == 'edit') {
                    this.supplier.warehouses.push(this.locationModalInfo);
                    this.saveData();
                } else {
                    this.supplier.warehouses.splice(this.locationModalInfoIndex, 1, this.locationModalInfo);
                }
                this.locationModalInfo = {};
                this.$refs[this.toOpenModal].hide();
            },
            editContactByDblClick(contact, id) {
                this.editContact(id, contact);
            },
            editLocationByDblClick(location, id) {
                this.editLocation(id, location);
            },
            openContextLocation(e, index, item) {
                if (!this.isCustomerUser) {
                  this.$refs.menuLocation.open(e, {item: item, index: index});
                }
            },
            completeDeleteLocation() {
                this.supplier.warehouses.splice(this.deleteObj.index, 1);
                this.$refs[this.toOpenModal].hide();
                this.deleteObj = {};
                this.saveData();
            },

            removeItemOnLocations(data) {
                if (this.isCustomerUser || !this.isStatusActive) {
                  return;
                }
                this.openModalRow = 'delete';
                this.deletedName = data.item.name;
                this.deleteObj = data;
                this.$refs[this.toOpenModal].show();
            },
            chooseTypeTab(chooseType) {
                this.toOpenModal = chooseType;
            },
            deleteSupplier() {
                if (confirm('Are you sure?')) {
                    Axios.post(`/fe/supplier/${this.$route.params.supplier_id}/delete`, {})
                        .then(response => {
                            if (response && response.data && response.data._status) {
                                toastr.success('Supplier has been deleted!');
                                this.$router.push('/suppliers');
                            } else {
                                toastr.error('Cannot delete supplier');
                                console.error(response);
                            }
                        })
                        .catch(e => {
                            toastr.error('Cannot delete supplier');
                            console.error(e);
                        });
                }
            },
        },
        mounted: function () {
            this.loadSupplier();
        },
        components: {
            AmazonAds,
            GoogleLocation,
            Multiselect,
            SupplierMakes,
            VueContext,
            AddressSuggestions,
            Email,
        }
    };
</script>
<style>
    .ask_delete_modal .modal-content .ti-trash {
        color: #FFFFFF !important;
    }

    .ask_delete_modal .modal-content {
        max-width: 500px;
        margin: auto;
    }

    .tables_bottom table thead th {
        background-color: #E7E8EC !important;
        color: #373A3C !important;
        font-weight: 400;
    }
</style>
<style scoped>
    .card-header {
        border-bottom-color: #EEEEEE !important;
        background-color: white !important;
        height: unset;
    }

    @media (max-width: 767px) {
        .overflow-x-xs {
            overflow-x: auto;
        }

        .pl-0 {
            padding-right: 0;
        }

        .pr-0 {
            padding-left: 0;
        }
    }

    .edit_cell_wrapper > div {
        margin-bottom: 15px;
    }

    .table_head {
        background-color: rgb(239, 238, 241);
        margin-bottom: 3px;
        border-radius: 5px 5px 0px 0px;
        height: 50px;
    }

    .table_head > p {
        margin-bottom: 0;
        color: black;
        padding: 14px;
        font-weight: bold;
        font-size: 15px;
    }

    .div-btn-save {
        height: 60px;
        align-items: center;
        display: flex;
        justify-content: flex-end;
    }
</style>

<template>
    <div class="suppliers-edit-page">
        <div class="page-header">
            <h4>Edit Supplier: {{supplier.business_name}}</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item home">
                    <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link to="/suppliers">View Suppliers</router-link>
                </li>
                <li class="breadcrumb-item active">Edit Supplier</li>
            </ol>
        </div>
        <div class="box box-block bg-white">
            <div class="tabs-component">
                <ul class="nav nav-tabs nav-tabs-2 float-sm-left">
                    <li class="tabs-component-tab nav-item">
                        <a class="nav-link  active" data-toggle="tab" href="#details" @click="chooseTypeTab('suppliesEdit')">Details</a>
                    </li>
                    <li class="tabs-component-tab nav-item">
                        <a class="nav-link" data-toggle="tab" href="#contacts" @click="chooseTypeTab('openContactModalRef')">Contacts</a>
                    </li>
                    <li class="tabs-component-tab nav-item">
                        <a class="nav-link" data-toggle="tab" href="#locations" @click="chooseTypeTab('openLocationModalRef')">Locations</a>
                    </li>
                </ul>
                <div class="rs-btn-group tab-header float-sm-right">
                    <div v-if="toOpenModal == 'openLocationModalRef'" class="tabs-nav-right-search">
                        <b-form-input v-model="locations.filter" type="text" class="form-control search-input" placeholder="Search or Filter results"
                                      debounce="100"></b-form-input>
                    </div>
                    <div v-if="toOpenModal == 'openLocationModalRef' && !isCustomerUser && isStatusActive" class="tabs-nav-right-btn">
                        <button type="button" class="btn-primary" @click="addLocation"><i class="ti-plus"></i> New Location</button>
                    </div>
                    <div v-if="toOpenModal == 'openContactModalRef'" class="tabs-nav-right-search">
                        <b-form-input v-model="contacts.filter" type="text" class="form-control search-input" placeholder="Search or Filter results"
                                      debounce="100"></b-form-input>
                    </div>
                    <div v-if="toOpenModal == 'openContactModalRef' && !isCustomerUser && isStatusActive" class="tabs-nav-right-btn">
                        <button type="button" class="btn-primary" @click="addContact"><i class="ti-plus"></i> New Contact</button>
                    </div>
                    <div v-if="toOpenModal != 'openContactModalRef' && toOpenModal != 'openLocationModalRef' && !isCustomerUser && isStatusActive" class="tabs-nav-right-btn">
                        <button @click="saveData" type="button" class="btn-primary  btn-save">Save</button>
                    </div>
                </div>
            </div>
            <div class="card main-card">
                <div class="card-block tab-content tables_bottom">
                    <div id="details" class="card-block tab-pane  active p-0">
                        <div class="row">
                            <div class="col-md-6 pl-0">
                                <!--Supplier Details-->
                                <div class="card">
                                    <div class="card-header bg-navyblue">
                                        <strong>Vehicle Owners</strong>
                                    </div>

                                    <div class="card-block bg-white">
                                      <div class="card-block bg-white">
                                        <label class="col-lg-3 col-form-label" style="padding-left: 0px;">Status</label>
                                        <toggle-button
                                            v-model="vehicleOwnersStatus"
                                            class="mb-0 rs-toggle__button"
                                            color="#5E79FF"
                                            :width="40"
                                            :disabled="!isStatusActive"
                                            :sync="true"
                                            :labels="false"/>
                                        <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25">
                                                            <template
                                                                v-if="vehicleOwnersStatus">Active</template>
                                                            <template v-else>Not Active</template>
                                                        </span>
                                      </div>
                                        <div class="form-group row">
                                          <label for="type" class="col-lg-3 col-form-label">Business Type</label>
                                          <div class="col-lg-9">
                                            <multiselect v-model="optiontype" :options="optiontypevalues"
                                                         :searchable="false"
                                                         :close-on-select="true"
                                                         :show-labels="false"
                                                         :disabled="isCustomerUser || !isStatusActive"
                                                         style="opacity: 1; height: 32px"
                                                         :class="{'customer-service-disabled': isCustomerUser}"
                                                         track-by="value"
                                                         label="name"
                                                         id="type"
                                            >
                                            </multiselect>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="business_name" class="col-lg-3 col-form-label">Name</label>
                                            <div class="col-lg-9">
                                                <input :readonly="!isStatusActive" :disabled="isCustomerUser" type="text" v-model="supplier.business_name" :class="{'customer-service-disabled': isCustomerUser}" class="form-control" id="business_name" placeholder="Business Name">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="abn" class="col-lg-3 col-form-label">ABN</label>
                                            <div class="col-lg-9">
                                                <input :readonly="!isStatusActive" :disabled="isCustomerUser" type="text" v-model="supplier.abn" :class="{'customer-service-disabled': isCustomerUser}" class="form-control" id="abn" placeholder="ABN">
                                            </div>
                                        </div>
                                        <div v-if="optiontype.value === 'parts' || optiontype.value === 'consumables'" class="form-group row">
                                          <label for="type" class="col-lg-3 col-form-label">Supply Kind</label>
                                          <div class="col-lg-9">
                                            <multiselect v-model="optionSupplyKind"
                                                         :options="selectionSupplyKind"
                                                         :close-on-select="true"
                                                         :showLabels="false"
                                                         :disabled="isCustomerUser || !isStatusActive"
                                                         style="opacity: 1; height: 32px"
                                                         :class="{'customer-service-disabled': isCustomerUser}"
                                                         track-by="value"
                                                         label="name"
                                                         :max-height="203"
                                            >
                                            </multiselect>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="dms" class="col-lg-3 col-form-label">DMS</label>
                                            <div class="col-lg-9">
                                                <input :readonly="!isStatusActive" :disabled="isCustomerUser" type="text" v-model="supplier.dms" :class="{'customer-service-disabled': isCustomerUser}" class="form-control" id="dms" placeholder="DMS">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="phone" class="col-lg-3 col-form-label">Phone</label>
                                            <div class="col-lg-9">
                                                <input :readonly="!isStatusActive" :disabled="isCustomerUser" type="text" v-model="supplier.phone" :class="{'customer-service-disabled': isCustomerUser}" class="form-control" id="phone" placeholder="Phone">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="fax" class="col-lg-3 col-form-label">Fax</label>
                                            <div class="col-lg-9">
                                                <input :readonly="!isStatusActive" :disabled="isCustomerUser" type="text" v-model="supplier.fax" :class="{'customer-service-disabled': isCustomerUser}" class="form-control" id="fax" placeholder="Fax">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="email" class="col-lg-3 col-form-label">Email</label>
                                            <email
                                                :email="supplier.email"
                                                :is-disabled="isCustomerUser || !isStatusActive"
                                                @onChange="(value) => supplier.email = value"
                                                @checked="(value) => isCheckedSuppEmail = value"
                                                class="col-lg-9"
                                            ></email>

                                        </div>
                                        <div class="form-group row">
                                            <label for="address" class="col-lg-3 col-form-label">Search Address</label>
                                            <div class="col-lg-9">
                                                <google-location type="text" :refsAddress="'defaultForm'" v-model="supplier.address" id="address"
                                                                 placeholder="Search for an Address"
                                                                 :isDisabled="isCustomerUser || !isStatusActive"
                                                                 :class="{'customer-service-disabled': isCustomerUser}"
                                                                 @onPlaceChange="addressChanged"></google-location>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="level_unit_lot" class="col-lg-3 col-form-label">Level/Unit/Lot Nbr</label>
                                            <div class="col-lg-9">
                                                <input :disabled="isCustomerUser || !isStatusActive" type="text" v-model="supplier.level_unit_lot" :class="{'customer-service-disabled': isCustomerUser}" class="form-control" id="level_unit_lot" placeholder="Lot, Unit, or Level number">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="street_name" class="col-lg-3 col-form-label">Street Name</label>
                                            <div class="col-lg-9">
                                                <input :readonly="!isStatusActive" :disabled="isCustomerUser" type="text" v-model="supplier.street_name" :class="{'customer-service-disabled': isCustomerUser}" class="form-control" id="street_name" placeholder="Street Name">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="suburb" class="col-lg-3 col-form-label">Suburb</label>
                                            <div class="col-lg-9">
                                                <input :readonly="!isStatusActive" :disabled="isCustomerUser" type="text" v-model="supplier.suburb" :class="{'customer-service-disabled': isCustomerUser}" class="form-control" id="suburb" placeholder="Suburb">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="state" class="col-lg-3 col-form-label">State</label>
                                            <div class="col-lg-9">
                                                <multiselect v-model="optionstate"
                                                             :options="optionstatevalues"
                                                             :searchable="false"
                                                             :close-on-select="true"
                                                             :disabled="isCustomerUser || !isStatusActive"
                                                             style="opacity: 1"
                                                             :class="{'customer-service-disabled': isCustomerUser}"
                                                             :show-labels="false"
                                                             track-by="value"
                                                             label="name"
                                                >
                                                </multiselect>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="postcode" class="col-lg-3 col-form-label">Postcode</label>
                                            <div class="col-lg-9">
                                                <input :readonly="!isStatusActive" :disabled="isCustomerUser" type="text" v-model="supplier.postcode" :class="{'customer-service-disabled': isCustomerUser}" class="form-control" id="postcode" placeholder="Postcode">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="country" class="col-lg-3 col-form-label">Country</label>
                                            <div class="col-lg-9">
                                                <multiselect v-model="optioncountry" :options="optioncountryvalues"
                                                             :searchable="false"
                                                             :close-on-select="true"
                                                             :show-labels="false"
                                                             :disabled="isCustomerUser || !isStatusActive"
                                                             style="opacity: 1"
                                                             :class="{'customer-service-disabled': isCustomerUser}"
                                                             track-by="value"
                                                             label="name"
                                                >
                                                </multiselect>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-lg-12">
                                                <button v-if="!isCustomerUser && !isEstimatorUser && isStatusActive" class="btn btn-danger font-weight-bold pull-right" @click="deleteSupplier">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>
                            <div class="col-md-6 pr-0">
                                <div class="card">
                                    <div class="card-header bg-navyblue suplier-mobile-block">
                                        <strong>Supplier Brands</strong>
                                    </div>
                                    <div class="card-block bg-white">
                                        <div class="form-group row">
                                            <label class="col-lg-4 col-form-label">Search and add Makes of Cars supplied</label>
                                            <div class="col-lg-8">
                                                <multiselect
                                                        v-model="selectedMake"
                                                        :options="vehicleMakes"
                                                        :multiple="false"
                                                        :close-on-select="true"
                                                        :clear-on-select="true"
                                                        :disabled="isCustomerUser || !isStatusActive"
                                                        style="opacity: 1"
                                                        :class="{'customer-service-disabled': isCustomerUser}"
                                                        :preserve-search="true"
                                                        placeholder="Type to search and add a make"
                                                        :preselect-first="false"
                                                >
                                                    <template v-slot:cell(selection)="{ values, search, isOpen }"></template>
                                                </multiselect>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-lg-4 col-form-label">Makes of Parts Supplied</label>
                                            <div class="col-lg-8">
                                                <supplier-makes @removeChip="removeChip" :chips="supplier.makes"></supplier-makes>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="col-md-6 pr-0">
                              <div class="card">
                                <div class="card-header bg-navyblue suplier-mobile-block">
                                  <strong>Account</strong>
                                </div>
                                <div class="card-block bg-white">
                                  <div class="form-group row">
                                    <label for="level_unit_lot" class="col-lg-3 col-form-label">Account Type</label>
                                    <div class="col-lg-9">
                                      <input :disabled="isCustomerUser" type="text" :class="{'customer-service-disabled': isCustomerUser}" class="form-control" id="level_unit_lot" placeholder="Cash / COD">
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <label for="street_name" class="col-lg-3 col-form-label">Tax Code</label>
                                    <div class="col-lg-9">
                                      <input :readonly="!isStatusActive" :disabled="isCustomerUser" type="text" :class="{'customer-service-disabled': isCustomerUser}" class="form-control" id="street_name" >
                                    </div>
                                  </div>

                                </div>
                              </div>

                            </div>
                        </div>
                    </div>
                    <div id="contacts" class="tab-pane p-0" role="tabpanel">
                        <div class="overflow-x-xs">
                            <div class="table_head">
                                <p>Contacts</p>
                            </div>
                            <b-table
                                    class="rs-scroll rs-scroll--y"
                                    responsive
                                    hover
                                    :items="supplier.contacts" :fields="contactsFields" :filter="contacts.filter"
                                    @row-dblclicked="editContactByDblClick"
                            >
                                <template v-slot:cell(name)="data">
                                    <div class="noselect" @contextmenu.prevent="openContextContacts($event, data.index, data.item)">{{data.item.name}}</div>
                                </template>
                                <template v-slot:cell(position)="data">
                                    <div class="noselect" @contextmenu.prevent="openContextContacts($event, data.index, data.item)">{{data.item.position}}</div>
                                </template>
                                <template v-slot:cell(department)="data">
                                    <div class="noselect" @contextmenu.prevent="openContextContacts($event, data.index, data.item)">{{data.item.department}}</div>
                                </template>
                                <template v-slot:cell(office_phone)="data">
                                    <div class="noselect" @contextmenu.prevent="openContextContacts($event, data.index, data.item)">{{data.item.office_phone}}</div>
                                </template>
                                <template v-slot:cell(mobile_phone)="data">
                                    <div class="noselect" @contextmenu.prevent="openContextContacts($event, data.index, data.item)">{{data.item.mobile_phone}}</div>
                                </template>
                                <template v-slot:cell(email)="data">
                                    <div class="noselect" @contextmenu.prevent="openContextContacts($event, data.index, data.item)">{{data.item.email}}</div>
                                </template>
                                <template v-slot:cell(location)="data">
                                    <div class="noselect" @contextmenu.prevent="openContextContacts($event, data.index, data.item)">{{data.item.location}}</div>
                                </template>
                                <template v-slot:cell(action)="data">
                                    <button class="btn_edit" @click="editContact(data.index, data.item)"></button>
                                    <button  v-if="!isCustomerUser && !isEstimatorUser" class="btn_trush" @click="removeItemOnContacts(data)"></button>
                                </template>
                            </b-table>
                        </div>
                        <vue-context ref="menuContact">
                            <ul slot-scope="item">
                                <li @click="editContact(item.data.index,item.data.item)">Edit Contact</li>
                                <li @click="addContact">New Contact</li>
                                <li  v-if="!isCustomerUser && !isEstimatorUser" @click="removeItemOnContacts(item.data)">Delete Contact</li>
                            </ul>
                        </vue-context>
                    </div>
                    <div id="locations" class="tab-pane p-0" role="tabpanel">
                        <div class="overflow-x-xs">
                            <div class="table_head">
                                <p>Warehouse Locations</p>
                            </div>
                            <b-table
                                    class="rs-scroll rs-scroll--y"
                                    responsive
                                    hover
                                    :items="supplier.warehouses" :fields="locationsFields" :filter="locations.filter"
                                    @row-dblclicked="editLocationByDblClick"
                            >
                                <template v-slot:cell(name)="data">
                                    <div class="noselect" @contextmenu.prevent="openContextLocation($event, data.index, data.item)">{{data.item.name}}</div>
                                </template>
                                <template v-slot:cell(address)="data">
                                    <div class="noselect" @contextmenu.prevent="openContextLocation($event, data.index, data.item)">{{data.item.address}}</div>
                                </template>
                                <template v-slot:cell(phone)="data">
                                    <div class="noselect" @contextmenu.prevent="openContextLocation($event, data.index, data.item)">{{data.item.phone}}</div>
                                </template>
                                <template v-slot:cell(hours_open)="data">
                                    <div class="noselect" @contextmenu.prevent="openContextLocation($event, data.index, data.item)">{{data.item.hours_open}}</div>
                                </template>
                                <template v-slot:cell(hours_closing)="data">
                                    <div class="noselect" @contextmenu.prevent="openContextLocation($event, data.index, data.item)">{{data.item.hours_closing}}</div>
                                </template>
                                <template v-slot:cell(delivery)="data">
                                    <div class="noselect" @contextmenu.prevent="openContextLocation($event, data.index, data.item)">{{data.item.delivery}}</div>
                                </template>
                                <template v-slot:cell(action)="data">
                                    <span class="action-icon active" tooltip-position="top"><button class="btn_edit" @click="editLocation(data.index, data.item)"></button></span>
                                    <span  v-if="!isCustomerUser && !isEstimatorUser" class="action-icon active" tooltip-position="top"><button class="btn_trush" @click="removeItemOnLocations(data)"></button></span>
                                </template>
                            </b-table>
                        </div>
                        <vue-context ref="menuLocation">
                            <ul slot-scope="item">
                                <li @click="editLocation(item.data.index,item.data.item)">Edit Location</li>
                                <li @click="addLocation">New Location</li>
                                <li v-if="!isCustomerUser && !isEstimatorUser" @click="removeItemOnLocations(item.data)">Delete Location</li>
                            </ul>
                        </vue-context>
                    </div>
                </div>
            </div>
        </div>
        <modal-wizard class="ask_delete_modal" v-if="openModalRow === 'delete'" ref="openContactModalRef" finishButtonText="Yes" @on-complete="completeDeleteContact">
            <modal-wizard-tab title="Delete contact" title-icon-class="ti-trash">
                <p>You are about to delete {{deletedName}}?</p>
            </modal-wizard-tab>
        </modal-wizard>
        <modal-wizard v-else ref="openContactModalRef" :finishButtonText="openModalRow === 'edit' ? 'Save' : 'Add'" @on-complete="completeEditContact">
            <modal-wizard-tab :title="openModalRow === 'edit' ? 'Edit contact' : 'Add contact'" :title-icon-class="openModalRow === 'edit' ? 'ti-pencil' : 'ti-file'">
                <template slot-scope="supplier">
                    <form action="" class="row form-group edit_cell_wrapper">
                        <div class="col-xs-12">
                            <label for="label_name">Name</label>
                            <input type="text" id="label_name" v-model="contactModalInfo.name" class="form-control">
                        </div>
                        <div class="col-xs-12">
                            <label for="label_position">Position</label>
                            <input type="text" id="label_position" v-model="contactModalInfo.position" class="form-control">
                        </div>
                        <div class="col-xs-12">
                            <label for="label_department">Department</label>
                            <input type="text" id="label_department" v-model="contactModalInfo.department" class="form-control">
                        </div>
                        <div class="col-xs-12">
                            <label for="label_phone_o">Office Phone</label>
                            <input type="text" id="label_phone_o" v-model="contactModalInfo.office_phone" class="form-control">
                        </div>
                        <div class="col-xs-12">
                            <label for="label_phone_m">Mobile Phone</label>
                            <input type="text" id="label_phone_m" v-model="contactModalInfo.mobile_phone" class="form-control">
                        </div>
                        <div class="col-xs-12">
                            <label for="label_email">Email</label>
                            <email
                                :email="contactModalInfo.email"
                                @onChange="(value) => contactModalInfo.email = value"
                                @checked="(value) => isCheckedContactEmail = value"
                            ></email>
                        </div>
                        <div class="col-xs-12">
                            <label for="label_location">Location</label>
                            <AddressSuggestions type="text"
                                                :refsAddress="'modalContactForm'"
                                                :model.sync="contactModalInfo.location"
                                                id="label_address_loc"
                                                placeholder="Search for an Address"
                                                :options="addressOptions"
                            >
                            </AddressSuggestions>
                        </div>
                    </form>
                </template>
            </modal-wizard-tab>
        </modal-wizard>
        <modal-wizard class="ask_delete_modal" v-if="openModalRow === 'delete'" ref="openLocationModalRef" finishButtonText="Yes" @on-complete="completeDeleteLocation">
            <modal-wizard-tab title="Delete Warehouse" title-icon-class="ti-trash">
                <p>You are about to delete {{deletedName}}?</p>
            </modal-wizard-tab>
        </modal-wizard>
        <modal-wizard v-else ref="openLocationModalRef" :finishButtonText="openModalRow === 'edit' ? 'Save' : 'Add'" @on-complete="completeEditLocation">
            <modal-wizard-tab :title="openModalRow === 'edit' ? 'Edit location' : 'Add location'" :title-icon-class="openModalRow === 'edit' ? 'ti-pencil' : 'ti-file'">
                <template slot-scope="supplier">
                    <form action="" class="row form-group edit_cell_wrapper">
                        <div class="col-xs-12">
                            <label for="label_name_loc">Name</label>
                            <input type="text" id="label_name_loc" v-model="locationModalInfo.name" class="form-control">
                        </div>
                        <div class="col-xs-12">
                            <label for="label_address_loc">Address</label>
                            <google-location type="text" :refsAddress="'modalLocationForm'" v-model="locationModalInfo.address" class="form-control" id="label_location"
                                             placeholder="Search for an Address"
                            ></google-location>
                        </div>
                        <div class="col-xs-12">
                            <label for="label_phone_loc">Phone</label>
                            <input type="text" id="label_phone_loc" v-model="locationModalInfo.phone" class="form-control">
                        </div>
                        <div class="col-xs-12">
                            <label for="label_open_hour">Open Hours</label>
                            <input type="text" id="label_open_hour" v-model="locationModalInfo.hours_open" class="form-control">
                        </div>
                        <div class="col-xs-12">
                            <label for="label_choosing_hour">Closing Hour</label>
                            <input type="text" id="label_choosing_hour" v-model="locationModalInfo.hours_closing" class="form-control">
                        </div>
                        <div class="col-xs-12">
                            <label for="label_delivery">Delivery</label>
                            <input type="text" id="label_delivery" v-model="locationModalInfo.delivery" class="form-control">
                        </div>
                    </form>
                </template>
            </modal-wizard-tab>
        </modal-wizard>
    </div>
</template>
<style>

</style>
<style scoped>
    #contacts {
        padding: 15px;
    }

    #locations {
        padding: 15px;
    }

    .search-input {
        display: inline;
        margin-right: 15px;
    }
    .col-lg-9 >>> .multiselect__tags{
      height: 32px;
    }
    .card-block .rs-toggle__button{
        padding-left: 8px;
    }
    .ti-plus {
        margin-right: 10px;
    }

    .btn-primary {
        height: 38px;
        border: none;
        display: inline-flex;
        align-items: center;
        padding: 0px 13px;
        border-radius: 3px;
    }

    .btn-primary.btn-save {
        padding: 0px 28px;
    }

    .rs-btn-group.float-sm-right {
        padding-right: 15px;
    }

    .card-block {
        padding-left: 0px;
        padding-right: 0px;
    }

    .card-header.bg-navyblue {
        background-color: rgb(239, 238, 241) !important;
        border-radius: 5px 5px 0px 0px;
    }

    .btn-supply-delete {
        background: rgb(255, 94, 94);
        padding: 10px 20px;
        color: white;
        font-size: 14px;
        border-radius: 3px;
        border: none;
    }

    .btn-supply-delete:hover {
        cursor: pointer;
    }

    .V3 .suppliers-edit-page .card-block .form-group .address {
        border: none;
        padding-left: 7px;
        padding-top: 0px;
        padding-right: 7px;
        height: 31px;
    }

    .V3 .suppliers-edit-page .form-group #label_location {
        padding: 0;
        border: 0;
    }

    .V3 .suppliers-edit-page .form-group #label_location i.bx {
        padding-top: 11px;
    }

    .V3 .suppliers-edit-page .form-group #label_location i.bx + input {
        height: 100%;
    }

    .suplier-mobile-block {
        width: 100%;
    }
</style>
