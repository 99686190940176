<template>
  <div class="tl-content box box-block display-block"
       :class="{'tl-content-background': true}"
       style="background-color: #FAFAFA;">
    <span class="arrow left" style="border-color: #FAFAFA;"
          :class="{'tl-content-arrow-background': true}"

    ></span>
    <div class="table-and-actions">
      <table class="activity-table">
        <tr>
          <td>Reply To Mobile</td>
          <td>{{activity.action_details['Estimate Sent To']}}</td>
        </tr>

        <tr v-if="sendMsg == false">
          <td>Apply Reply</td>
          <td>
            <multiselect id="custom_default_rates_paint_material"
                         :options="templates"
                         label="name"
                         v-model="selectedTemplate"
                         track-by="id"
                         placeholder="Select Reply Template"
                         :showLabels="false"
                         style="opacity: 1; height: 32px"
                         @input="v => textMsg = v.text"
                         :option-height="31.5"
                         :close-on-select="true"
            >
            </multiselect>
          </td>
        </tr>
        <tr v-if="sendMsg == false">
          <td>Text</td>
          <td>
            <textarea
                class="terms-editor"
                v-model="textMsg"
            >
            </textarea>
          </td>
        </tr>

        <tr v-if="sendMsg">
          <td>Text</td>
          <td>
            {{ textMsg }}
          </td>
        </tr>

        <tr class="tl-date text-muted mt-1">
          <td>Date</td>
          <td>
<!--            <span>{{ getDate() }}</span>-->
            <span v-html="activity.entry_date.replaceAll('-', '/')"></span> - <span v-html="activity.entry_time"></span>
            <button v-if="sendMsg == false" @click="send" class="btn btn-primary btn-send">Send</button>
          </td>
        </tr>
      </table>
      <div class="navbar-nav nav dropdown dropdown-options show float-sm-right text-right additional-action-menu-style" style="margin-right:1.25rem; position: absolute; right: 7px">
                 <span class="btn ml-0-25 waves-effect waves-light ex-options-button additional-action-menu-style"
                       style="margin-top: -10px; padding-left: 10px !important;"
                       :data-toggle="'dropdown'"
                       aria-haspopup="true" aria-expanded="true">Actions
                    <span class="dropdown-arrow  pull-right" style="box-sizing: border-box; width: 30px; border-left: 1px solid rgba(27, 30, 56, 0.25);"><i
                        class="fa fa-chevron-down pull-right-action"></i></span>
                 </span>
        <div class="dropdown-menu dropdown-menu-right animated fadeInUp drop-down-menu-actions">
          <div class="dropdownLinks-actions-links">
            <a class="dropdown-item text-primary clickable dropdown-action-item" @click="$emit('delete')">Delete Entry</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {defineComponent} from "vue";
import Multiselect from "vue-multiselect";
import {background} from "@amaury-tobias/v-avatar/src/utils";
import dayjs from "dayjs";
import Axios from "axios";

export default defineComponent({
  name: 'activity-list-sms',
  props: {
    activity: {
      type: Object,
      default() {
        return {}
      }
    },
    templates: {
      type: Array,
      default() {
        return []
      }
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      textMsg: '',
      sendMsg: false,
      selectedTemplate: null,
    }
  },
  methods:{
    background,
    send() {
      this.sendMsg = true
      Axios.post(`/fe/email/customer/${this.activity.estimate_id}`, {
        customers: [{
          additional_message: this.textMsg,
          sms_check: true,
          customer_name: this.activity.createdBy
        }],
        images: [],
        sendSms: true,
        smsAdditionalMessage: this.textMsg,
        reply: true
      }).then((r) => {
        if (r.status == 200) {
          this.$emit('close')
        }
      })
    },
    getDate() {
      return dayjs(new Date()).format('DD/MM/YYYY HH:mm:ss')
    }
  },
})
</script>


<style scoped>
.tl-content-arrow-background:after{
  background-color: #f4f4ec !important;
}
.tl-content-background{
  background-color: #f4f4ec !important;
}
.additional-action-menu-style-disabled{
  border-color: #d7d7d7 !important;
  color: #7f7f7f !important;
  cursor: not-allowed;
}
.dropdown .dropdownLinks-links .dropdown-action-item {
  display: inline-block;
  position: relative;
  width: 100%;
  min-height: 38px;
  padding: 10px 14px 8px 14px;
  clear: both;
  color: #1C1F39;
  text-align: inherit;
  white-space: normal;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  background: 0 0;
  border: 0;
  cursor: pointer;
}

.dropdown .dropdownLinks-links .dropdown-action-item:hover {
  background-color: rgba(94, 121, 255, 0.1);
  color: #5E79FF;
  font-weight: bold;
}

.dropdownLinks-actions-links {
  width: 115px;
}

.drop-down-menu-actions {
  width: 115px !important;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgb(27 30 56 / 15%);
  min-width: 115px !important;
  margin: -13px -14px 0 !important;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  z-index: 110;
}

.dropdown-action-item {
  text-align: center !important;
  min-height: 20px !important;
}

.pull-right-action {
  padding-right: 3px !important;
  margin-left: 10px;
}
.terms-editor{
  height: 120px;
  background-color: rgb(255, 255, 255);
}
.btn-send{
  height: 30px;
  float: right;
  position: relative;
  bottom: 5px;
  padding: 0 15px;
  margin-left: 30px;
}
</style>
