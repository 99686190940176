<template>
  <div style="transform: translateY(-10px)">
    <div class="tabs-component tabs-component-2" style="padding-top: 0px">
      <div class="tabs-component-w">
        <ul class="nav nav-tabs nav-tabs-2  float-sm-left">
          <li style='padding-top: 0px'  class="tabs-component-tab nav-item">
            <span href="" class="tabs-component-tab-a nav-link" @click="tabActive = 1" :class="{'active': tabActive == 1}">Send To Customer</span>
          </li>
          <li style='padding-top: 0px'  class="tabs-component-tab nav-item">
            <span href="" class="tabs-component-tab-a nav-link" @click="tabActive = 2" :class="{'active': tabActive == 2}">Reply To</span>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="tabActive == 1"  style="margin-top: 15px; padding-left: 15px" class="card row no-gutter">
      <div class="col-lg-6 col-12 ">
        <div class="card-header bg-navyblue">
          <strong>SMS Management</strong>
        </div>
        <p class="description-header"> Set the text that you would like to appear in each SMS when sending your customer a copy of the estimate from the
          <span style="font-weight: bold">Options \ Send to Customer</span> button.</p>
        <div class="card-block bg-white">

          <div class="form-group row no-gutter rs-toggle">
            <label class="col-sm-5 col-form-label">
              SMS Services
              <p class="description-text">Enables the SMS service on your account. Charges apply for sending out messages and receiving messages.
                <br><br> Billing is monthly and charged per message outgoing & incoming.</p>
            </label>
            <div class="col-sm-5">
              <div class="d-flex" style="padding-top: 20px; padding-left: 20px">
                <toggle-button
                    v-model="value.sms.sendToCustomer.smsServices"
                    class="mb-0 rs-toggle__button"
                    color="#5E79FF"
                    :disabled="!isStatusActive || !(value.billing.settings.sms_messaging || value.billing.settings.sms_messaging_incoming)"
                    :sync="true"
                    :width="40"
                    :labels="false"/>
                <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25">
                  <template v-if="value.sms.sendToCustomer.smsServices">Active</template>
                  <template v-else>Not Active</template>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-12" style="padding-left: 13px;">
        <div class="card-header bg-navyblue">
          <strong>Templates</strong>
        </div>
        <div style="border-bottom: 2px solid rgba(27, 30, 56, .25); padding-bottom: 20px;">
          <p class="description-header ">
            <span style="font-weight: bold; position: relative; top: 8px">Template List</span>
            <button class="btn btn-primary float-right" @click="onCLickAddTemplate('message')">+ Add SMS Template</button>
          </p>
        </div>
        <div class="card-block bg-white" v-for="(template, ind) in computedSmsTemplates" :key="ind">
          <div class="form-group row no-gutter">
            <label class="col-sm-6 col-form-label">
              SMS Name
            </label>
            <div class="col-sm-6">
              <input v-model="template.name" @change="updateTemplate(template)" type="text" class="form-control" style="width: 250px;" placeholder="Send Estimate">
              <p v-if="!template.name.length" class="limit-text error-text" style="margin-bottom: 0px">Name must not be empty</p>
            </div>
          </div>

          <div class="form-group row no-gutter" style="border-bottom: 2px solid rgba(27, 30, 56, .25); padding-bottom: 15px">
            <label class="col-sm-6 col-form-label">
              SMS Message Text
              <p class="description-text">Add your standard message. <br> Every text will be sent with a <br> link for your customer to view
                <br> the estimate online.</p>
            </label>
            <div class="col-sm-6">
              <textarea style="height: 150px; width: 250px;" :readonly="!isStatusActive" v-model="template.text" @change="updateTemplate(template)" class="form-control"></textarea>
              <p v-if="!template.text.length" class="limit-text error-text" style="margin-bottom: 0px">Text must not be empty</p>
              <p class="limit-text" :class="{'error-text': template.text.length > value.maxSmsTextLength }">Limit of {{value.maxSmsTextLength}} characters</p>
            </div>
            <button @click="removeTemplate(template)" class="delete-user float-right">Delete</button>
          </div>


        </div>
      </div>
    </div>
    <div v-if="tabActive == 2"  style="margin-top: 15px; padding-left: 15px" class="card row no-gutter">
      <div class="col-lg-6 col-12 ">
        <div class="card-header bg-navyblue">
          <strong>Reply Messaging</strong>
        </div>
        <p class="description-header">Reply to your coustomers SMS all in one place. Create and apply standart response just like you would form a mobile phone.</p>
        <div class="card-block bg-white">

          <div class="form-group row no-gutter rs-toggle">
            <label class="col-sm-5 col-form-label">
              SMS Reply
              <p class="description-text">Enables the SMS reply on your account. Charges apply for sending out messages and receiving messages.
                <br><br> Billing is monthly and charged per message incoming.</p>
            </label>
            <div class="col-sm-5">
              <div class="d-flex" style="padding-top: 20px; padding-left: 20px">
                <toggle-button
                    v-model="value.sms.sendToCustomer.smsReply"
                    class="mb-0 rs-toggle__button"
                    color="#5E79FF"
                    :disabled="!isStatusActive || !value.billing.settings.sms_messaging"
                    :sync="true"
                    :width="40"
                    :labels="false"/>
                <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25">
                  <template v-if="value.sms.sendToCustomer.smsReply">Active</template>
                  <template v-else>Not Active</template>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-12" style="padding-left: 13px;">
        <div class="card-header bg-navyblue">
          <strong>Templates</strong>
        </div>
        <div style="border-bottom: 2px solid rgba(27, 30, 56, .25); padding-bottom: 20px;">
          <p class="description-header ">
            <span style="font-weight: bold; position: relative; top: 8px">Template List</span>
            <button class="btn btn-primary float-right"  @click="onCLickAddTemplate('reply')">+ Add SMS Reply Template</button>
          </p>
        </div>
        <div class="card-block bg-white" v-for="(template) in computedReplyTemplates" :key="ind">
          <div class="form-group row no-gutter">
            <label class="col-sm-6 col-form-label">
              Reply Template Name
            </label>
            <div class="col-sm-6">
              <input v-model="template.name" @change="updateTemplate(template)" type="text" class="form-control" style="width: 250px;" placeholder="Send Estimate">
              <p v-if="!template.name.length" class="limit-text error-text" style="margin-bottom: 0px">Name must not be empty</p>
            </div>
          </div>

          <div class="form-group row no-gutter" style="border-bottom: 2px solid rgba(27, 30, 56, .25); padding-bottom: 15px">
            <label class="col-sm-6 col-form-label">
              SMS Reply Message Text
              <p class="description-text">Add your standard message. <br> Every text will be sent with a <br> link for your customer to view
                <br> the estimate online.</p>
            </label>
            <div class="col-sm-6">
              <textarea style="height: 150px; width: 250px;" :readonly="!isStatusActive" v-model="template.text" @change="updateTemplate(template, ind)" class="form-control"></textarea>
              <p v-if="!template.text.length" class="limit-text error-text" style="margin-bottom: 0px">Text must not be empty</p>
              <p class="limit-text" :class="{'error-text': template.text.length > value.maxSmsTextLength }">Limit of {{value.maxSmsTextLength}} characters</p>
            </div>
            <button @click="removeTemplate(template)" class="delete-user float-right">Delete</button>
          </div>


        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Axios from 'axios';
import {appConfig} from '../../config'
import XeroIntegration from './integrations/Xero'
import {mapGetters} from "vuex";

export default {
  name: "Sms",
  props: {
    value: {}
  },
  data() {
    return{
      tabActive: 1,
      smsTemplates: [],
      replyTemplates: [],
    }
  },
  computed: {
    ...mapGetters({
      isStatusActive: 'isStatusActive',
    }),
    computedSmsTemplates() {
      return _.orderBy(this.smsTemplates, [(itm) => { return itm.id ? Number(itm.id) : 9999999999999}], ['desc'])
    },
    computedReplyTemplates() {
      return _.orderBy(this.replyTemplates, [(itm) => { return itm.id ? Number(itm.id) : 9999999999999}], ['desc'])
    },
  },
  mounted() {
    this.loadTemplates()
  },
  methods: {
    loadTemplates() {
      Axios.get('/fe/sms-templates').then((r) => {
        if (r.data._status && r.data.templates) {
          this.smsTemplates = _.filter(r.data.templates, (t) => { return t.type == 'message'})
          this.replyTemplates = _.filter(r.data.templates, (t) => { return t.type == 'reply'})
        }
      })
    },
    onCLickAddTemplate(type) {
      if (type == 'message') {
        let notSavedTemplate = _.find(this.smsTemplates, (t) => {
          return !t.id
        })
        if (notSavedTemplate) {
          return
        }
        this.smsTemplates.push({
          id: null,
          name: '',
          text: '',
          type: 'message',
        })
      } else {
        let notSavedTemplate = _.find(this.replyTemplates, (t) => {
          return !t.id
        })
        if (notSavedTemplate) {
          return
        }
        this.replyTemplates.push({
          id: null,
          name: '',
          text: '',
          type: 'reply',
        })
      }
    },
    addTemplate(template) {
      Axios.post('/fe/sms-templates/add', {
        type: template.type,
        name: template.name,
        text: template.text,
      }).then((r) => {
        if (r.data._status && r.data.template) {
          if (template.type == 'message') {
            let ind = _.findIndex(this.smsTemplates, (t) => {
              return !t.id
            })
            this.smsTemplates.splice(ind, 1, r.data.template)
          } else {
            let ind = _.findIndex(this.replyTemplates, (t) => {
              return !t.id
            })
            this.replyTemplates.splice(ind, 1, r.data.template)
          }
        }
      })
    },
    updateTemplate(template) {
      if (!template.name.length || !template.text.length) {
        return
      }
      if (!template.id) {
        this.addTemplate(template)
        return
      }
      Axios.post('/fe/sms-templates/' + template.id, {
        name: template.name,
        text: template.text,
      }).then((r) => {})
    },
    removeTemplate(template) {
      if (!template.id) {
        if (template.type == 'message') {
          let ind = _.findIndex(this.smsTemplates, (t) => {
            return !t.id
          })
          this.smsTemplates.splice(ind, 1)
        } else {
          let ind = _.findIndex(this.replyTemplates, (t) => {
            return !t.id
          })
          this.replyTemplates.splice(ind, 1)
        }
        return
      }

      Axios.post('/fe/sms-templates/delete', {
        id: template.id
      }).then((r) => {
        if (r.data._status) {
          if (template.type == 'message') {
            let index = _.findIndex(this.smsTemplates, (t) => {
              return Number(t.id) == Number(template.id)
            })
            if (index !== -1) {
              this.smsTemplates.splice(index, 1)
            }
          } else {
            let index = _.findIndex(this.replyTemplates, (t) => {
              return Number(t.id) == Number(template.id)
            })
            if (index !== -1) {
              this.replyTemplates.splice(index, 1)
            }
          }
        }
      })
    },
  }
};
</script>

<style scoped>
.delete-user {
  height: 31px;
  width: 101px;
  border: 1px solid #FF5E5E !important;
  border-radius: 3px !important;
  color: #FF5E5E;
  font-weight: 700;
}
.limit-text {
  color: #A2ABAE;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
}

.error-text {
  color: red;
}

.tab-content .active {
  width: 100%;
}

.tabs-component-2::after {
  content: '';
  width: 100%;
  display: block;
  background: #e3e4e7;
  height: 1px;
  clear: both;
}

.nav-item:hover {
  cursor: pointer;
}

.description-text {
  padding-top: 10px;
  color: rgba(0, 0, 0, 0.5);
}
.description-header {
  padding: 15px 15px 0px 15px;
}
</style>
