<template>
  <div class="qr-scanner" :class="{'full-scanner': userInfo.scanMode == 'full'}">
    <div class="">
      <div class="close-web-camera" @click="close">
        <i  class='bx bx-x'></i>
      </div>
      <div id="reader" class="reader"></div>
      <div class="qr-box"></div>
    </div>
  </div>
</template>

<script>
import { Html5Qrcode } from "html5-qrcode";
import Axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "QrScanner",
  data(){
    return {
      html5QrCode:null,
    }
  },
  mounted() {
    this.startScanner();

    screen.orientation.addEventListener("change", this.orientantionChange);
  },
  beforeDestroy() {
    if (this.html5QrCode && this.html5QrCode.isScanning) {
      this.html5QrCode.stop().catch(err => {
        console.error("Failed to stop the scanner:", err);
      });
    }
    screen.orientation.removeEventListener("change", this.orientantionChange);
  },
  methods: {
    orientantionChange (event) {
      if (!this.$fullscreen.isFullscreen) {
        this.$fullscreen.toggle(document.body, {
          wrap: false,
        });
      }

      if (this.html5QrCode && this.html5QrCode.isScanning) {
        this.html5QrCode.stop().catch(err => {
          console.error("Failed to stop the scanner:", err);
        });
      }

      this.startScanner();
    },
    close(){
      //this.html5QrCode?.stop()
      console.log('CLOSE1')
      this.$emit('close')
    },
    saveError(type, error) {
      // Axios.get('/test/qr/errorMessage?' + type + error )
    },
    startScanner() {
      const html5QrCode = new Html5Qrcode("reader");

      html5QrCode.start(
          { facingMode: "environment" },
          {
            fps: 10,
            qrbox: { width: 250, height: 250 },
            disableFlip: true
          },
          (decodedText, decodedResult) => {
            let audio = document.getElementById('scannerAudio');

            if (audio) {
              audio.play().catch(e => {
                this.saveError('audio-', _.toString(e))
                console.log(e)
              })
            }
            this.$emit('scanned', decodedText)
          },
          (errorMessage) => {
            // this.saveError('qrCodeErrorCallback-', _.toString(errorMessage))
          }
      ).catch(err => {
        this.saveError('startError-', _.toString(err))
      });

      this.html5QrCode = html5QrCode;
    },
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo'
    })
  }
};
</script>

<style>
.qr-scanner video {
  object-fit: cover;
  min-height: 290px;
}

.full-scanner {
  background-color: transparent !important;
  position: relative;
}
.full-scanner video {
  object-fit: cover !important;
  min-height: 100vh !important;
}
.full-scanner .reader {
  width: 100%;
  height: 100%;
}
.full-scanner .close-web-camera {
  background-color: #fdfdfd !important;
}


@media screen and (orientation: landscape) {
  .qr-scanner {
    width: 100vh !important;
  }
  .wrapper-modal-scan-active {
    display: flex;
    justify-content: center;
    background-color: white !important;
  }

  .full-scanner {
    width: 100vw !important;
  }
}
.body-scanner .site-content {
  opacity: 0 !important;
}
</style>
<style scoped>
.qr-scanner {
  /*position: relative;*/
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: white;
}

.reader {
  /*width: 100%;*/
  /*height: 100%;*/
}

.qr-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250px;
  height: 250px;
  border: 5px solid #5e79ff;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.close-web-camera{
  position: absolute;
  font-size: 34px;
  left: 30px;
  top: 15px;
  text-align: center;
  padding-top: 5px;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: rgba(27, 30, 56, 0.1);
  z-index: 9999;
}
</style>
