<script>
    import Axios from 'axios';
    import FileUploader from './utility/file-uploader';
    import GoogleLocation from './utility/google-location';
    import {email} from 'vuelidate/lib/validators';

    export default {
        name: 'business',
        data: function () {
            return {
                logo_image: null,
                same_as_postal: false,
            };
        },
        validations: {
            $store: {
                state: {
                    vendorInfo: {
                        email: {
                            email,
                        },
                    }
                }
            },
        },
        methods: {
            validVendorInfoEmail: function (email) {
                this.$store.state.vendorInfo.email = email;
                this.$v.$store.state.vendorInfo.email.$touch();
            },
            updateInfo: function () {
                if (this.$v.$anyError) {
                    toastr.error('Your changes could not be saved, please check the form fields again');
                    return;
                }
                NProgress.start();
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: '/fe/vendor/info',
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: JSON.stringify({data: this.updatedVendorInfo, logo_image: this.logo_image})
                })
                // Axios.post('/fe/vendor/info', JSON.stringify({data: this.updatedVendorInfo, logo_image: this.logo_image}))
                    .then(response => {
                        NProgress.done();
                        if (response.status == 200) {
                            toastr.success(response.data.msg);
                            this.$store.dispatch('loadVendorInfo');
                        } else {
                            toastr.error(response.data.msg);
                        }
                    })
                    .catch(error => {
                        NProgress.done();
                        toastr.error(error);
                    });
            },
            addressPostalChanged: function (addr) {
                this.vendorInfo.postal_address = addr["address"];
                this.vendorInfo.postal_level_unit_lot = addr['levelUnitLot'];
                this.vendorInfo.postal_street = addr["street_name"];
                this.vendorInfo.postal_suburb = addr["suburb"];
                this.vendorInfo.postal_state = addr["state"];
                this.vendorInfo.postal_postcode = addr["postcode"];
                this.vendorInfo.postal_country = addr["country"];
            },
            addressPhysicalChanged: function (addr) {
                this.vendorInfo.physical_address = addr["address"];
                this.vendorInfo.physical_level_unit_lot = addr['levelUnitLot'];
                this.vendorInfo.physical_street = addr["street_name"];
                this.vendorInfo.physical_suburb = addr["suburb"];
                this.vendorInfo.physical_state = addr["state"];
                this.vendorInfo.physical_postcode = addr["postcode"];
                this.vendorInfo.physical_country = addr["country"];
            }
        },
        computed: {
            vendorInfo: function () {
                return this.$store.state.vendorInfo;
            },
            businessTypes: function () {
                return this.$store.state.businessTypes;
            },
            updatedVendorInfo: function () {
                return {
                    vendor_id: this.vendorInfo.vendor_id,
                    display_name: this.vendorInfo.display_name,
                    business_name: this.vendorInfo.business_name,
                    abn: this.vendorInfo.abn,
                    phone: this.vendorInfo.phone,
                    phone_area_code: this.vendorInfo.phone_area_code,
                    email: this.vendorInfo.email,
                    location: this.vendorInfo.location,
                    business_type_id: this.vendorInfo.business_type_id,
                    license_number: this.vendorInfo.license_number,
                    branch: this.vendorInfo.branch,
                    org_description: this.vendorInfo.org_description,

                    postal_address: this.vendorInfo.postal_address,
                    postal_level_unit_lot: this.vendorInfo.postal_level_unit_lot,
                    postal_street: this.vendorInfo.postal_street,
                    postal_suburb: this.vendorInfo.postal_suburb,
                    postal_state: this.vendorInfo.postal_state,
                    postal_postcode: this.vendorInfo.postal_postcode,
                    postal_country: this.vendorInfo.postal_country,

                    physical_address: this.vendorInfo.physical_address,
                    physical_level_unit_lot: this.vendorInfo.physical_level_unit_lot,
                    physical_street: this.vendorInfo.physical_street,
                    physical_suburb: this.vendorInfo.physical_suburb,
                    physical_state: this.vendorInfo.physical_state,
                    physical_postcode: this.vendorInfo.physical_postcode,
                    physical_country: this.vendorInfo.physical_country,

                    website: this.vendorInfo.website,
                    logo_picture: this.vendorInfo.logo_picture,
                };
            },
            drOptions: function () {
                return {
                    defaultFile: this.vendorInfo.logo_picture ? '/img/brands/' + this.vendorInfo.logo_picture : '',
                    messages: {
                        'default': 'Drag and drop an image here or click',
                        'replace': 'Drag and drop or click to add new image',
                        'remove': 'Remove',
                        'error': 'Ooops, something went wrong.'
                    }
                };
            }
        },
        mounted: function () {
            this.$store.dispatch('loadVendorInfo');
            this.$store.dispatch('loadBusinessTypes');
            this.drOptions.defaultFile = this.updatedVendorInfo.logo_picture;
        },
        watch: {
            same_as_postal: function () {
                if (this.same_as_postal) {
                    this.updatedVendorInfo.physical_address = this.updatedVendorInfo.postal_address;
                    this.updatedVendorInfo.physical_level_unit_lot = this.updatedVendorInfo.postal_level_unit_lot;
                    this.updatedVendorInfo.physical_street = this.updatedVendorInfo.postal_street;
                    this.updatedVendorInfo.physical_suburb = this.updatedVendorInfo.postal_suburb;
                    this.updatedVendorInfo.physical_state = this.updatedVendorInfo.postal_state;
                    this.updatedVendorInfo.physical_postcode = this.updatedVendorInfo.postal_postcode;
                    this.updatedVendorInfo.physical_country = this.updatedVendorInfo.postal_country;
                }
            }
        },
        components: {
            FileUploader,
            GoogleLocation
        }
    };
</script>

<template>
    <div>
        <div class="page-header">
        <h4>Business</h4>
        <ol class="breadcrumbs">
            <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
            <li class="breadcrumb-item active">Business</li>
        </ol>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-block bg-white">
                    <div class="card-block card bg-white mb-0">
                        <div class="row">
                            <div class="col-md-3 float-md-right mb-1">
                                <file-uploader v-if="vendorInfo.logo_picture" :options="drOptions" v-model="logo_image" accept="image/*"></file-uploader>
                                <p class="font-90 text-muted mb-20 text-center">Click on the above image to replace it with a new <strong><em>logo</em></strong>.</p>
                            </div>

                            <div class="col-md-9 float-md-left">
                                <h5 class="mb-1">Basic Information</h5>

                                <div class="form-group row">
                                    <label for="display_name" class="col-sm-3 col-form-label">Display Name</label>
                                    <div class="col-sm-9">
                                        <input type="text" v-model="updatedVendorInfo.display_name" class="form-control" id="display_name" placeholder="Display Name">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="business_name" class="col-sm-3 col-form-label">Legal/Trading Name</label>
                                    <div class="col-sm-9">
                                        <input type="text" v-model="updatedVendorInfo.business_name" class="form-control" id="business_name" placeholder="Business Name">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="business_type" class="col-sm-3 col-form-label">Business Type</label>
                                    <div class="col-sm-9">
                                        <!--<input type="text" class="form-control" id="business_type" placeholder="Display Name">-->
                                        <select class="form-control" v-model="updatedVendorInfo.business_type_id" id="business_type">
                                            <option>Select your business type</option>
                                            <option v-for="bt in businessTypes" :value="bt.business_type_id">{{bt.type_name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="license_number" class="col-sm-3 col-form-label">Repairer License Number</label>
                                    <div class="col-sm-9">
                                        <input type="text" v-model="updatedVendorInfo.license_number" class="form-control" id="license_number" placeholder="Repairer License Number">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="abn" class="col-sm-3 col-form-label">Australian Business Number (ABN)</label>
                                    <div class="col-sm-9">
                                        <input type="text" v-model="updatedVendorInfo.abn" class="form-control" id="abn" placeholder="ABN Number">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="branch" class="col-sm-3 col-form-label">Branch (If Applicable)</label>
                                    <div class="col-sm-9">
                                        <input type="text" v-model="updatedVendorInfo.branch" class="form-control" id="branch" placeholder="Branch">
                                    </div>
                                </div>
                                <div class="form-group row mb-3">
                                    <label for="org_description" class="col-sm-3 col-form-label">Organisation Description</label>
                                    <div class="col-sm-9">
                                        <input type="text" v-model="updatedVendorInfo.org_description" class="form-control" id="org_description" placeholder="Organisation Description">
                                    </div>
                                </div>

                                <h5 class="mb-1">Contact Details</h5>
                                <div class="form-group row">
                                    <label for="postal_address" class="col-sm-3 col-form-label"><strong>Postal Address</strong></label>
                                    <div class="col-sm-9">
                                        <google-location type="text" v-model="updatedVendorInfo.postal_address" class="form-control" id="postal_address" placeholder="Search Postal Address"
                                                         @onPlaceChange="addressPostalChanged"></google-location>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="postal_level_unit_lot" class="col-sm-3 col-form-label">Level/Unit/Lot Nbr</label>
                                    <div class="col-sm-9">
                                        <input type="text" v-model="updatedVendorInfo.postal_level_unit_lot" class="form-control" id="postal_level_unit_lot" placeholder="Lot, Unit, or Level number">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="postal_street" class="col-sm-3 col-form-label">Street Nbr/Name</label>
                                    <div class="col-sm-9">
                                        <input type="text" v-model="updatedVendorInfo.postal_street" class="form-control" id="postal_street" placeholder="Street Number or Name">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="postal_suburb" class="col-sm-3 col-form-label">Suburb</label>
                                    <div class="col-sm-9">
                                        <input type="text" v-model="updatedVendorInfo.postal_suburb" class="form-control" id="postal_suburb" placeholder="Suburb">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="postal_state" class="col-sm-3 col-form-label">State</label>
                                    <div class="col-sm-9">
                                        <!--<input type="text" class="form-control" id="state" placeholder="State">-->
                                        <select v-model="updatedVendorInfo.postal_state" class="form-control" id="postal_state">
                                            <option value="">Select your state</option>
                                            <option value="QLD">Queensland</option>
                                            <option value="NT">Northern Territory</option>
                                            <option value="NSW">New South Wales</option>
                                            <option value="VIC">Victoria</option>
                                            <option value="TAS">Tasmania</option>
                                            <option value="SA">South Australia</option>
                                            <option value="WA">Western Australia</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row mb-2">
                                    <label for="postal_postcode" class="col-sm-3 col-form-label">Postcode</label>
                                    <div class="col-sm-9">
                                        <input type="text" v-model="updatedVendorInfo.postal_postcode" class="form-control" id="postal_postcode" placeholder="Postcode">
                                    </div>
                                </div>
                                <div class="form-group row mb-2">
                                    <label for="postal_country" class="col-sm-3 col-form-label">Country</label>
                                    <div class="col-sm-9">
                                        <select v-model="updatedVendorInfo.postal_country" class="form-control" id="postal_country" placeholder="Country">
                                            <option value="">Select your country</option>
                                            <option value="AU">Australia</option>
                                            <option value="NZ">New Zealand</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="postal_address" class="col-sm-3 col-form-label"><strong>Business Address</strong></label>
                                    <div class="col-sm-9">
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input v-model="same_as_postal" class="form-check-input" type="checkbox"> Same as postal address
                                            </label>
                                        </div>
                                        <google-location type="text" v-model="updatedVendorInfo.physical_address" class="form-control" id="physical_address" placeholder="Search Business Address"
                                                         @onPlaceChange="addressPhysicalChanged"></google-location>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="physical_level_unit_lot" class="col-sm-3 col-form-label">Level/Unit/Lot Nbr</label>
                                    <div class="col-sm-9">
                                        <input type="text" v-model="updatedVendorInfo.physical_level_unit_lot" class="form-control" id="physical_level_unit_lot"
                                               placeholder="Lot, Unit, or Level number">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="physical_street" class="col-sm-3 col-form-label">Street Nbr/Name</label>
                                    <div class="col-sm-9">
                                        <input type="text" v-model="updatedVendorInfo.physical_street" class="form-control" id="physical_street" placeholder="Street Number or Name">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="physical_suburb" class="col-sm-3 col-form-label">Suburb</label>
                                    <div class="col-sm-9">
                                        <input type="text" v-model="updatedVendorInfo.physical_suburb" class="form-control" id="physical_suburb" placeholder="Suburb">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="physical_state" class="col-sm-3 col-form-label">State</label>
                                    <div class="col-sm-9">
                                        <!--<input type="text" class="form-control" id="state" placeholder="State">-->
                                        <select v-model="updatedVendorInfo.physical_state" class="form-control" id="physical_state">
                                            <option value="">Select your state</option>
                                            <option value="QLD">Queensland</option>
                                            <option value="NT">Northern Territory</option>
                                            <option value="NSW">New South Wales</option>
                                            <option value="VIC">Victoria</option>
                                            <option value="TAS">Tasmania</option>
                                            <option value="SA">South Australia</option>
                                            <option value="WA">Western Australia</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row mb-2">
                                    <label for="physical_postcode" class="col-sm-3 col-form-label">Postcode</label>
                                    <div class="col-sm-9">
                                        <input type="text" v-model="updatedVendorInfo.physical_postcode" class="form-control" id="physical_postcode" placeholder="Postcode">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="physical_country" class="col-sm-3 col-form-label">Country</label>
                                    <div class="col-sm-9">
                                        <select v-model="updatedVendorInfo.physical_country" class="form-control" id="physical_country" placeholder="Country">
                                            <option value="">Select your country</option>
                                            <option value="AU">Australia</option>
                                            <option value="NZ">New Zealand</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="vendor_email" class="col-sm-3 col-form-label">Business Email</label>
                                    <div class="col-sm-9">
                                        <input type="text" :value="updatedVendorInfo.email" @change="validVendorInfoEmail($event.target.value)" class="form-control" id="vendor_email"
                                               placeholder="Email">
                                        <small class="form-text show-help-for-body" v-if="!$v.$store.state.vendorInfo.email.email">
                                            Please enter a valid email address
                                        </small>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="vendor_phone" class="col-sm-3 col-form-label">Phone</label>
                                    <div class="col-sm-9">
                                        <div class="form-group row mb-0">
                                            <div class="col-xs-2"><input type="text" v-model="updatedVendorInfo.phone_area_code" class="form-control" id="area_code" placeholder="Area Code"></div>
                                            <div class="col-xs-10"><input type="text" v-model="updatedVendorInfo.phone" class="form-control" id="vendor_phone" placeholder="Number"></div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="form-group row">
                                    <label for="vendor_location" class="col-sm-3 col-form-label">Location</label>
                                    <div class="col-sm-9">
                                        <input type="text" v-model="updatedVendorInfo.vendor_location" class="form-control" id="vendor_location" placeholder="Location">
                                    </div>
                                </div> -->
                                <div class="form-group row">
                                    <label for="website" class="col-sm-3 col-form-label">Website</label>
                                    <div class="col-sm-9">
                                        <input type="text" v-model="updatedVendorInfo.website" class="form-control" id="website" placeholder="Website">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-12">
                                        <button type="button" @click="updateInfo()" class="btn btn-primary w-min-sm mb-0-25 waves-effect waves-light pull-right w-min-lg">Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
