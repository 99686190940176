<template>
  <div class="wrapper-modal-scan" :class="{'wrapper-modal-scan-active': isShowScanner}">
    <div v-if="!isShowScanner" class="modal-scan-window">
      <div v-if="firstMenuLevel" class="modal-scan-window-title">Select Action</div>
      <div v-if="!firstMenuLevel" class="modal-scan-window-title">Set Vch Location</div>
      <span class="close-modal"><i class='bx bx-x' @click="$emit('closeModalScan')"></i></span>
      <div v-if="firstMenuLevel" class="modal-scan-window-btn">
        <div>
          <button @click="selectedOption = 'open'; isShowScanner = true; toggleFullscreen(true)">Open a Job Card</button>
        </div>
        <div>
          <button @click="selectedOption = 'start'; isShowScanner = true; toggleFullscreen(true)">Start a Job</button>
        </div>
        <div>
          <button>End a Job</button>
        </div>
        <div>
          <button @click="setVehicleLoc">Set Vehicle Loc</button>
        </div>
      </div>
      <div v-if="!firstMenuLevel" class="modal-scan-window-btn">
        <div>
          <div class="page-header-nav-options">
            <div class="navbar-nav nav dropdown dropdown-options  show inline" style="width: 100% !important; height: auto">
              <button @click="saveLocation" style="margin-bottom: 40px">Save</button>
              <button type="button"
                      style="width: 100% !important;"
                      class="btn waves-effect waves-light ex-options-button loc-btn"
                      id="dropdownMenuLink1" data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true">
                <span v-if="selectedLocation">{{selectedLocation.name}}</span>
                <span v-else style="font-size: 29px">Pick Loc</span>
                <span class="dropdown-arrow pull-right" style="width: auto !important;">
                  <i class="fa fa-chevron-down pull-right" style="position: relative; bottom: 5px"></i>
                </span>
              </button>
              <div class="dropdown-menu dropdown-menu-right animated fadeInUp" aria-labelledby="dropdownMenuLink1">
                <a
                    v-for="loc in locations"
                    :key="loc.id"
                    class="dropdown-item"
                    :class="{'active': selectedLocation == loc}"
                    @click="selectedLoc(loc)">{{loc.name}}</a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <button>Drop Pin Loc</button>
          <div id="map"></div>
        </div>
      </div>
    </div>
    <qr-scanner v-else @scanned="onScanned" @close="closeQrScanner"></qr-scanner>
  </div>
</template>

<script>
import QrScanner from '../../qr-scanner'
import Axios from "axios";
import {mapGetters} from "vuex";
import _ from "lodash"
export default {
  name: 'modal-scan',
  data() {
    return{
      firstMenuLevel: true,
      isShowScanner: false,
      selectedOption: '',
      selectedLocation: null,
      locations: [],
      markers: [],
      linkMap: null,
      map: null,
      cardNumber: null,
      cardId: null,
      accessKey: null,
    }
  },
  methods: {
    saveLocation() {
      if (_.isEmpty(this.selectedLocation) && _.isEmpty(this.linkMap)) {
        toastr.error("Please select location");
        return
      }

      this.$emit('closeModalScan')

      Axios.post(`/fe/card/job/${this.cardNumber}/location`, {
        link: this.selectedLocation ? null : this.linkMap,
        locationId: this.selectedLocation?.id,
      })
          .then((response) => {

          })
          .catch((error) => {
          })
          .finally(() => {
            this.$router.push({
              name: 'CardsEdit',
              params: {
                card_id: this.cardId,
                tab: 'jobCard',
                accessKey: this.accessKey
              }
            }).catch(()=>{});

          })
    },
    setVehicleLoc() {
      return
      this.selectedOption = 'location'
      this.firstMenuLevel = false
      this.$nextTick(() => {
        this.initMap()
      })
    },
    initMap() {
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: {lat: -34.397, lng: 150.644},
        zoom: 8
      });

      this.map.addListener('click', this.clickMap);
    },
    clickMap(event) {
      for (var i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
      this.markers = [];

      let lat = event.latLng.lat();
      let lng = event.latLng.lng();

      let marker = new google.maps.Marker({
        position: event.latLng,
        map: this.map
      });

      this.markers.push(marker)

      marker.addListener('dblclick', () => {this.removeMarker(marker)});
      this.linkMap = `https://www.google.com/maps/@${lat},${lng},15z`
    },
    removeMarker(marker) {
      marker.setMap(null);
      this.markers = _.filter(this.markers, (m) => { return m !== marker})
      this.linkMap = null
    },
    selectedLoc(v) {
      if (this.selectedLocation == v) {
        this.selectedLocation = null
      } else {
        this.selectedLocation = v
      }
    },
    loadLocations() {
      Axios.get('/fe/vendor/locations')
          .then(res => {
            if(res && res.data && res.data._status){
              this.locations = res.data.locations;
            }
          })
          .catch(e => toastr.error(e))
    },
    closeQrScanner(){
      this.isShowScanner = false
      this.toggleFullscreen(false)
    },
    toggleFullscreen(v) {
      if (v && !this.$fullscreen.isFullscreen) {
        this.$fullscreen.toggle(document.body, {
          wrap: false,
        });
      }
      if (!v && this.$fullscreen.isFullscreen) {
        this.$fullscreen.toggle(document.body, {
          wrap: false,
        });
      }

      document.body.classList.toggle('body-scanner');
    },
    onScanned(v) {
      if (this.selectedOption !== 'location') {
        this.$emit('closeModalScan')
      }

      this.isShowScanner = false
      this.toggleFullscreen(false)

      Axios.get('/fe/card/job/' + v).then((r) => {
        if (r.data._status && r.data.cardId && r.data.accessKey) {
          if (this.selectedOption == 'open') {
            this.goToJob(r.data.cardId, v, r.data.accessKey)
          } else if (this.selectedOption == 'start') {
            this.startJob(r.data.cardId, v, r.data.accessKey)
          } else if (this.selectedOption == 'location') {
            this.firstMenuLevel = false;
            this.$nextTick(() => {
              this.initMap()
            })
            this.cardId = r.data.cardId
            this.cardNumber = v
            this.accessKey = r.data.accessKey
          }
        }
      })

    },
    goToJob(cardId, cardNumber, accessKey) {
      this.$router.push({
        name: 'CardsEdit',
        params: {
          card_id: cardId,
          tab: 'jobCard',
          accessKey: accessKey
        }
      }).catch(()=>{});
      // this.$emit('closeModalScan')
    },
    startJob(cardId, cardNumber, accessKey) {
      Axios.post(`/fe/card/job/${cardNumber}/start`)
          .then((response) => {

          })
          .catch((error) => {
          })
          .finally(() => {
            this.$router.push({
              name: 'CardsEdit',
              params: {
                card_id: cardId,
                tab: 'jobCard',
                accessKey: accessKey
              }
            }).catch(()=>{});
            // this.$emit('closeModalScan')

          })
    },
  },
  mounted() {
    this.loadLocations()
  },
  components: {
    QrScanner
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
      cardsAll: 'card/getAllCards',
    })
  }
}
</script>

<style scoped>
#map {
  height: 400px;
  width: 100%;
}

.dropdown-item.active {
  background-color: rgba(94, 121, 255, .1) !important;
  color: #82a2dc !important;
}

.loc-btn {
  min-height: 61px !important;
  height: auto !important;
}

.dropdown-item {
  border-bottom: 1px solid rgba(28, 31, 57, 0.25) !important;
}
.dropdown-item:last-child {
  border-bottom: none !important;
}
.close-modal{
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 15px;
}
.dropdown-options .dropdown-menu{
  width: 100% !important;
  margin-top: 0 !important;
}
.dropdown-options .dropdown-menu .dropdown-item{
  font-size: 18px !important;
  min-height: 45px !important;
}
.wrapper-modal-scan{
  z-index: 999999;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.27);
}
.modal-scan-window{
  z-index: 9999999;
  background-color: #222a35;
  position: relative;
  width: 85%;
  height: 78%;
  margin: 15% auto;
  color: white;
  padding: 50px 30px 0 30px;
  overflow-y: auto;
}
.modal-scan-window-title{
  font-size: 29px;
  font-weight: 900;
  text-align: center;
  margin-bottom: 60px;
}
.modal-scan-window-btn div{
  margin: 40px 0;
}
.modal-scan-window-btn div button{
  background-color: #5d78fd;
  font-size: 29px;
  border: 2px solid #01105e;
  font-weight: 900;
  width: 100%;
  text-align: center;
  padding: 12px;
}
.modal-scan-window-btn div button:focus{
  background-color: #002afc;
}
.dropdown-options .ex-options-button{
  background-color: #5d78fd !important;
  font-size: 18px !important;
  border: 2px solid #01105e !important;
  color: white !important;
  font-weight: 900 !important;
  text-align: center !important;
  padding: 12px !important;
  height: 71px !important;
}
.ex-options-button:focus{
  background-color: #002afc !important;
}
.dropdown-options.open .fa-chevron-down{
  transform: rotate(180deg)
}
@media (max-width: 375px) {
  .modal-scan-window-btn div button{
    font-size: 22px;
  }
  .dropdown-options .ex-options-button{
    font-size: 22px !important;
    height: 61px !important;
  }
  .modal-scan-window-btn div{
    margin: 30px 0;
  }
  .modal-scan-window-title{
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 1000px) and (orientation: landscape) {
  .modal-scan-window{
    margin: 3% auto;
  }
  .modal-scan-window-btn div{
    margin: 25px 0;
  }
  .modal-scan-window-title{
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 1350px) and (orientation: landscape) {
  .modal-scan-window{
    margin: 5% auto;
  }
  .modal-scan-window-btn div{
    margin: 40px 0;
  }
}
</style>
